var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { object, string, unknown, undefined as _undefined } from "zod";
let getRandomValues;
const rnds8 = new Uint8Array(16);
function rng() {
  if (!getRandomValues) {
    getRandomValues = typeof crypto !== "undefined" && crypto.getRandomValues && crypto.getRandomValues.bind(crypto);
    if (!getRandomValues) {
      throw new Error("crypto.getRandomValues() not supported. See https://github.com/uuidjs/uuid#getrandomvalues-not-supported");
    }
  }
  return getRandomValues(rnds8);
}
const byteToHex = [];
for (let i = 0; i < 256; ++i) {
  byteToHex.push((i + 256).toString(16).slice(1));
}
function unsafeStringify(arr, offset = 0) {
  return byteToHex[arr[offset + 0]] + byteToHex[arr[offset + 1]] + byteToHex[arr[offset + 2]] + byteToHex[arr[offset + 3]] + "-" + byteToHex[arr[offset + 4]] + byteToHex[arr[offset + 5]] + "-" + byteToHex[arr[offset + 6]] + byteToHex[arr[offset + 7]] + "-" + byteToHex[arr[offset + 8]] + byteToHex[arr[offset + 9]] + "-" + byteToHex[arr[offset + 10]] + byteToHex[arr[offset + 11]] + byteToHex[arr[offset + 12]] + byteToHex[arr[offset + 13]] + byteToHex[arr[offset + 14]] + byteToHex[arr[offset + 15]];
}
const randomUUID = typeof crypto !== "undefined" && crypto.randomUUID && crypto.randomUUID.bind(crypto);
const native = {
  randomUUID
};
function v4(options, buf, offset) {
  if (native.randomUUID && !buf && !options) {
    return native.randomUUID();
  }
  options = options || {};
  const rnds = options.random || (options.rng || rng)();
  rnds[6] = rnds[6] & 15 | 64;
  rnds[8] = rnds[8] & 63 | 128;
  return unsafeStringify(rnds);
}
const INIT_HANDSHAKE = "klarna_initialize_messenger_handshake";
const INIT_HANDSHAKE_COMPLETE = "klarna_initialize_messenger_handshake_complete";
const ALLOWED_MESSAGE_ORIGIN_HOSTNAME_PATTERN = /(\.klarna\.com|\.klarna\.net|^x\.klarnacdn\.net)$/u;
const MessageSchema = object({
  messageId: string(),
  method: string(),
  data: unknown()
});
const MessageResolutionSchema = object({
  messageId: string(),
  method: string(),
  origin: string(),
  reject: unknown().or(_undefined()),
  resolve: unknown().or(_undefined())
});
function isProduction() {
  return !["development", "test"].includes(process.env.NODE_ENV);
}
class Messenger {
  constructor({ source, target }) {
    __publicField(this, "callbacks");
    __publicField(this, "handshakeComplete", false);
    __publicField(this, "source");
    __publicField(this, "sourcePort");
    __publicField(this, "target");
    __publicField(this, "targetPort");
    this.callbacks = /* @__PURE__ */ new Map();
    this.source = source;
    this.target = target;
    const { port1, port2 } = new MessageChannel();
    this.sourcePort = port1;
    this.targetPort = port2;
    this.sourcePort.onmessage = this.onMessageFromTarget.bind(this);
  }
  static isIframe(value) {
    try {
      return !!value.contentWindow;
    } catch (error) {
      return false;
    }
  }
  onMessageFromTarget(ev) {
    if (ev.data === INIT_HANDSHAKE_COMPLETE) {
      this.handshakeComplete = true;
      return;
    }
    const parsingResult = MessageResolutionSchema.safeParse(ev.data);
    if (!parsingResult.success) {
      console.error("Invalid data schema received from target");
      return;
    }
    const callback = this.callbacks.get(parsingResult.data.messageId);
    if (!callback) {
      console.error(`Callback not available for method: ${parsingResult.data.method}`);
      return;
    }
    const { reject, resolve, method } = parsingResult.data;
    if (reject !== void 0) callback.reject(reject);
    else if (resolve !== void 0) callback.resolve(resolve);
    else console.error(`No resolution available for method: ${method}`);
  }
  waitForHandshake() {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject(new Error("Handshake timeout"));
      }, 1e4);
      const interval = setInterval(() => {
        if (this.handshakeComplete) {
          clearTimeout(timeout);
          clearInterval(interval);
          resolve(true);
        }
      }, 100);
    });
  }
  postMessageToTarget(_0) {
    return __async(this, arguments, function* ({ method, data }) {
      const messageId = v4();
      const message = { messageId, method, data };
      this.sourcePort.postMessage(message);
      return new Promise((resolve, reject) => {
        this.callbacks.set(messageId, { resolve, reject });
      });
    });
  }
  initiateHandshake() {
    return __async(this, null, function* () {
      var _a;
      if (Messenger.isIframe(this.target)) {
        (_a = this.target.contentWindow) == null ? void 0 : _a.postMessage({ type: INIT_HANDSHAKE }, "*", [this.targetPort]);
      } else {
        this.target.postMessage({ type: INIT_HANDSHAKE }, "*", [this.targetPort]);
      }
      yield this.waitForHandshake();
    });
  }
  destroy() {
    this.sourcePort.close();
    this.targetPort.close();
  }
}
class MessengerTarget {
  constructor() {
    __publicField(this, "handlers");
    __publicField(this, "port");
    __publicField(this, "sourceOrigin");
    __publicField(this, "handshakeComplete");
    this.handlers = /* @__PURE__ */ new Map();
    this.handshakeComplete = false;
  }
  waitForHandshake() {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (this.handshakeComplete) {
          clearInterval(interval);
          resolve(true);
        }
      }, 100);
    });
  }
  sendMessageToSource(message) {
    return __async(this, null, function* () {
      if (!this.handshakeComplete) yield this.waitForHandshake();
      this.port.postMessage(message);
    });
  }
  onMessageFromSource(ev) {
    return __async(this, null, function* () {
      const { messageId, method, data } = MessageSchema.parse(ev.data);
      const resolution = {
        messageId,
        method,
        origin: this.sourceOrigin,
        reject: void 0,
        resolve: void 0
      };
      const handler = this.handlers.get(method);
      if (!handler) {
        resolution.reject = `Unhandled method: ${method}, add appropriate handler.`;
        this.sendMessageToSource(resolution);
        return;
      }
      try {
        resolution.resolve = yield handler({
          data,
          config: { sourceOrigin: this.sourceOrigin }
        });
      } catch (error) {
        resolution.reject = error;
      }
      this.sendMessageToSource(resolution);
    });
  }
  registerHandler(method, handler) {
    this.handlers.set(method, handler);
  }
  initiateHandshakeListener({
    validateOrigin = true,
    removeListenerAfterHandshake = true
  }) {
    const onGlobalMessageEvent = (event) => {
      var _a;
      if (event.data.type !== INIT_HANDSHAKE) return;
      if (!((_a = event.ports[0]) == null ? void 0 : _a.postMessage)) return;
      if (validateOrigin && isProduction()) {
        if (!ALLOWED_MESSAGE_ORIGIN_HOSTNAME_PATTERN.test(new URL(event.origin).hostname)) return;
      }
      this.port = event.ports[0];
      this.port.postMessage(INIT_HANDSHAKE_COMPLETE);
      this.port.onmessage = this.onMessageFromSource.bind(this);
      this.sourceOrigin = event.origin;
      this.handshakeComplete = true;
      if (removeListenerAfterHandshake) window.removeEventListener("message", onGlobalMessageEvent);
    };
    window.addEventListener("message", onGlobalMessageEvent);
  }
}
export {
  Messenger,
  MessengerTarget
};
