import { BrowserMicroSentryClient } from '@micro-sentry/browser'

import { ErrorKeys } from '../errors/errorKeys'
import { isStagingEnvironment } from '../isStagingEnvironment'
import { trackError } from '../tracker/trackError'
import { dropErrors } from './dropErrors'
import { getErrorFingerprints } from './getErrorFingerprints'
import { ignoreNonSDKErrors } from './ignoreNonSDKErrors'
import { sampleErrors } from './sampleErrors'

type Func<T, R> = (arg: T) => R

const compose =
  <T, U, V>(g: Func<U, V>, f: Func<T, U>): Func<T, V> =>
  (x: T): V =>
    g(f(x))

let sentryClient: BrowserMicroSentryClient
type Primitive = string | number | boolean | undefined | null
interface SentryConfig {
  version: string
  environment: string
  tags?: Record<string, Primitive>
}

const fallbackSentryClient = {
  report: () => {},
  setTags: () => {},
  setTag: () => {},
  setExtras: () => {},
  setExtra: () => {},
} as unknown as BrowserMicroSentryClient

export const getSentryClient = (): BrowserMicroSentryClient => {
  if (!sentryClient) {
    trackError(ErrorKeys.SENTRY_NOT_CONFIGURED, 'Sentry client not initialized')
    return fallbackSentryClient
  }
  return sentryClient
}

export const initializeSentryClient = (config: SentryConfig): BrowserMicroSentryClient => {
  return new BrowserMicroSentryClient({
    dsn: 'https://6fdc8e6e634d4a998b6f0dbfd7b025e1@o24547.ingest.sentry.io/4505471301713920',
    release: config.version,
    environment: config.environment,
    beforeSend: (event) => {
      event.exception?.values?.forEach(({ value }) => {
        event.fingerprint = getErrorFingerprints(value)
      })

      const webSDKError = ignoreNonSDKErrors(event)

      if (webSDKError) {
        const removeErrors = compose(sampleErrors, dropErrors)
        return removeErrors(webSDKError)
      }

      return null
    },
    blacklistUrls: [
      // Google Adsense
      /pagead\/js/i,
      // Facebook
      /graph\.facebook\.com/i,
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Itunes
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  })
}

/*
 * Using micro sentry instead of normal sentry client to reduce bundle size.
 *
 * Error conditions:
 * - Sentry is only used in playground and production
 * - Some similar errors are grouped by adding a fingerprint to them (see getErrorFingerprints.ts)
 * - Errors from other bundles than klarna are ignored (see ignoreNonSDKErrors.ts)
 * - Some common errors are sampled due to the high frequency of them (see sampleErrors.ts)
 *
 * It's important to follow up on the above conditions as these errors should be removed with time and the filters will then be inapplicable.
 */
export const setupSentry = (config: SentryConfig): BrowserMicroSentryClient => {
  if (process.env.NODE_ENV === 'development' || isStagingEnvironment()) {
    sentryClient = fallbackSentryClient
  } else if (!sentryClient) {
    sentryClient = initializeSentryClient(config)
  }

  if (config.tags) {
    Object.entries(config.tags).forEach(([key, value]) => {
      if (value) {
        sentryClient.setTag(key, value.toString())
      }
    })
  }

  return sentryClient
}
