export const getRegionFromCurrency = (currency: string) => {
  switch (currency) {
    case 'USD':
      return 'na'
    case 'EUR':
      return 'eu'
    default:
      return 'eu'
  }
}
