import { backendBridge } from '@klarna-web-sdk/backend-bridge'
import { camelCaseToSnakeCaseMap } from '@klarna-web-sdk/utils/src/caseConverter'

import type { InteroperabilityContext, InteroperabilityOptions } from '../types'

export const resolveInteroperability = async (
  shoppingSessionId: string,
  context: InteroperabilityContext,
  options: InteroperabilityOptions
) => {
  const _response = await backendBridge.call({
    method: 'interoperabilityApiResolve',
    data: {
      shoppingSessionId,
      ...camelCaseToSnakeCaseMap(context),
      ...camelCaseToSnakeCaseMap(options),
    },
  })

  return _response
}
