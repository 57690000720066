import { getRegionFromCurrency } from './getRegionFromCurrency'
import { getRegionFromLocale } from './getRegionFromLocale'

interface GetRegionParams {
  paymentRequestId?: string
  currency?: string
  locale?: string
}

export const getRegion = ({ paymentRequestId, currency, locale }: GetRegionParams) => {
  if (paymentRequestId) {
    const paymentRequestIdParts = paymentRequestId.split(':')
    if (paymentRequestIdParts.length >= 3) {
      const klarnaRegion = paymentRequestId.split(':')[2].substring(0, 2)
      if (klarnaRegion === 'us') {
        return 'na'
      } else if (klarnaRegion === 'ap') {
        return 'oc'
      }
      return klarnaRegion
    }
  }
  if (currency) {
    return getRegionFromCurrency(currency)
  }

  return getRegionFromLocale(locale)
}
