import type { TemplateResult } from 'lit'

export enum Language {
  DA = 'da',
  DE = 'de',
  EN = 'en',
  ES = 'es',
  FI = 'fi',
  FR = 'fr',
  IT = 'it',
  NL = 'nl',
  NB = 'nb',
  PL = 'pl',
  PT = 'pt',
  SV = 'sv',
}

export enum ETranslationKey {
  PayWith = 'pay_with',
  ContinueWith = 'continue_with',
  OverlayContentText = 'overlay_content_text',
  OverlayContentButtonLabel = 'overlay_content_button_label',
}

export type LitTranslation = string | TemplateResult
