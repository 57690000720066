import { LogoType } from '../../types'

export const getLogoType = (dataset: DOMStringMap): LogoType => {
  const attribute = dataset.logoType?.toLowerCase()
  if (!(attribute === 'text' || attribute === 'badge')) {
    return ''
  }

  return attribute
}
