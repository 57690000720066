import { getParsedUserAgent, TrackerClients, trackerFactory } from '@klarna-web-sdk/utils'

import { IdentitySDKConfig } from './IdentitySDK'

export const TrackingEvents = {
  ButtonReady: 'button_ready',
  ButtonClicked: 'button_clicked',

  OnPageFlowStarted: 'on_page_flow_started',
  RedirectFlowStarted: 'redirect_flow_started',

  LoginSuccess: 'login_success',
  LoginFailure: 'login_failure',

  UnexpectedError: 'unexpected_error',
  RedirectUriNotProvided: 'redirect_uri_not_provided',
} as const

export type EnvironmentTracker = {
  url: string
  // todo: currently enabled property is ignored
  // we need to implement a fake tracker when tracker is disabled
  enabled: boolean
}

export interface IdentityTrackingEvent {
  name: (typeof TrackingEvents)[keyof typeof TrackingEvents]
  options?: Record<string, unknown>
}

const IDENTITY_TRACKER_CLIENT = TrackerClients.identitySdk

export class IdentityTracker {
  private static tracker: ReturnType<typeof trackerFactory>

  private static getInstance() {
    if (!IdentityTracker.tracker) {
      IdentityTracker.tracker = trackerFactory(IDENTITY_TRACKER_CLIENT)
    }

    return IdentityTracker.tracker
  }

  public static configureInstance(
    { sessionId, clientId, environment }: IdentitySDKConfig,
    trackerConfig: EnvironmentTracker
  ) {
    IdentityTracker.getInstance().configure({
      options: {
        client: IDENTITY_TRACKER_CLIENT,
        clientVersion: 'v1', // TODO: GET SDK VERSION
        sessionId,
        baseUrl: trackerConfig.url,
      },
      data: {
        clientId,
        environment,
        sdk: 'websdk',
        ...getParsedUserAgent(),
        sessionId,
      },
      instanceId: IDENTITY_TRACKER_CLIENT,
    })

    return IdentityTracker.tracker
  }

  public static sendEvent({ name, options = {} }: IdentityTrackingEvent) {
    return IdentityTracker.getInstance().event(name, {}, options)
  }
}
