const shouldLoadTestDriveBadge = (
  environment: string,
  clientId?: string,
  win = window
): boolean => {
  const DOMAINS_TO_HIDE_TEST_DRIVE_BADGE = [
    'www.klarna.com',
    'www.klarnademo.com',
    'demo.klarna.dev',
    'demo-store-eu.staging.c2c.klarna.net',
  ]

  if (DOMAINS_TO_HIDE_TEST_DRIVE_BADGE.includes(win.location.hostname)) {
    return false
  }

  // To support backward compatibility for data-environment parameter
  if (environment === 'playground') {
    return true
  }

  if (clientId) {
    return clientId.startsWith('_test_')
  }

  return false
}

export default shouldLoadTestDriveBadge
