import { InteractionMode, InteractionModes } from '@klarna/flow-interaction-mode/dist/constants'
import {
  ElementArgs,
  ETranslationKey,
  getTranslation,
  klarnaElements,
  klarnaShapes,
  klarnaThemes,
  logWarn,
} from '@klarna-web-sdk/utils'
import { html, LitElement } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { z } from 'zod'

import kBadge from '../../assets/kBadge'
import kLogo from '../../assets/kLogo'
import { IdentityButton } from '../components/IdentityButton'
import { IdentitySDK } from '../components/IdentitySDK'
import styles from './style.scss'

export enum ButtonLogoAlignment {
  Default = 'default',
  Left = 'left',
  Center = 'center',
}

export interface ButtonOptions {
  buttonTheme: klarnaThemes
  buttonShape: klarnaShapes
  buttonLogoAlignment: ButtonLogoAlignment
  interactionMode: InteractionMode
}

type IdentityInteractionMode = Extract<InteractionMode, 'DEVICE_BEST' | 'ON_PAGE' | 'REDIRECT'>

export interface IdentityButtonConfiguration extends ElementArgs {
  shape?: klarnaShapes
  theme?: klarnaThemes
  logoAlignment?: ButtonLogoAlignment
  hideOverlay?: boolean
  // todo: fix scope inconsistencies
  scope: string
  redirectUri?: string
  interactionMode?: IdentityInteractionMode
}

export const IdentityButtonConfigurationSchema = z.object({
  shape: z.nativeEnum(klarnaShapes, { message: `must be one of ${klarnaShapes}` }).optional(),
  theme: z.nativeEnum(klarnaThemes, { message: `must be one of ${klarnaThemes}` }).optional(),
  logoAlignment: z
    .nativeEnum(ButtonLogoAlignment, { message: `must be one of ${ButtonLogoAlignment}` })
    .optional(),
  hideOverlay: z.boolean().optional(),
  scope: z.string(),
  redirectUri: z.string().url({ message: 'must be a valid URL' }),
  interactionMode: z
    .enum(['DEVICE_BEST', 'REDIRECT'], {
      message: `must be one of 'DEVICE_BEST', 'REDIRECT'`,
    })
    .optional(),
})

export function defineIdentityButton(identitySDK: IdentitySDK) {
  const { clientInstanceName } = identitySDK.getConfig()
  const elementSelector =
    clientInstanceName && clientInstanceName !== 'default'
      ? `${klarnaElements.IDENTITY_BUTTON}-${clientInstanceName}`
      : klarnaElements.IDENTITY_BUTTON
  if (customElements.get(elementSelector)) {
    return
  }

  @customElement(elementSelector)
  class KlarnaIdentityButton extends LitElement {
    private identityButton: IdentityButton | undefined

    @property({ attribute: 'data-shape' })
    accessor shape: klarnaShapes = klarnaShapes.DEFAULT

    @property({ attribute: 'data-theme' })
    accessor theme: klarnaThemes = klarnaThemes.DEFAULT

    @property({ attribute: 'data-logo-alignment' })
    accessor logoAlignment: ButtonLogoAlignment = ButtonLogoAlignment.Default

    @property({ attribute: 'data-hide-overlay', type: Boolean })
    accessor hideOverlay = false

    @property({ attribute: 'data-interaction-mode' })
    accessor interactionMode = InteractionModes.DEVICE_BEST

    @property({ attribute: 'data-scope' })
    accessor scope = ''

    @property({ attribute: 'data-redirect-uri' })
    accessor redirectUri = ''

    @property({ attribute: 'data-loading', type: Boolean })
    accessor loading = false

    private alignLogo(logoAlignment: ButtonLogoAlignment) {
      const xWithKlarna = getTranslation(ETranslationKey.ContinueWith, {
        locale: identitySDK.getLanguage(),
        params: [logoAlignment === ButtonLogoAlignment.Default ? '' : 'Klarna'],
      })

      switch (logoAlignment) {
        case ButtonLogoAlignment.Center:
          return html`<span id="klarna-identity-button__text--center"
            ><span id="logo">${kLogo}</span><span id="copy">${xWithKlarna}</span></span
          >`
        case ButtonLogoAlignment.Left:
          return html`<span id="logo">${kLogo}</span><span id="copy">${xWithKlarna}</span> `
        default:
          return html`<span id="klarna-identity-button__text--center"
            ><span>${xWithKlarna}</span><span id="badge">${kBadge}</span></span
          >`
      }
    }

    private handleClick() {
      // prevent double clicking
      if (this.loading) {
        return
      }

      this.loading = true
      this.identityButton?.click()
    }

    private validateButtonOptions() {
      const buttonOptions = {
        shape: this.shape,
        theme: this.theme,
        logoAlignment: this.logoAlignment,
        hideOverlay: this.hideOverlay,
        interactionMode: this.interactionMode,
        scope: this.scope,
        redirectUri: this.redirectUri,
      }
      const { error } = IdentityButtonConfigurationSchema.safeParse(buttonOptions)
      if (error) {
        logWarn(`Invalid button configuration: ${error.message}`)
      }
    }

    connectedCallback() {
      super.connectedCallback()
      this.identityButton = identitySDK.registerButton(this)
      identitySDK.on('signin', this.handleEvent('signin'))
      identitySDK.on('error', this.handleEvent('error'))
      identitySDK.on('popupclose', this.handleEvent('popupclose'))
    }

    disconnectedCallback() {
      super.disconnectedCallback()
      identitySDK.unregisterButton(this)
      this.identityButton = undefined
    }

    private handleEvent(eventName: 'signin' | 'error' | 'popupclose') {
      return () => {
        switch (eventName) {
          case 'signin':
          case 'error':
          case 'popupclose':
            this.loading = false
        }
      }
    }

    render() {
      this.validateButtonOptions()
      return html`
        <style>
          ${styles}
        </style>
        <button
          id="klarna-identity-button"
          class="theme-${this.theme === klarnaThemes.DEFAULT
            ? klarnaThemes.DARK
            : this.theme} shape-${this.shape}"
          @click=${this.handleClick}
        >
          <div id="klarna-identity-button__outline"></div>
          <div id="klarna-identity-button__inner-container">
            <span id="klarna-identity-button__text" class="${this.loading ? 'hidden' : ''}">
              ${this.alignLogo(this.logoAlignment)}
            </span>
            ${this.loading ? html`<span id="klarna-identity-button__spinner"></span>` : ''}
          </div>
        </button>
      `
    }
  }

  return KlarnaIdentityButton
}
