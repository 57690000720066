import { html } from 'lit'

import klarnaPinkBadgeLogo from '../../assets/klarnaPinkBadgeLogo'
import styles from './style.scss'

export default function sideBar() {
  return html`
    <style>
      ${styles}
    </style>
    <div style="all: initial;">
      <div id="badge">${klarnaPinkBadgeLogo}</div>
    </div>
  `
}
