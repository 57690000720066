import { html } from 'lit'

const kLogo = html` <svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_13551_452856)">
    <path d="M24 0H0V24H24V0Z" fill="#FFA8CD" />
    <path
      d="M17.7208 4.33496H14.3928C14.3928 7.07246 12.7179 9.52527 10.1729 11.2773L9.17237 11.9781V4.33496H5.71387V19.665H9.17237V12.0657L14.893 19.665H19.1129L13.6097 12.3942C16.1111 10.5765 17.7425 7.75137 17.7208 4.33496Z"
      fill="#0B051D"
    />
  </g>
  <defs>
    <clipPath id="clip0_13551_452856">
      <rect width="24" height="24" rx="8.34" fill="white" />
    </clipPath>
  </defs>
</svg>`

export default kLogo
