import { any, ZodTypeAny } from 'zod'
/**
 * Helper method that allows for custom superRefined zod objects to run
 * custom validations at the same time that built in zod validations run
 * If there are type violations for any provided value, only those type violdation will be returned
 * Read more here: https://github.com/colinhacks/zod/issues/68#issuecomment-655866864
 * @param zodType
 * @returns
 */
export const zodAlwaysRefine = <T extends ZodTypeAny>(zodType: T) => {
  return any().superRefine(async (value, ctx) => {
    const res = await zodType.safeParseAsync(value)

    if (res.success === false)
      for (const issue of res.error.issues) {
        ctx.addIssue(issue)
      }
  }) as unknown as T
}
