import { klarnaThemes } from '../constants'
import { isProductionBuild } from '../isProductionBuild'
import { logWarn } from '../logger'
import { validateKlarnaOrigin } from '../validateKlarnaOrigin'

export default function getButtonTheme(themeRequested: klarnaThemes): klarnaThemes {
  if (Object.values(klarnaThemes).includes(themeRequested)) return themeRequested

  if (!isProductionBuild() || validateKlarnaOrigin(window.location.origin)) {
    logWarn(`Invalid theme requested: "${themeRequested}", using default theme instead.`)
  }

  return klarnaThemes.DEFAULT
}
