type Primitive = string | number | boolean | undefined | null

// Remove undefined attributes from an object,
// useful as we often support different combinations of attributes depending on products and auth strategy
export const removeUndefinedAttributes = (attributes: Record<string, Primitive>) => {
  const cleanedAttributes = { ...attributes }
  Object.keys(cleanedAttributes).forEach((key) =>
    cleanedAttributes[key] === undefined ? delete cleanedAttributes[key] : {}
  )

  return cleanedAttributes
}
