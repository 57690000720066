import { closeInteractionMode } from '@klarna/flow-interaction-mode'
import { MessengerTarget } from '@klarna-web-sdk/messenger'
import { MethodHandler, MethodHandlerProps } from '@klarna-web-sdk/messenger/types'
import { PaymentSDK } from '@klarna-web-sdk/payment/src/paymentSDK'
import { MethodsSchema } from '@klarna-web-sdk/sdk-bridge/schema'
import { MethodsKey } from '@klarna-web-sdk/sdk-bridge/types'
import {
  camelCaseToSnakeCaseMap,
  snakeCaseToCamelCaseMap,
} from '@klarna-web-sdk/utils/src/caseConverter'

import { request } from '../apis/request'
import { transformSuccessInformation } from './shippingChangeHandler'

/**
 * SdkBridgeReceiver gets initiated inside Web SDK and receives messages
 * from the outside browser context. It is responsible for validating
 * the incoming messages and creating appropriate resolution.
 */
export class SdkBridgeReceiver {
  private messengerTarget: MessengerTarget

  constructor() {
    this.messengerTarget = new MessengerTarget()
  }

  private async closeInteractionMode(): Promise<void> {
    closeInteractionMode()
  }

  private async shippingAddressChange(props: MethodHandlerProps) {
    const { data } = props
    const parsedData = MethodsSchema.shippingAddressChange.data.parse(data)

    if (!PaymentSDK.shippingAddressChangeCallback) {
      throw new Error('callback is not defined by integrator')
    }

    const callbackResponse = await PaymentSDK.shippingAddressChangeCallback(
      request(),
      snakeCaseToCamelCaseMap(parsedData)
    )

    let transformedResponse
    if ('rejectionReason' in callbackResponse || Array.isArray(callbackResponse)) {
      transformedResponse = camelCaseToSnakeCaseMap(callbackResponse)
    } else {
      if ('paymentAmount' in callbackResponse && 'lineItems' in callbackResponse) {
        const lineItemsSum = callbackResponse.lineItems.reduce(
          (prev, curr) => prev + curr.totalAmount,
          0
        )
        if (lineItemsSum !== callbackResponse.paymentAmount) {
          throw new Error('invalid order data provided')
        }
      }
      transformedResponse = transformSuccessInformation(callbackResponse)
    }

    return transformedResponse
  }

  private async shippingOptionSelect(props: MethodHandlerProps) {
    const { data } = props
    const parsedData = MethodsSchema.shippingOptionSelect.data.parse(data)

    if (!PaymentSDK.shippingOptionSelectCallback) {
      throw new Error('callback is not defined by integrator')
    }

    const callbackResponse = await PaymentSDK.shippingOptionSelectCallback(
      request(),
      snakeCaseToCamelCaseMap(parsedData)
    )
    if ('paymentAmount' in callbackResponse && 'lineItems' in callbackResponse) {
      const lineItemsSum = callbackResponse.lineItems.reduce(
        (prev, curr) => prev + curr.totalAmount,
        0
      )
      if (lineItemsSum !== callbackResponse.paymentAmount) {
        throw new Error('invalid order data provided')
      }
    }
    const transformedResponse = transformSuccessInformation(callbackResponse)

    return transformedResponse
  }

  public initiateHandshakeListener() {
    this.messengerTarget.initiateHandshakeListener({
      validateOrigin: false,
      removeListenerAfterHandshake: false,
    })
  }

  public registerAllHandlers() {
    const methodHandlersMap: Record<MethodsKey, MethodHandler> = {
      closeInteractionMode: this.closeInteractionMode.bind(this),
      shippingAddressChange: this.shippingAddressChange.bind(this),
      shippingOptionSelect: this.shippingOptionSelect.bind(this),
    }

    for (const [method, handler] of Object.entries(methodHandlersMap)) {
      this.messengerTarget.registerHandler(method, handler)
    }
  }
}
