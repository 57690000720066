import extendKlarnaNamespace from '@klarna-web-sdk/utils/src/extendKlarnaNamespace'

function refresh(): unknown {
  const event = new CustomEvent('OSM:refresh')
  document.dispatchEvent(event)
  // Needed for backwards compatibility, refreshing of the
  // placement is handled automatically nowdays so the merchants do not have to
  // call any function, but this stub is needed to not break clients

  return null
}

export default function initializeWindowInterface(
  on: (eventName: string) => void,
  off: (eventName: string) => void
) {
  /** Object to support legacy OSM and not break merchants pages, OSM v2 */
  extendKlarnaNamespace('OnsiteMessaging', {
    refresh,
    eventListeners: {},
    on,
    off,
  })
}
