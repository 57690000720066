import { UAParser } from 'ua-parser-js'

export const getParsedUserAgent = () => {
  const userAgentParser = UAParser(window.navigator.userAgent)

  return {
    browserName: userAgentParser.browser.name,
    browserVersion: userAgentParser.browser.version,
    osName: userAgentParser.os.name,
    osVersion: userAgentParser.os.version,
    deviceModel: userAgentParser.device.model,
    deviceType: userAgentParser.device.type,
    deviceVendor: userAgentParser.device.vendor,
    engineName: userAgentParser.engine.name,
    engineVersion: userAgentParser.engine.version,
    userAgent: userAgentParser.ua,
  }
}
