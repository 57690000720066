import { detect } from 'detect-browser'

/*
  Artificially limits the amount of browser names that can be reported in order to prevent:
    1. Metric coordinality from exploiding
    2. User Agent malcious injections

  This list might need to be updated whenever an amount of "unknown" browsers becomes to high.
*/
const KNOWN_BROWSER_NAMES = [
  'chrome',
  'ios',
  'chromium-webview',
  'edge-chromium',
  'safari',
  'instagram',
  'ios-webview',
  'samsung',
  'bot',
  'crios',
  'facebook',
  'firefox',
  'opera',
  'edge-ios',
  'silk',
  'fxios',
  'android',
]

export const detectBrowserName = () => {
  try {
    const browserName = detect()?.name
    return KNOWN_BROWSER_NAMES.includes(browserName) ? browserName : 'unknown'
  } catch (e) {
    return 'unknown'
  }
}
