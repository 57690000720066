import { isValidLocale } from '@klarna-web-sdk/utils/src/isValidLocale'
import { isValidMessagePrefix } from '@klarna-web-sdk/utils/src/isValidMessagePrefix'
import { logError, logWarn } from '@klarna-web-sdk/utils/src/logger'

import { DEPRECATED_PLACEMENT_KEYS, PLACEMENTS, VALID_THEMES } from '../../config'
import { ValidationError } from '../../errors'
import getPaymentAmount from '../getPaymentAmount/getPaymentAmount'
import { handleValidationError } from '../handleValidationError/handleValidationError'
import hasValidAmount from '../validateAmount/validateAmount'

const placementKeys = Object.keys(PLACEMENTS)

export default function hasValidPlacementConfiguration(dataset: DOMStringMap): boolean {
  const { key = '', locale = '', theme, messagePrefix } = dataset
  /* In case of deprecated keys we still want to render, that's why we don't return false */
  if (DEPRECATED_PLACEMENT_KEYS.includes(key)) {
    logWarn(
      `The placement type ${key} will soon be deprecated. Please check https://docs.klarna.com/on-site-messaging/in-depth-knowledge/placements/#deprecated-placement-types`
    )
  }

  /* In case of invalid theme keys we still want to render, that's why we don't return false */
  if (theme && !VALID_THEMES.includes(theme?.toLowerCase())) {
    logWarn(
      `The provided theme(${theme}) is not a valid argument. Please use one of these valid values: ${VALID_THEMES}.`
    )
  }

  /* Check that the placement Key is passed and is valid */
  if (!key) {
    const errorMessage =
      'data-key is missing. It is a required attribute. Please check https://docs.klarna.com/on-site-messaging/in-depth-knowledge/placements/'
    logError(errorMessage)
    throw new ValidationError(errorMessage)
  } else if (!placementKeys.includes(key) && !key.includes('custom')) {
    const errorMessage = `${key} is not a valid placement type. Please check https://docs.klarna.com/on-site-messaging/in-depth-knowledge/placements/`
    logError(errorMessage)
    throw new ValidationError(errorMessage)
  }

  /* Check that the locale is passed and is valid */
  if (!locale || !isValidLocale(locale)) {
    const errorMessage = !locale
      ? 'Locale klarna-placement is missing. It is a required attribute.'
      : `${locale} is not a valid locale in klarna-placement.`

    handleValidationError(
      `${errorMessage} Please check https://docs.klarna.com/on-site-messaging/in-depth-knowledge/placements/`
    )
  }

  /* Check that the message-prefix is valid */
  if (messagePrefix && !isValidMessagePrefix(messagePrefix)) {
    const errorMessage = `${messagePrefix} is not a valid message prefix in klarna-placement. Available options are: Or, or.`

    handleValidationError(
      `${errorMessage} Please check https://docs.klarna.com/on-site-messaging/in-depth-knowledge/placements/`
    )
  }

  /* Check the amount is passed and is valid */
  const isValidAmount = hasValidAmount(key, getPaymentAmount(dataset))
  if (!isValidAmount) {
    return false
  }
  return true
}
