import { Messenger } from '@klarna-web-sdk/messenger'
import { ErrorCodes, ErrorTypes } from '@klarna-web-sdk/payment/src/constants'
import { PaymentError } from '@klarna-web-sdk/payment/src/utils/paymentError'
import type { trackerFactory } from '@klarna-web-sdk/utils'
import { SDKConfig } from '@klarna-web-sdk/utils/src/types'
import { TypeOf } from 'zod'

import { MethodsSchema } from './schema'
import type { MethodsKey, MethodsSchemaType } from './types'
import { ApiError } from './utils'

const FRAME_ID = 'klarna-communication-iframe'

/**
 * BackendBridge is a wrapper around Messenger class that provides
 * a communication channel between Web SDK and a klarna hosted iframe
 * for backend communication.
 *
 * This is the interface for Web SDK (or any other integrated packages)
 * to communicate with backend. Any method that can be called
 * should also have a corresponding handler in receiver file. Otherwise
 * the promise will never be resolved.
 */
export class BackendBridge {
  // eslint-disable-next-line no-use-before-define
  private static instance: BackendBridge
  static getInstance() {
    if (!this.instance) {
      this.instance = new BackendBridge()
    }
    return this.instance
  }

  private config: SDKConfig
  private messenger: Messenger
  private status: 'initialized' | 'initializing' | 'uninitialized'
  private tracker: ReturnType<typeof trackerFactory>

  constructor() {
    this.status = 'uninitialized'
  }

  private waitForInitialization() {
    return new Promise((resolve) => {
      if (this.status === 'initialized') resolve(true)
      else {
        const interval = setInterval(() => {
          if (this.status === 'initialized') {
            clearInterval(interval)
            resolve(true)
          }
        }, 100)
      }
    })
  }

  private async createTarget(
    baseUrl: string,
    clientInstanceName: string = ''
  ): Promise<{
    target: HTMLIFrameElement
    src: string
  }> {
    return new Promise((resolve, reject) => {
      if (!baseUrl || baseUrl === '') reject(new Error('BackendBridge: baseUrl missing'))

      try {
        const src = `${baseUrl}backend_bridge_iframe.html`

        const existingIframe = document.querySelector(
          `#${FRAME_ID}${clientInstanceName}`
        ) as HTMLIFrameElement
        if (existingIframe) resolve({ target: existingIframe, src })

        const iframe = document.createElement('iframe')
        iframe.src = src
        iframe.id = `${FRAME_ID}${clientInstanceName}`
        iframe.style.cssText = 'display:none!important'
        document.body.appendChild(iframe)

        iframe.onload = () => resolve({ target: iframe, src })
      } catch (error) {
        reject(error)
      }
    })
  }

  public async configure(config: SDKConfig, tracker: ReturnType<typeof trackerFactory>) {
    this.config = config
    this.tracker = tracker
  }

  public async init() {
    if (!this.config)
      throw new PaymentError(
        ErrorTypes.TECHNICAL_ERROR,
        ErrorCodes.INTERNAL_ERROR,
        'BackendBridge: config missing'
      )

    this.status = 'initialized'
    this.tracker.event('backend_bridge_init', { ...this.config })

    const { target } = await this.createTarget(this.config.baseUrl, this.config.clientInstanceName)

    this.messenger = new Messenger({ source: window, target })
    await this.messenger.initiateHandshake()
    await this.call({ method: 'setupApiCredentials', data: this.config })

    this.status = 'initialized'
  }

  public async call<K extends MethodsKey>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: MethodsSchemaType[K] extends { data: any }
      ? { method: K; data: TypeOf<MethodsSchemaType[K]['data']> }
      : { method: K; data?: never }
  ): Promise<TypeOf<MethodsSchemaType[K]['response']>> {
    if (this.status === 'initializing') await this.waitForInitialization()
    if (this.status === 'uninitialized') await this.init()

    const parsedRequest = MethodsSchema[params.method].data.safeParse(params.data)
    if (parsedRequest.success === false) {
      throw new PaymentError(
        ErrorTypes.TECHNICAL_ERROR,
        ErrorCodes.INTERNAL_ERROR,
        'Invalid request',
        parsedRequest.error
      )
    }

    return this.messenger
      .postMessageToTarget({
        method: params.method,
        data: parsedRequest.data,
      })
      .then((response) => {
        const parsedResponse = MethodsSchema[params.method].response.safeParse(response)
        if (parsedResponse.success === false) {
          throw new PaymentError(
            ErrorTypes.TECHNICAL_ERROR,
            ErrorCodes.INTERNAL_ERROR,
            'Invalid response',
            parsedResponse.error
          )
        }
        return parsedResponse.data
      })
      .catch((error) => {
        // messenger cannot transfer error instances, we have to check for known properties
        if (
          Object.prototype.hasOwnProperty.call(error, 'status') &&
          Object.prototype.hasOwnProperty.call(error, 'statusText') &&
          Object.prototype.hasOwnProperty.call(error, 'response')
        ) {
          throw new ApiError(error)
        } else {
          throw new PaymentError(ErrorTypes.TECHNICAL_ERROR, error, error)
        }
      })
  }
}

export const backendBridge = BackendBridge.getInstance()
