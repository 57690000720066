import { logWarn } from '@klarna-web-sdk/utils/src/logger'

export const getSubscriptionInterval = (dataset: DOMStringMap): string | undefined => {
  const { subscriptionInterval, messagePreference } = dataset

  if (messagePreference === 'subscription') {
    if (!subscriptionInterval) {
      logWarn(
        'Subscription interval is missing. Please provide a subscription interval when message preference is set to subscription'
      )
      return undefined
    }
    return subscriptionInterval
  }

  return undefined
}
