import { PaymentError } from '@klarna-web-sdk/payment/src/utils/paymentError'

import { SUPPORTED_CURRENCIES, SUPPORTED_CURRENCY_COUNTRY_COMBOS } from '../config'
import {
  Country as CountryEnum,
  Currency as CurrencyEnum,
  ErrorCodes,
  ErrorTypes,
  TrackingEvents,
} from '../constants'
import { CanMakePaymentOptionsSchema } from '../schema'
import { CanMakePaymentOptions } from '../types'
import { tracker } from '../utils/paymentTracker'

export const canMakePayment = async (options: CanMakePaymentOptions) => {
  tracker().event(TrackingEvents.CAN_MAKE_PAYMENT_CALLED, { ...options })

  if (!options)
    throw new PaymentError(
      ErrorTypes.INPUT_ERROR,
      ErrorCodes.VALIDATION_ERROR,
      'Options are missing'
    )

  try {
    CanMakePaymentOptionsSchema.parse(options)
  } catch (error) {
    throw new PaymentError(
      ErrorTypes.INPUT_ERROR,
      ErrorCodes.VALIDATION_ERROR,
      'Invalid options',
      error
    )
  }

  // TODO: check if we can infer types from zod schema https://klarna.atlassian.net/browse/KPC-1604
  const currency = options.currency as CurrencyEnum | undefined
  const country = options.country as CountryEnum | undefined

  if (currency && !SUPPORTED_CURRENCIES.includes(currency)) {
    tracker().event(TrackingEvents.CAN_MAKE_PAYMENT_COMPLETED, { result: 'false' })
    return false
  }
  if (currency && country && !SUPPORTED_CURRENCY_COUNTRY_COMBOS[currency]?.includes(country)) {
    tracker().event(TrackingEvents.CAN_MAKE_PAYMENT_COMPLETED, { result: 'false' })
    return false
  }

  tracker().event(TrackingEvents.CAN_MAKE_PAYMENT_COMPLETED, { result: 'true' })
  return true
}
