/**
 * Integrator API is a set of methods that are exposed by the integrator to
 * enable the web sdk to interact with frames and windows outside of the
 * context of the web sdk.
 *
 * IMPORTANT: Integrator API only works if the provided fullscreen window is running on the
 * same origin as the web sdk is integrated on.
 *
 * This is mainly used by KCO to display fullscreen dialogs outside of the KCO iframe.
 */

export function getIntegratorApi(win = window): Window['klarnaIntegratorApi'] {
  return win.klarnaIntegratorApi
}

export function getIntegratorApiFullscreenIframeWindow(win = window) {
  try {
    return win.parent.frames[win.klarnaIntegratorApi.fullscreen.frameId]
  } catch (error) {
    // silent error
  }
}

export function tryIntegratorApiFullscreenShow(win = window) {
  try {
    win.klarnaIntegratorApi.fullscreen.show({ isOpf: true })
  } catch (error) {
    // silent error
  }
}

export function tryIntegratorApiFullscreenHide(win = window) {
  try {
    win.klarnaIntegratorApi.fullscreen.hide({ isOpf: true })
  } catch (error) {
    // silent error
  }
}
