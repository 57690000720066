import { SUPPORTED_LOCALES } from '@klarna-web-sdk/messaging/src/config'

export const isValidLocale = (
  locale: string | null,
  supportedLocales: { [key: string]: string[] } = SUPPORTED_LOCALES
): boolean => {
  if (!locale) {
    return false
  }

  const [language = '', country = ''] = locale.split('-')
  if (!country || !language) {
    return false
  }
  const upperCountry = country.toUpperCase()
  const lowerLanguage = language.toLowerCase()

  const languagesForCountry = supportedLocales[upperCountry]

  if (!languagesForCountry) {
    return false
  }

  return supportedLocales[upperCountry].includes(lowerLanguage)
}
