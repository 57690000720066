import { html } from 'lit'
import { unsafeHTML } from 'lit/directives/unsafe-html.js'

import { KlarnaBadge } from '../../assets/logos'
import addHyperLinks from '../helpers/addHyperLinks/addHyperLinks'
import styles from './style.scss'

function getCarSVG() {
  return `<svg
  aria-labelledby="osm-klarna-shopping-cart"
  viewBox="0 0 50 47"
>
  <path
    fill-rule="evenodd"
    d="M21 2c5 0 9.2 3.7 9.9 8.5h10.6l-1.8 15h6.6v1.7c0 .1.6 12.3 2.5 18l.8 2.3H18.3l.8-2.3.4-1.2h-10c-2.7 0-4.9-2-5.3-4.5l-3.8-29H11A10 10 0 0 1 21 2Zm22 27H25c-.1 3.1-.6 10-1.8 15h21.6A113 113 0 0 1 43 29Zm-5.5-15h-33l3.4 25c0 .9.8 1.5 1.7 1.5h10.7c1-6 1.3-13.2 1.3-13.3v-1.7h14.6L37.5 14Zm-6.3 17.5a2.7 2.7 0 0 0 5.6 0h3.5a6.2 6.2 0 1 1-12.5 0h3.4ZM21 5.5c-3 0-5.6 2.1-6.3 5h12.6c-.7-2.9-3.2-5-6.3-5Z"
  />
</svg>`
}

type HyperLink = {
  text: string
  url: string
}

interface GetPaymentMethodsContentHTMLProps {
  infoPagePMGroups?: {
    title: string
    description: string
    hyper_link_list: HyperLink[]
  }[]
}

function getPaymentMethodsContentHTML(props: GetPaymentMethodsContentHTMLProps): string {
  const { infoPagePMGroups } = props

  if (infoPagePMGroups?.length) {
    const pmGroupsHTML = infoPagePMGroups
      .map((pm) => {
        const { title, description, hyper_link_list } = pm
        return `
        <div class="section-item payment-method">
          <h2 part="osm-heading">${title}</h2>
          <p class="content">
            ${addHyperLinks(description, hyper_link_list)}
          </p>
        </div>
      `
      })
      .join('')
    return `${pmGroupsHTML}`
  }

  return ''
}

type FAQItem = {
  question: string
  answer: string
  hyper_link_list: HyperLink[]
}

type PaymentMethodGroupWithFAQ = {
  title: string
  description: string
  hyper_link_list: HyperLink[]
  faq: FAQItem[]
}

interface GetFaqContentHTMLProps {
  infoPageFAQs?: FAQItem[]
  infoPagePMGroups?: PaymentMethodGroupWithFAQ[]
}

function getFaqContentHTML(props: GetFaqContentHTMLProps) {
  let faqItems: FAQItem[] = []

  const { infoPageFAQs, infoPagePMGroups } = props

  if (infoPageFAQs?.length) faqItems = [...faqItems, ...infoPageFAQs]

  if (infoPagePMGroups?.length)
    infoPagePMGroups.forEach((pm) => (faqItems = [...faqItems, ...pm.faq]))

  const faqHtml = faqItems
    .map((item) => {
      const { question, answer, hyper_link_list } = item
      return `
      <h3 part="osm-heading" class="content question">${question}</h3>
      <p class="content">${addHyperLinks(answer, hyper_link_list)}</p>
    `
    })
    .join('')
  return `<div>${faqHtml}</div>`
}

function getMobileSVG() {
  return `<svg aria-hidden="true" viewBox="0 0 34 50">
  <path
    fill-rule="evenodd"
    d="M28.3 0c2.8 0 5.2 2.4 5.2 5.3v39.5c0 2.8-2.4 5.2-5.3 5.2H5.9a5.2 5.2 0 0 1-5.3-5.3V5.4A5.2 5.2 0 0 1 5.8 0h22.4ZM11.4 3.5H5.7c-1 0-1.7.8-1.7 1.8v39.5c0 1 .8 1.7 1.8 1.7h22.4c1 0 1.8-.8 1.8-1.8V5.4c0-1-.8-1.8-1.8-1.8h-5.7l-2.3 3h-6.4l-2.3-3Zm5.5 36a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm8.3-13a2 2 0 1 1 0 4 2 2 0 0 1 0-4ZM21.7 15c0 3.3-1.3 6.4-3.5 8.6l4.8 6.6h-4.3l-5.3-7.1 1.4-1a8.8 8.8 0 0 0 3.5-7.1ZM13 15v15.2H9.5V15H13Z"
  />
</svg>`
}

type FAQ = {
  question: string
  answer: string
  hyper_link_list: HyperLink[]
}

export type PaymentMethodGroup = {
  title: string
  description: string
  hyper_link_list: HyperLink[]
  faq: FAQ[]
}

interface AvailablePaymentMethodsSectionProps {
  assetsUrl: string
  infoPagePaymentSectionDescription: string
  infoPagePaymentSectionTitle: string
  infoPagePMGroups: PaymentMethodGroup[]
}

function renderAvaialblePaymentMethodsSection(props: AvailablePaymentMethodsSectionProps) {
  const {
    assetsUrl,
    infoPagePaymentSectionDescription,
    infoPagePaymentSectionTitle,
    infoPagePMGroups,
  } = props

  return unsafeHTML(`
      <section class="columns">
          <div class="section-item">
          <div class="section-image">
              <img loading="lazy" alt="Klarna Checkout is easy, convenient and fast"
              src="${assetsUrl}/images/info-page-sleeping.webp"/>
          </div>
          </div>

          <div class="section-item">
              <h2 part="osm-heading">${infoPagePaymentSectionTitle}</h2>
              <p class="content">${infoPagePaymentSectionDescription}</p>
          </div>

          ${getPaymentMethodsContentHTML({ infoPagePMGroups })}<br />
      </section>
  `)
}

interface JourneySectionProps {
  infoPageJourneyTitle: string
  infoPageJourneyStepOne: string
  infoPageJourneyStepTwo: string
  infoPageJourneyStepThree: string
}

function renderJourneySection(props: JourneySectionProps) {
  const {
    infoPageJourneyTitle,
    infoPageJourneyStepOne,
    infoPageJourneyStepTwo,
    infoPageJourneyStepThree,
  } = props

  return unsafeHTML(`
      <section class="journey">
          <h2 part="osm-heading">${infoPageJourneyTitle}</h2>
          <div class="journey-steps-wrapper">
          <div class="journey-step">
              <div class="icon">${getCarSVG()}</div>
              <p class="content">${infoPageJourneyStepOne}</p>
          </div>

          <div class="journey-step">
              <div class="icon">${KlarnaBadge({ width: 95, height: 40 })}</div>
              <p class="content">${infoPageJourneyStepTwo}</p>
          </div>

          <div class="journey-step">
              <div class="icon">${getMobileSVG()}</div>
              <p class="content">${infoPageJourneyStepThree}</p>
          </div>

          <div class="journey-step"></div>
          </div>
      </section>
  `)
}

interface GenericContentSectionProps {
  infoPageTitleTertiaryFirstColumn: string
  infoPageContentTertiaryFirstColumn: string
  infoPageTitleTertiarySecondColumn: string
  infoPageContentTertiarySecondColumn: string
}

function renderGenericContentSection(props: GenericContentSectionProps) {
  const {
    infoPageTitleTertiaryFirstColumn,
    infoPageContentTertiaryFirstColumn,
    infoPageTitleTertiarySecondColumn,
    infoPageContentTertiarySecondColumn,
  } = props

  return unsafeHTML(`
      <section class="columns">
          <div class="section-item">
          <h2 part="osm-heading">${infoPageTitleTertiaryFirstColumn}</h2>
          <p class="content">${infoPageContentTertiaryFirstColumn}</p>
          </div>

          <div class="section-item">
          <h2 part="osm-heading">${infoPageTitleTertiarySecondColumn}</h2>
          <p class="content">${infoPageContentTertiarySecondColumn}</p>
          </div>
      </section>
  `)
}

interface FAQSectionProps {
  assetsUrl: string
  infoPageTitleFirstColumn: string
  infoPageContentFirstColumn: string
  infoPageTitleSecondColumn: string
  infoPageContentSecondColumn: string
  infoPageFAQs: FAQ[]
  infoPagePMGroups: PaymentMethodGroup[]
}

function renderFAQSection(props: FAQSectionProps) {
  const {
    assetsUrl,
    infoPageTitleFirstColumn,
    infoPageContentFirstColumn,
    infoPageTitleSecondColumn,
    infoPageContentSecondColumn,
    infoPageFAQs,
    infoPagePMGroups,
  } = props

  return unsafeHTML(`
  <section class="columns">
      <div class="section-item">
          <div class="section-image">
              <img loading="lazy" alt="Manage your purchases through the Klarna app"
              src="${assetsUrl}/images/info-page-app-v2.webp"/>
          </div>
          <h2 part="osm-heading">${infoPageTitleFirstColumn}</h2>
          <p class="content">${infoPageContentFirstColumn}</p>
          ${getFaqContentHTML({ infoPageFAQs, infoPagePMGroups })}
      </div>

      <div class="section-item">
          <div class="section-image">
              <img loading="lazy" alt="Klarna makes shopping smoooth"
              src="${assetsUrl}/images/info-page-pocket.webp"/>
          </div>
          <h2 part="osm-heading">
              ${infoPageTitleSecondColumn}
          </h2>
          <p class="content">${infoPageContentSecondColumn}</p>
      </div>
  </section>
  `)
}

export default function infoPage({
  theme,
  infoPageMainTitle,
  infoPageMainSubtitle,
  assetsUrl,
  infoPageTitleFirstColumn,
  infoPageContentFirstColumn,
  infoPageTitleSecondColumn,
  infoPageContentSecondColumn,
  infoPageTitleTertiaryFirstColumn,
  infoPageContentTertiaryFirstColumn,
  infoPageTitleTertiarySecondColumn,
  infoPageContentTertiarySecondColumn,
  infoPageJourneyTitle,
  infoPageJourneyStepOne,
  infoPageJourneyStepTwo,
  infoPageJourneyStepThree,
  infoPagePaymentSectionDescription,
  infoPagePaymentSectionTitle,
  infoPageFAQs,
  infoPagePMGroups,
}: {
  theme: string
  infoPageMainTitle: string
  infoPageMainSubtitle: string
  assetsUrl: string
  infoPageTitleFirstColumn: string
  infoPageContentFirstColumn: string
  infoPageTitleSecondColumn: string
  infoPageContentSecondColumn: string
  infoPageTitleTertiaryFirstColumn: string
  infoPageContentTertiaryFirstColumn: string
  infoPageTitleTertiarySecondColumn: string
  infoPageContentTertiarySecondColumn: string
  infoPageJourneyTitle: string
  infoPageJourneyStepOne: string
  infoPageJourneyStepTwo: string
  infoPageJourneyStepThree: string
  infoPagePaymentSectionDescription: string
  infoPagePaymentSectionTitle: string
  infoPageFAQs: FAQItem[]
  infoPagePMGroups: PaymentMethodGroupWithFAQ[]
}) {
  return html`
    <style>
      ${styles}
    </style>
    <div
      data-testid="${infoPageMainTitle ? 'info-page' : ''}"
      class="container ${theme}"
      part="osm-container"
    >
      <div class="landing-page">
        <h1 part="osm-heading">${infoPageMainTitle}</h1>
        <p class="content">${infoPageMainSubtitle}</p>

        ${renderAvaialblePaymentMethodsSection({
          assetsUrl,
          infoPagePaymentSectionDescription,
          infoPagePaymentSectionTitle,
          infoPagePMGroups,
        })}
        ${renderJourneySection({
          infoPageJourneyTitle,
          infoPageJourneyStepOne,
          infoPageJourneyStepTwo,
          infoPageJourneyStepThree,
        })}
        ${renderGenericContentSection({
          infoPageTitleTertiaryFirstColumn,
          infoPageContentTertiaryFirstColumn,
          infoPageTitleTertiarySecondColumn,
          infoPageContentTertiarySecondColumn,
        })}
        ${renderFAQSection({
          assetsUrl,
          infoPageTitleFirstColumn,
          infoPageContentFirstColumn,
          infoPageTitleSecondColumn,
          infoPageContentSecondColumn,
          infoPageFAQs,
          infoPagePMGroups,
        })}
      </div>
    </div>
  `
}
