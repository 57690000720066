import { backendBridge } from '@klarna-web-sdk/backend-bridge'
import { snakeCaseToCamelCaseMap } from '@klarna-web-sdk/utils/src/caseConverter'
import { getUserLocale } from '@klarna-web-sdk/utils/src/getUserLocale'
import { getRegion } from '@klarna-web-sdk/utils/src/region'

import { PaymentRequestResponse } from '../types'
import { store } from './store'

export async function fetchPaymentRequest(id?: string): Promise<PaymentRequestResponse> {
  const paymentRequestId = id || store.get('paymentRequestId')
  const paymentRequest = store.get('paymentRequest')

  const apiResponse = await backendBridge.call({
    method: 'paymentApiGetAuthorizationRequest',
    data: {
      id: paymentRequestId,
      region: getRegion({
        paymentRequestId,
        currency: paymentRequest?.currency,
        locale: getUserLocale(),
      }),
    },
  })

  const response = snakeCaseToCamelCaseMap(apiResponse) as PaymentRequestResponse

  store.set('paymentRequest', response.paymentRequest)
  store.set('paymentRequestId', response.paymentRequestId)
  store.set('paymentRequestState', response.state)
  store.set('paymentRequestPreviousState', response.previousState)
  store.set('paymentRequestStateContext', response.stateContext)
  store.set('paymentRequestResult', {
    paymentConfirmationToken: response.stateContext?.paymentConfirmationToken,
  })

  return response
}
