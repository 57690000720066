import { getSampleGroup } from './getSampleGroup'
import { localStorageHelper } from './storage'
import type { RuntimeConfig, VersionConfig } from './types'

const STORAGE_KEY = 'version'

const setRolloutAndVersion = ({
  defaultVersion,
  canaryVersion,
  canaryWeight,
}: VersionConfig): string | void => {
  const ttl = 60 * 60 * 24
  const localStorage = localStorageHelper(STORAGE_KEY)
  const time = Date.now() + ttl * 1000
  localStorage.set('ttl', time.toString())

  if (getSampleGroup(canaryWeight * 100)) {
    if (getSampleGroup(50)) {
      localStorage.set('rolloutVariant', 'canary')
      localStorage.set('version', canaryVersion)
      return canaryVersion
    } else {
      localStorage.set('rolloutVariant', 'control')
      localStorage.set('version', defaultVersion)
      return defaultVersion
    }
  } else {
    localStorage.set('rolloutVariant', 'none')
    localStorage.set('version', defaultVersion)
    return defaultVersion
  }
}

export const getRolloutVariant = () => {
  try {
    const localStorage = localStorageHelper(STORAGE_KEY)
    const rolloutVariant = localStorage.get('rolloutVariant')

    return rolloutVariant
  } catch {
    return 'none'
  }
}

const getVersion = (config: RuntimeConfig): string => {
  if (!config) {
    throw new Error('Missing runtime config')
  }

  try {
    const localStorage = localStorageHelper(STORAGE_KEY)

    // Flush if canaryVersion has been promoted to defaultVersion or if flushVersion is set
    if (
      config.lib.defaultVersion === config.lib?.canaryVersion ||
      config.lib?.flushVersion === 'true'
    ) {
      localStorage.remove('version')
      localStorage.remove('rolloutVariant')
      localStorage.remove('ttl')
    }

    const version = localStorage.get('version')
    if (version) {
      return version
    } else {
      const result = setRolloutAndVersion(config.lib)
      if (result) {
        return result
      } else {
        throw new Error('Failed to set rollout and version')
      }
    }
  } catch (error) {
    if (config.lib.defaultVersion) return config.lib.defaultVersion

    throw new Error(`Failed to get version: ${error.message}`)
  }
}

export default getVersion
