import { html, svg } from 'lit'

export const Mastercard = html`<svg
  part="badge"
  role="img"
  focusable="false"
  viewBox="0 0 35 22"
  aria-label="Mastercard"
>
  ${svg`<g fill="none">
    <path fill="#FF5F00" d="M12.144 3.898h8.591v13.959h-8.591z"/><path fill="#EB001B" d="M13.0286 10.87904c-.00215-2.72342 1.25459-5.29659 3.40812-6.97808-3.65851-2.86026-8.91228-2.44364-12.0691.95708-3.15683 3.40071-3.15683 8.64376 0 12.04448 3.15682 3.40071 8.41059 3.81733 12.0691.95707-2.15422-1.68202-3.41104-4.25626-3.40812-6.98055Z"/><path fill="#F79E1B" d="M30.87476 10.87904c0 3.39865-1.95083 6.4989-5.02376 7.98409-3.07292 1.48518-6.72853 1.09455-9.41428-1.006 2.1526-1.68292 3.40943-4.25575 3.40943-6.97932 0-2.72357-1.25683-5.2964-3.40943-6.97931 2.68575-2.10055 6.34136-2.49119 9.41428-1.006 3.07293 1.48518 5.02376 4.58544 5.02376 7.98408v.00246ZM30.23 17v-.82857h.16667V16H30v.17143h.15667V17H30.23Zm.77 0v-1h-.12l-.14.71429L30.6 16h-.12v1h.08667v-.75714l.13.65h.09l.13-.65V17H31Z"/>
  </g>`}
</svg> `
