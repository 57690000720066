import type { EventBus } from '@klarna-web-sdk/utils'
import type { SDKConfig } from '@klarna-web-sdk/utils/src/types'

import { ErrorCodes, ErrorTypes, PaymentRequestState } from '../constants'
import { PaymentRequestDataAllOptional, PaymentRequestOptions } from '../schema'
import type {
  PaymentRequestData as PaymentRequestDataType,
  PaymentRequestOptions as PaymentRequestOptionsType,
  PaymentRequestStateContext as PaymentRequestStateContextType,
} from '../types'
import { PaymentError } from './paymentError'
type intervalMatch = {
  type?: string
  intervalId?: ReturnType<typeof setInterval>
}
interface IStoreData {
  config?: SDKConfig
  emit?: typeof EventBus.prototype.emit
  paymentRequest?: Partial<PaymentRequestDataType>
  paymentRequestId?: string
  paymentRequestOptions?: Partial<PaymentRequestOptionsType>
  paymentRequestState: PaymentRequestState
  paymentRequestPreviousState?: PaymentRequestState
  paymentRequestResult?: {
    paymentConfirmationToken?: string | null
  }
  paymentRequestStateContext?: Partial<PaymentRequestStateContextType>
  pollingQueue?: Array<{ type: string; intervalId: ReturnType<typeof setInterval> }>
}

type StoreDataKey = keyof IStoreData

interface IStore {
  clear: () => void
  delete: (key: StoreDataKey) => void
  get: <T extends StoreDataKey>(key: T) => IStoreData[T]
  set: <T extends StoreDataKey>(key: T, value: IStoreData[T]) => void
  updatePaymentRequest: (context: Partial<PaymentRequestDataType>) => void
  updatePaymentRequestOptions: (options: Partial<PaymentRequestOptionsType>) => void
  resetPaymentRequest: () => void
}

export const StoreData: IStoreData = {
  paymentRequest: {},
  paymentRequestOptions: {},
  paymentRequestState: PaymentRequestState.CREATED,
  pollingQueue: [],
}

export const store: IStore = {
  clear() {
    Object.keys(StoreData).forEach((key) => {
      delete StoreData[key as StoreDataKey]
    })
  },
  set(key, value) {
    StoreData[key] = value
  },
  get(key) {
    return StoreData[key]
  },
  delete(key) {
    delete StoreData[key]
  },
  updatePaymentRequest(context) {
    StoreData.paymentRequest = {
      ...StoreData.paymentRequest,
      ...context,
    }
  },
  updatePaymentRequestOptions(options) {
    StoreData.paymentRequestOptions = {
      ...StoreData.paymentRequestOptions,
      ...options,
    }
  },
  resetPaymentRequest() {
    StoreData.paymentRequest = {}
    StoreData.paymentRequestId = undefined
    StoreData.paymentRequestOptions = {}
    StoreData.paymentRequestPreviousState = undefined
    StoreData.paymentRequestResult = undefined
    StoreData.paymentRequestState = PaymentRequestState.CREATED
    StoreData.paymentRequestStateContext = undefined
  },
}

export const storeUpdatePaymentRequest = (paymentRequest: Partial<PaymentRequestDataType>) => {
  const parsedPaymentRequest = PaymentRequestDataAllOptional.safeParse(paymentRequest)

  if (parsedPaymentRequest.success === false) {
    throw new PaymentError(
      ErrorTypes.INPUT_ERROR,
      ErrorCodes.VALIDATION_ERROR,
      'Invalid PaymentRequest',
      parsedPaymentRequest.error
    )
  }

  store.updatePaymentRequest(parsedPaymentRequest.data)
}

export const storeUpdatePaymentRequestOptions = (options: Partial<PaymentRequestOptionsType>) => {
  const parsedOptions = PaymentRequestOptions.safeParse(options)

  if (parsedOptions.success === false) {
    throw new PaymentError(
      ErrorTypes.INPUT_ERROR,
      ErrorCodes.VALIDATION_ERROR,
      'Invalid PaymentRequestOptions',
      parsedOptions.error
    )
  }

  store.updatePaymentRequestOptions(parsedOptions.data)
}

export const cancelPollingInterval = ({ type, intervalId }: intervalMatch) => {
  const pollingQueue = store.get('pollingQueue')
  if (!pollingQueue.length) return
  if (intervalId) {
    clearInterval(intervalId)
    store.set(
      'pollingQueue',
      pollingQueue.filter((item) => item.intervalId !== intervalId)
    )
    return
  }
  const pollingItem = pollingQueue.find((item) => item.type === type)

  if (pollingItem) {
    clearInterval(pollingItem.intervalId)
    store.set(
      'pollingQueue',
      pollingQueue.filter((item) => item.type !== type)
    )
  }
}

export const addPollingInterval = (pollingItem: {
  type: string
  intervalId: ReturnType<typeof setInterval>
}) => {
  const pollingQueue = store.get('pollingQueue') || []
  store.set('pollingQueue', [...pollingQueue, pollingItem])
}
