import { SentryRequestBody } from '@micro-sentry/core'

import { validateKlarnaOrigin } from '../validateKlarnaOrigin'

export const ignoreNonSDKErrors = (event: SentryRequestBody) => {
  let shouldDropError = true
  event.exception?.values?.forEach(({ stacktrace }) => {
    stacktrace?.frames?.forEach(({ filename }) => {
      shouldDropError = !validateKlarnaOrigin(filename)
    })
  })

  if (shouldDropError) {
    return null
  }

  return event
}
