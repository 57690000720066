import { jwtDecode } from 'jwt-decode'

import type { Environment } from './types'
interface DecodedToken {
  session_id: string
  client_id: string
  environment: Environment
}

export const decodeClientToken = (clientToken: string) => {
  try {
    const decodedToken: DecodedToken = jwtDecode(clientToken)
    const sessionId = decodedToken['session_id']
    const clientId = decodedToken['client_id']
    const environment = decodedToken['environment']

    return {
      sessionId,
      clientId,
      environment,
    }
  } catch (error) {
    console.error('Error decoding JWT: ', error.message)
  }
}
