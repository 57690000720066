import { html } from 'lit'

export default function iconCheckmark() {
  return html`<svg aria-hidden="true" role="img" viewBox="0 0 20 20" height="20" width="20">
    <g fill="none" fill-rule="evenodd" transform="translate(4, 4) scale(1)">
      <path
        class="checkout-icon"
        d="M10.215 1 4.292 8.558 1.414 5.68 0 7.094l3.676 3.676a.999.999 0 0 0 .707.293l.06-.002c.286-.018.55-.157.727-.381l6.619-8.446L10.215 1Z"
        fill="#5F6163"
        style="transition: fill 0.2s ease 0s;"
      ></path>
    </g>
  </svg>`
}
