import { html } from 'lit'

const klarnaPinkBadgeLogo = html`<svg
  viewBox="0 0 38 22"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <title>Acceptance mark / Klarna / Inside Checkout / Pink</title>
  <g id="Delivery" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Acceptance-mark-/-Klarna-/-Inside-Checkout-/-Pink">
      <g id="Mark" transform="translate(-0.000300, 0.000400)">
        <path
          d="M35,22 L3,22 C1.344,22 0,20.656 0,19 L0,3 C0,1.343 1.344,0 3,0 L35,0 C36.657,0 38,1.343 38,3 L38,19 C38,20.656 36.657,22 35,22"
          id="BG"
          fill="#FFB3C7"
        ></path>
        <path
          d="M30.5664,13.3271 C29.8184,13.3271 29.2124,12.7531 29.2124,12.0431 C29.2124,11.3341 29.8184,10.7601 30.5664,10.7601 C31.3144,10.7601 31.9204,11.3341 31.9204,12.0431 C31.9204,12.7531 31.3144,13.3271 30.5664,13.3271 L30.5664,13.3271 Z M31.9244,9.5481 L31.9244,9.8671 C31.5034,9.5791 30.9934,9.4101 30.4444,9.4101 C28.9894,9.4101 27.8104,10.5891 27.8104,12.0431 C27.8104,13.4981 28.9894,14.6771 30.4444,14.6771 C30.9934,14.6771 31.5034,14.5081 31.9244,14.2201 L31.9244,14.5391 L33.4204,14.5391 L33.4204,9.5481 L31.9244,9.5481 Z"
          id="Letter"
          fill="#000000"
        ></path>
        <path
          d="M8.792,7.3222 L7.171,7.3222 C7.171,8.6502 6.561,9.8702 5.495,10.6682 L4.854,11.1482 L7.341,14.5412 L9.387,14.5412 L7.098,11.4192 C8.183,10.3392 8.792,8.8842 8.792,7.3222"
          id="Letter"
          fill="#000000"
        ></path>
        <polygon
          id="Letter"
          fill="#000000"
          points="2.9953 14.5406 4.6553 14.5406 4.6553 7.3216 2.9953 7.3216"
        ></polygon>
        <polygon
          id="Letter"
          fill="#000000"
          points="9.8693 14.5386 11.4333 14.5386 11.4333 7.3236 9.8693 7.3236"
        ></polygon>
        <path
          d="M25.1552,9.414 C24.5582,9.414 23.9932,9.599 23.6152,10.11 L23.6152,9.548 L22.1282,9.548 L22.1282,14.539 L23.6342,14.539 L23.6342,11.916 C23.6342,11.157 24.1422,10.785 24.7562,10.785 C25.4122,10.785 25.7902,11.178 25.7902,11.905 L25.7902,14.539 L27.2822,14.539 L27.2822,11.365 C27.2822,10.203 26.3592,9.414 25.1552,9.414"
          id="Letter"
          fill="#000000"
        ></path>
        <path
          d="M14.8017,13.3271 C14.0537,13.3271 13.4477,12.7531 13.4477,12.0431 C13.4477,11.3341 14.0537,10.7601 14.8017,10.7601 C15.5497,10.7601 16.1557,11.3341 16.1557,12.0431 C16.1557,12.7531 15.5497,13.3271 14.8017,13.3271 L14.8017,13.3271 Z M16.1607,9.5481 L16.1607,9.8671 C15.7387,9.5791 15.2297,9.4101 14.6797,9.4101 C13.2257,9.4101 12.0457,10.5891 12.0457,12.0431 C12.0457,13.4981 13.2257,14.6771 14.6797,14.6771 C15.2297,14.6771 15.7387,14.5081 16.1607,14.2201 L16.1607,14.5391 L17.6557,14.5391 L17.6557,9.5481 L16.1607,9.5481 Z"
          id="Letter"
          fill="#000000"
        ></path>
        <path
          d="M19.9668,10.1982 L19.9668,9.5482 L18.4368,9.5482 L18.4368,14.5392 L19.9698,14.5392 L19.9698,12.2092 C19.9698,11.4232 20.8218,11.0002 21.4128,11.0002 L21.4318,11.0002 L21.4318,9.5482 C20.8238,9.5482 20.2668,9.8072 19.9668,10.1982"
          id="Letter"
          fill="#000000"
        ></path>
        <path
          d="M35.0058,12.7685 C34.4878,12.7685 34.0688,13.1885 34.0688,13.7055 C34.0688,14.2245 34.4878,14.6445 35.0058,14.6445 C35.5238,14.6445 35.9428,14.2245 35.9428,13.7055 C35.9428,13.1885 35.5238,12.7685 35.0058,12.7685"
          id="Letter"
          fill="#000000"
        ></path>
      </g>
    </g>
  </g>
</svg>`

export default klarnaPinkBadgeLogo
