/* eslint-disable @typescript-eslint/ban-ts-comment */
import { html } from 'lit'
import { unsafeHTML } from 'lit/directives/unsafe-html.js'

export default function renderLegacy({
  code,
  onLearnMoreClick,
}: {
  code: string
  onLearnMoreClick: (e: PointerEvent, link: string) => void
}) {
  let ctaLink = ''

  function onClick(e: PointerEvent) {
    onLearnMoreClick(e, ctaLink)
  }

  setTimeout(() => {
    const actionElements = [...document.querySelectorAll('[onclick^="kmerchant"]')]
    document.querySelectorAll('klarna-placement').forEach((el) => {
      if (el.shadowRoot === null) {
        return
      }
      const shadowRootEl = el.shadowRoot.querySelector('[onclick^="kmerchant"]')
      if (shadowRootEl) {
        actionElements.push(shadowRootEl)
      }
    })

    for (const actionElement of actionElements) {
      const action = actionElement.getAttribute('onclick')
      const regex = /\{[^}]*url: '([^']*)[^}]*\}/
      const match = action.match(regex)
      const url = match[1]
      ctaLink = url
      actionElement.setAttribute('onclick', '')

      actionElement.addEventListener('click', onClick.bind(this))
    }
  }, 1000)

  return html`${unsafeHTML(code)}`
}
