import { PaymentError } from '@klarna-web-sdk/payment/src/utils/paymentError'

import { ErrorCodes, ErrorTypes, TrackingEvents } from '../constants'
import { PaymentRequestData as PaymentRequestDataType } from '../types'
import { tracker } from '../utils/paymentTracker'
import { store, storeUpdatePaymentRequest } from '../utils/store'
import { updatePaymentRequest } from '../utils/updatePaymentRequest'

export async function update(paymentRequest?: Partial<PaymentRequestDataType>) {
  const paymentRequestId = store.get('paymentRequestId')

  tracker().event(TrackingEvents.UPDATE_CALLED, { paymentRequestId })

  if (paymentRequest) storeUpdatePaymentRequest(paymentRequest)

  const updatedPaymentRequest = store.get('paymentRequest') as PaymentRequestDataType

  // only update locally when no paymentRequest is made yet
  if (!paymentRequestId) {
    return
  }

  try {
    const response = await updatePaymentRequest(paymentRequestId, updatedPaymentRequest)
    tracker().event(TrackingEvents.UPDATE_COMPLETED, {
      paymentRequestId: response.paymentRequestId,
    })
  } catch (error) {
    throw new PaymentError(
      ErrorTypes.TECHNICAL_ERROR,
      ErrorCodes.INTERNAL_ERROR,
      'Update failed',
      error
    )
  }
}
