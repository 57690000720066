import { isLegacyOSMClientId } from '@klarna-web-sdk/utils'

import addQueryParams from '../addQueryParams/addQueryParams'

export default function enhanceLinkWithClientInfo(link: string, clientId: string): string {
  const origin = window.origin || window.location.origin
  let ctaLink = link

  if (isLegacyOSMClientId(clientId)) {
    ctaLink = addQueryParams(ctaLink, 'legacyOSMClientId', clientId)
  } else {
    ctaLink = addQueryParams(ctaLink, 'integratorOrigin', origin)
    ctaLink = addQueryParams(ctaLink, 'clientId', clientId)
  }

  return ctaLink
}
