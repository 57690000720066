import { logError } from '@klarna-web-sdk/utils'
import { v4 as uuid } from 'uuid'

import { EShoppingStorageKeys } from './constants'
import { PublicPayload, PublicResponse, sessionListenerFunc } from './types'
import {
  createShoppingSession,
  getInteroperabilityToken,
  getShoppingSession,
} from './utils/shoppingSessionRequests'
import { sessionBrowserStorage } from './utils/storage'

export class ShoppingSDK {
  // eslint-disable-next-line no-use-before-define
  static instance: ShoppingSDK
  static getInstance(shoppingSessionId?: ShoppingSDK['shoppingSessionId']) {
    if (!ShoppingSDK.instance) {
      ShoppingSDK.instance = new ShoppingSDK(shoppingSessionId)
    }
    return ShoppingSDK.instance
  }

  private shoppingSessionId: string | undefined
  private shoppingSession: PublicResponse | undefined
  private listeners: Record<string, sessionListenerFunc> = {}
  private errorDuringSessionCreation = false

  private constructor(shoppingSessionId?: ShoppingSDK['shoppingSessionId']) {
    if (shoppingSessionId) {
      this.shoppingSessionId = shoppingSessionId
      sessionBrowserStorage.set(EShoppingStorageKeys.ShoppingSessionId, this.shoppingSessionId)
    } else {
      try {
        const sessionId = sessionBrowserStorage.get(EShoppingStorageKeys.ShoppingSessionId)
        if (sessionId) this.shoppingSessionId = sessionId
      } catch (error) {
        logError('Unable to retrieve shopping session from local storage')
      }
    }

    // retrieve shopping session from backend if exists
    if (this.shoppingSessionId) this.retrieveSessionFromService(this.shoppingSessionId)
  }

  private async retrieveSessionFromService(shoppingSessionId: string) {
    try {
      this.shoppingSession = await getShoppingSession(shoppingSessionId)
      this.triggerListeners()
    } catch (error) {
      logError('Shopping Session not found for provided shoppingSessionId')
    }
  }

  private async createSessionInService(
    data: Partial<PublicPayload> = {
      supplementaryPurchaseData: {},
    }
  ) {
    try {
      this.shoppingSession = await createShoppingSession(data)
      this.shoppingSessionId = this.shoppingSession.shoppingSessionId
      sessionBrowserStorage.set(EShoppingStorageKeys.ShoppingSessionId, this.shoppingSessionId)
    } catch (error) {
      logError('Error creating shopping session')
      this.errorDuringSessionCreation = true
    }
  }

  private triggerListeners() {
    Object.values(this.listeners).forEach((listener) => {
      listener(this.shoppingSession)
    })
  }

  private async getInteroperabilityToken(): Promise<string> {
    if (!this.shoppingSessionId) {
      logError('Shopping session id not found')
      return
    }

    try {
      return await getInteroperabilityToken(this.shoppingSessionId)
    } catch (error) {
      logError('Error while fetching interoperability token')
    }
  }

  public async getId(
    { forceCreateSession }: { forceCreateSession: boolean } = { forceCreateSession: false }
  ) {
    if (this.shoppingSessionId) return this.shoppingSessionId
    else {
      if (!forceCreateSession) return undefined

      try {
        await this.createSessionInService()
        return this.shoppingSessionId
      } catch (error) {
        logError(`Error creating shopping session: ${error}`)
        return undefined
      }
    }
  }

  public addListener(listener: sessionListenerFunc) {
    const listenerId = uuid()
    this.listeners[listenerId] = listener
    return listenerId
  }

  public removeListener(listenerId: string) {
    delete this.listeners[listenerId]
  }

  public async refresh() {
    await this.retrieveSessionFromService(this.shoppingSessionId)
  }

  public async userInteracted() {
    if (!this.shoppingSessionId && !this.errorDuringSessionCreation) {
      await this.createSessionInService()
      this.triggerListeners()
    }
  }

  /**
   * @hidden
   */
  getPublicAPI() {
    return {
      getId: this.getId.bind(this),
      session: {
        submit: async (consumerSessionPayload?: Partial<PublicPayload>) => {
          return createShoppingSession(consumerSessionPayload)
        },
      },
      getInteroperabilityToken: this.getInteroperabilityToken.bind(this),
    }
  }
}

export type ShoppingSDKPublicAPI = ReturnType<typeof ShoppingSDK.prototype.getPublicAPI>

export const useShoppingSession = () => {
  const ShoppingSdkInstance = ShoppingSDK.getInstance()

  if (String(__IS_STAGING__).toLowerCase() === 'true') {
    return {
      getId: ShoppingSdkInstance.getId.bind(ShoppingSdkInstance) as ShoppingSDK['getId'],
      addListener: ShoppingSdkInstance.addListener.bind(
        ShoppingSdkInstance
      ) as ShoppingSDK['addListener'],
      removeListener: ShoppingSdkInstance.removeListener.bind(
        ShoppingSdkInstance
      ) as ShoppingSDK['removeListener'],
      userInteracted: ShoppingSdkInstance.userInteracted.bind(
        ShoppingSdkInstance
      ) as ShoppingSDK['userInteracted'],
      refresh: ShoppingSdkInstance.refresh.bind(ShoppingSdkInstance) as ShoppingSDK['refresh'],
    }
  } else {
    return {
      getId: async (): Promise<string> => {
        return undefined
      },
      addListener: () => {
        return 'fakeListenerId'
      },
      removeListener: () => {},
      userInteracted: () => {},
      refresh: () => {},
    }
  }
}
