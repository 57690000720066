import { html, svg } from 'lit'

export const Klarna = html`<svg
  part="badge"
  role="img"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 60 30"
  aria-label="Klarna"
>
  ${svg`<g fill="none" fill-rule="evenodd">
    <path fill="#FFB3C7" d="M55.5 30h-51C2.025 30 0 27.975 0 25.5v-21C0 2.0265 2.025 0 4.5 0h51C57.9735 0 60 2.0265 60 4.5v21c0 2.475-2.0265 4.5-4.5 4.5"></path><path fill="#000" d="M45.74265 18.1758c-1.02 0-1.8465-.783-1.8465-1.752 0-.966.8265-1.7505 1.8465-1.7505s1.8465.7845 1.8465 1.7505c0 .969-.8265 1.752-1.8465 1.752Zm1.8525-5.157v.438c-.5745-.393-1.269-.6255-2.019-.6255-1.9845 0-3.5925 1.6095-3.5925 3.5925 0 1.9845 1.608 3.5925 3.5925 3.5925.75 0 1.4445-.231 2.019-.6225v.435h2.04v-6.81h-2.04Zm-31.5522-3.0345h-2.2125c0 1.812-.831 3.474-2.2845 4.563l-.876.6555 3.3945 4.6275h2.79l-3.123-4.257c1.479-1.4745 2.3115-3.4575 2.3115-5.589m-7.9077 9.84615h2.265v-9.846h-2.265zm9.3765-.003h2.1345v-9.843h-2.1345zM38.3613 12.8364c-.813 0-1.584.2535-2.0985.951v-.7665h-2.0295v6.807h2.0535v-3.5775c0-1.035.6945-1.542 1.5315-1.542.894 0 1.41.534 1.41 1.5285v3.591h2.0355v-4.3305c0-1.5825-1.26-2.661-2.9025-2.661m-14.12115 5.3394c-1.02 0-1.8465-.783-1.8465-1.752 0-.966.8265-1.7505 1.8465-1.7505s1.8465.7845 1.8465 1.7505c0 .969-.8265 1.752-1.8465 1.752Zm1.8525-5.157v.438c-.5745-.393-1.269-.6255-2.019-.6255-1.9845 0-3.5925 1.6095-3.5925 3.5925 0 1.9845 1.608 3.5925 3.5925 3.5925.75 0 1.4445-.231 2.019-.6225v.435h2.04v-6.81h-2.04Zm5.19195.88845v-.8865h-2.0865v6.807h2.091v-3.1785c0-1.0725 1.1625-1.6485 1.968-1.6485h.0255v-1.98c-.828 0-1.59.3555-1.998.8865m20.5137 3.50535c-.7065 0-1.2795.573-1.2795 1.281 0 .705.573 1.278 1.2795 1.278.7065 0 1.2795-.573 1.2795-1.278 0-.708-.573-1.281-1.2795-1.281"></path>
  </g>`}
</svg>`
