import type { Region, TimeZoneRegion } from '../types.d.ts'

export type TimeZoneMapping = {
  [key in Lowercase<TimeZoneRegion>]: Lowercase<Region>
}

export const timeZoneMapping: TimeZoneMapping = {
  africa: 'eu',
  america: 'na',
  antarctica: 'na',
  arctic: 'na',
  asia: 'oc',
  atlantic: 'na',
  australia: 'oc',
  europe: 'eu',
  indian: 'oc',
  pacific: 'na',
}
