import { Environment, type Region } from '@klarna-web-sdk/utils/src/types'

import type { EnvironmentTracker } from '../components/IdentityTracker'

export type EnvironmentConfig = {
  /**
   * OpenID Connect config urls
   * issuerUrl refers to the url that provides he OIDC authentication and authorization
   * idpUrl refers to the Identity provider url
   */
  oidc: {
    idpUrl: string
    issuerUrl: string
  }
  tracker: EnvironmentTracker
}

type Config = {
  [environmentKey in Environment]: {
    [regionKey in Region]: EnvironmentConfig
  }
}

export const CONFIG: Config = {
  development: {
    EU: {
      oidc: {
        idpUrl: 'https://login.nonprod.klarna.net/',
        issuerUrl: 'https://login.nonprod.klarna.net',
      },
      tracker: {
        url: 'https://frontend-event-router-eu.staging.c2c.klarna.net',
        // todo: do we need this?
        enabled: false,
      },
    },
    NA: {
      oidc: {
        idpUrl: 'https://login.nonprod.klarna.net/na/lp/idp',
        issuerUrl: 'https://login.nonprod.klarna.net',
      },
      tracker: {
        url: 'https://frontend-event-router-us.staging.c2c.klarna.net',
        enabled: false,
      },
    },
    OC: {
      oidc: {
        idpUrl: '',
        issuerUrl: '',
      },
      tracker: {
        url: '',
        enabled: false,
      },
    },
  },
  staging: {
    EU: {
      oidc: {
        idpUrl: 'https://login.nonprod.klarna.net/',
        issuerUrl: 'https://login.nonprod.klarna.net',
      },
      tracker: {
        url: 'https://frontend-event-router-eu.staging.c2c.klarna.net',
        enabled: true,
      },
    },
    NA: {
      oidc: {
        idpUrl: 'https://login.nonprod.klarna.net/na/lp/idp',
        issuerUrl: 'https://login.nonprod.klarna.net',
      },
      tracker: {
        url: 'https://frontend-event-router-us.staging.c2c.klarna.net',
        enabled: true,
      },
    },
    OC: {
      oidc: {
        idpUrl: '',
        issuerUrl: '',
      },
      tracker: {
        url: '',
        enabled: false,
      },
    },
  },
  playground: {
    EU: {
      oidc: {
        idpUrl: 'https://login.playground.klarna.com/',
        issuerUrl: 'https://login.playground.klarna.com',
      },
      tracker: {
        url: 'https://eu.playground.klarnaevt.com',
        enabled: true,
      },
    },
    NA: {
      oidc: {
        idpUrl: 'https://login.playground.klarna.com/na/lp/idp',
        issuerUrl: 'https://login.playground.klarna.com',
      },
      tracker: {
        url: 'https://na.playground.klarnaevt.com',
        enabled: true,
      },
    },
    OC: {
      oidc: {
        idpUrl: '',
        issuerUrl: '',
      },
      tracker: {
        url: '',
        enabled: false,
      },
    },
  },
  production: {
    EU: {
      oidc: {
        idpUrl: 'https://login.klarna.com/',
        issuerUrl: 'https://login.klarna.com',
      },
      tracker: {
        url: 'https://eu.klarnaevt.com',
        enabled: true,
      },
    },
    NA: {
      oidc: {
        idpUrl: 'https://login.klarna.com/na/lp/idp',
        issuerUrl: 'https://login.klarna.com',
      },
      tracker: {
        url: 'https://na.klarnaevt.com',
        enabled: true,
      },
    },
    OC: {
      oidc: {
        idpUrl: '',
        issuerUrl: '',
      },
      tracker: {
        url: '',
        enabled: false,
      },
    },
  },
}
