import { logWarn } from '@klarna-web-sdk/utils'

import { TrackingEvents } from '../constants'
import { tracker } from '../utils/paymentTracker'

export async function prepare() {
  // TODO: implement prepare - this is a placeholder
  tracker().event(TrackingEvents.PREPARE_CALLED)
  logWarn('prepare is not implemented yet')
  tracker().event(TrackingEvents.PREPARE_COMPLETED)
}
