import { html } from 'lit'
import { unsafeHTML } from 'lit/directives/unsafe-html.js'

import iconCheckmark from './iconCheckmark'
import styles from './style.scss'
import zeroFinancingBadge from './zeroFinancingBadge'

interface CheckoutLearnMoreProps {
  mainText?: string
  ctaLabel?: string
  ctaLink?: string
  theme?: string
  locale: string
  zeroInterestBadgeText?: string
  onLearnMoreClick: () => null
  buyersProtectionLabel?: string
  buyersProtectionLink?: string
}

export default function checkout(props: CheckoutLearnMoreProps) {
  const {
    onLearnMoreClick,
    locale,
    mainText,
    ctaLabel,
    ctaLink,
    theme,
    zeroInterestBadgeText,
    buyersProtectionLabel,
    buyersProtectionLink,
  } = props

  const text = mainText?.replace(
    buyersProtectionLabel,
    '<a class="checkout-buyers-protection-link" target="_blank" href="' +
      buyersProtectionLink +
      '">' +
      buyersProtectionLabel +
      '</a>'
  )

  return html`
    <style>
      ${styles}
    </style>
    <div
      data-testid="checkout"
      class="${locale} checkout-container ${theme}"
      part="osm-checkout-container"
      aria-label="Checkout Information"
      role="region"
    >
      ${zeroInterestBadgeText
        ? html` <div class="checkout-zero-financing">
            ${zeroFinancingBadge()} ${zeroInterestBadgeText}
          </div>`
        : ''}
      <div class="vertical-space"></div>
      <div class="checkmark-flex-container">
        <div class="checkout-icon ${theme}">${iconCheckmark()}</div>
        <div class="horisontal-space"></div>
        <div>
          <div class="checkout-main-text ${theme}" role="heading" aria-level="2">
            ${unsafeHTML(text)}
          </div>
          <div class="vertical-space"></div>
          <div
            class="checkout-text-info"
            @click=${onLearnMoreClick}
            aria-labelledby="checkout-cta-link"
          >
            <span>
              <a id="checkout-cta-link" class="checkout-cta-link" href="${ctaLink}" role="button"
                >${ctaLabel}</a
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  `
}
