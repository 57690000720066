enum ErrorFingerprints {
  sessionStorageNotSupported = 'sessionStorage-not-supported',
  localStorageNotSupported = 'localStorage-not-supported',
  dynamicImportFailed = 'dynamic-import-failed',
  multipleCustomElementsWithSameTagName = 'multiple-custom-elements-same-tag-name',
  unexpectedTokenMethodParamsList = 'unexpected-token-method-params-list',
  missingColonAfterPropertyId = 'missing-colon-after-property-id',
  unexpectedTokenEqual = 'unexpected-token-equal',
  expectedExpressionGotEqual = 'expected-expression-got-equal',
  performanceNotDefined = 'performance-is-not-defined',
  undefinedIsNotAnObjectRenderOptions = 'undefined-is-not-an-object-render-options',
  renderOptionsIsUndefined = 'render-options-is-undefined',
  nullIsNotAnObjectParentNodeInsertBefore = 'null-is-not-an-object-parent-node-insert-before',
  failedToFetchOSMAPI = 'failed-to-fetch-osm-api',
  cannotReadPropertiesOfNull = 'cannot-read-properties-of-null',
  failedToExecuteInvokeOnCreateHTMLCallback = 'failed-to-execute-invoke-on-create-html-callback',
  performanceMarkIsNotAFunction = 'performance-mark-is-not-a-function',
  backendBridgeInitFailed = 'backend-bridge-init-failed',
  globalThisIsNotDefined = 'globalThisIsNotDefined',
  identityNaClientIdsNotFound = 'identity-na-client-ids-not-found',
  chunkLoadErrorObjectNotExtensible = 'chunk-load-error-object-not-extensible',
}

export const getErrorFingerprints = (error: string) => {
  switch (error) {
    case 'Storage type: sessionStorage not supported':
      return [ErrorFingerprints.sessionStorageNotSupported]
    case 'Storage type: localStorage not supported':
      return [ErrorFingerprints.localStorageNotSupported]
    case 'Cannot define multiple custom elements with the same tag name':
      return [ErrorFingerprints.multipleCustomElementsWithSameTagName]
    /**
     * Should be solved with https://babeljs.io/docs/babel-plugin-transform-class-properties or by using ES2022
     * Remove this fingerprint when below issue is resolved
     * https://klarna-1.sentry.io/issues/4566982748/?project=4505471301713920&referrer=issue-stream&statsPeriod=30d&stream_index=0
     */
    case "Unexpected token ';'. Expected an opening '(' before a method's parameter list.":
      return [ErrorFingerprints.unexpectedTokenMethodParamsList]
    // dynamic import failed chrome + webkit browsers
    case error.match(/Failed to fetch dynamically imported module/)?.input:
      return [ErrorFingerprints.dynamicImportFailed]
    // dynamic import failed firefox
    case error.match('missing : after property id')?.input:
      return [ErrorFingerprints.missingColonAfterPropertyId]
    case error.match("Unexpected token '='")?.input:
      return [ErrorFingerprints.unexpectedTokenEqual]
    case error.match("expected expression, got '='")?.input:
      return [ErrorFingerprints.expectedExpressionGotEqual]
    case error.match(/error loading dynamically imported module/)?.input:
      return [ErrorFingerprints.dynamicImportFailed]
    case error.match(/performance is not defined/)?.input:
      return [ErrorFingerprints.performanceNotDefined]
    case error.match(/performance?.mark is not a function/)?.input:
      return [ErrorFingerprints.performanceMarkIsNotAFunction]
    case error.match(/undefined is not an object (evaluating 'this.renderOptions.renderBefore')/)
      ?.input:
      return [ErrorFingerprints.undefinedIsNotAnObjectRenderOptions]
    case error.match(/this.renderOptions is undefined/)?.input:
      return [ErrorFingerprints.renderOptionsIsUndefined]
    case error.match(/Cannot read properties of null (reading 'insertBefore')/)?.input:
      return [ErrorFingerprints.cannotReadPropertiesOfNull]
    case error.match(/null is not an object (evaluating 'this._$AA.parentNode.insertBefore')/)
      ?.input:
      return [ErrorFingerprints.nullIsNotAnObjectParentNodeInsertBefore]
    case error.match(
      /Failed to execute 'invoke' on 'CreateHTMLCallback': The provided callback is no longer runnable./
    )?.input:
      return [ErrorFingerprints.failedToExecuteInvokeOnCreateHTMLCallback]
    case error.match('Failed to fetch: GET https://js.klarna.com/na/cma/')?.input:
      return [ErrorFingerprints.failedToFetchOSMAPI]
    case error.match('BackendBridge: init failed')?.input:
      return [ErrorFingerprints.backendBridgeInitFailed]
    case error.match('globalThis is not defined')?.input:
      return [ErrorFingerprints.globalThisIsNotDefined]
    case error.match('Identity naClientIds list not found')?.input:
      return [ErrorFingerprints.identityNaClientIdsNotFound]
    case error.match('Cannot add property timelineEnd, object is not extensible')?.input:
      return [ErrorFingerprints.chunkLoadErrorObjectNotExtensible]
    default:
      break
  }
}
