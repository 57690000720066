import { backendBridge } from '@klarna-web-sdk/backend-bridge'
import { snakeCaseToCamelCaseMap } from '@klarna-web-sdk/utils/src/caseConverter'
import { getUserLocale } from '@klarna-web-sdk/utils/src/getUserLocale'
import { getRegion } from '@klarna-web-sdk/utils/src/region'

import { AbortedPaymentRequestResponse } from '../types'
import { cancelPollingInterval, store } from './store'

export async function abortPaymentRequest(): Promise<AbortedPaymentRequestResponse> {
  const paymentRequestId = store.get('paymentRequestId')
  if (!paymentRequestId) {
    throw new Error('Payment request ID is missing')
  }
  const paymentRequest = store.get('paymentRequest')

  // Cancel any current polling mechanisms for payment request state
  cancelPollingInterval({ type: 'pollingForInProgressState' })

  const apiResponse = await backendBridge.call({
    method: 'paymentApiSendAbortSignal',
    data: {
      id: paymentRequestId,
      region: getRegion({
        paymentRequestId,
        currency: paymentRequest?.currency,
        locale: getUserLocale(),
      }),
    },
  })

  const response = snakeCaseToCamelCaseMap(apiResponse) as AbortedPaymentRequestResponse

  return response
}
