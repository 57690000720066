import { LitElement } from 'lit'

import { reportSentryError } from '../sentry'

// A mixin class must have a constructor with a single rest parameter of type 'any[]'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Constructor<T> = new (...args: any[]) => T

export const ErrorMixin = <T extends Constructor<LitElement>>(superClass: T) => {
  class ErrorMixinClass extends superClass {
    connectedCallback() {
      try {
        super.connectedCallback()
      } catch (error) {
        reportSentryError(error)
      }
    }

    disconnectedCallback() {
      try {
        super.disconnectedCallback()
      } catch (error) {
        reportSentryError(error)
      }
    }

    performUpdate() {
      try {
        super.performUpdate()
      } catch (error) {
        reportSentryError(error)
      }
    }
  }
  return ErrorMixinClass as T
}
