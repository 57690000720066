export default function getPaymentAmount(dataset: DOMStringMap) {
  const { paymentAmount, purchaseAmount, purchase_amount } = dataset
  if (paymentAmount !== undefined) {
    return paymentAmount
  }
  if (purchaseAmount !== undefined) {
    return purchaseAmount
  }
  return purchase_amount
}
