import { array, boolean, number, object, string, union } from 'zod'

const ShippingAddressSchema = object({
  street_address: string(),
  street_address2: string().nullish(),
  city: string(),
  postal_code: string(),
  region: string().nullish(),
  country: string(),
  family_name: string(),
  given_name: string(),
})

const ShippingChangeSuccessResponseSchema = object({
  amount: number().optional(),
  tax_amount: number().optional(),
  purchase_details: object({
    order_lines: array(
      object({
        name: string(),
        quantity: number(),
        total_amount: number(),
        type: string(),
        unit_price: number(),
      }).optional()
    ),
  }).optional(),
  selected_shipping_option_reference: string().optional(),
  shipping_options: array(
    object({
      shipping_option_reference: string(),
      shipping_amount: number(),
      display_name: string(),
      description: string(),
      shipping_type: string().optional(),
    })
  ),
})

const ShippingChangeRejectionResponseSchema = object({
  rejection_reason: string(),
})

const SelectedShippingOptionSchema = object({
  shipping_option_reference: string(),
})

export const MethodsSchema = {
  closeInteractionMode: {
    response: boolean(),
  },
  shippingAddressChange: {
    data: ShippingAddressSchema,
    response: union([ShippingChangeRejectionResponseSchema, ShippingChangeSuccessResponseSchema]),
  },
  shippingOptionSelect: {
    data: SelectedShippingOptionSchema,
    response: object({
      amount: number(),
      taxAmount: number().optional(),
      purchase_details: object({
        order_lines: array(
          object({
            name: string(),
            quantity: number(),
            total_amount: number(),
          }).optional()
        ),
      }),
    }),
  },
}
