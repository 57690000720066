const extendKlarnaNamespace = (name: string, value = {}) => {
  if (!window.Klarna) return

  if (Object.prototype.hasOwnProperty.call(window.Klarna, name)) {
    window.Klarna[name] = {
      ...window.Klarna[name],
      ...value,
    }
  } else {
    window.Klarna[name] = value
  }
}

export default extendKlarnaNamespace
