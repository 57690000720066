/* eslint-disable @typescript-eslint/no-explicit-any */
import { camelCaseToSnakeCase } from '@klarna-web-sdk/utils/src/caseConverter'

import { UpdatedSuccessInformationSnakeCase } from '../types'

export const transformSuccessInformation = (response: any): UpdatedSuccessInformationSnakeCase => {
  const copiedResponse = JSON.parse(JSON.stringify(response))

  const transformKeys = (obj: any): any => {
    if (Array.isArray(obj)) {
      return obj.map((item) => transformKeys(item))
    }

    if (obj !== null && typeof obj === 'object') {
      const transformedObject: any = {}

      for (const key of Object.keys(obj)) {
        switch (key) {
          case 'paymentAmount':
            transformedObject['amount'] = transformKeys(obj[key])
            break
          case 'taxAmount':
            transformedObject['tax_amount'] = transformKeys(obj[key])
            break
          case 'lineItems':
            // Flattening lineItems to order_lines within purchase_details
            transformedObject['purchase_details'] = {
              order_lines: transformKeys(obj[key]).map((orderline: any) => ({
                ...orderline,
                type: 'PHYSICAL',
                unit_price: Math.round(orderline.total_amount / orderline.quantity),
              })),
            }
            break
          case 'selectedShippingOptionReference':
            transformedObject['selected_shipping_option_reference'] = obj[key]
            break
          case 'shippingOptions':
            transformedObject['shipping_options'] = obj[key].map((option: any) => {
              const transformedOption: any = {}
              for (const optionKey of Object.keys(option)) {
                if (optionKey === 'amount') {
                  transformedOption['shipping_amount'] = transformKeys(option[optionKey])
                } else {
                  transformedOption[camelCaseToSnakeCase(optionKey)] = transformKeys(
                    option[optionKey]
                  )
                }
              }
              return transformedOption
            })
            break
          case 'shipping_option_reference':
          case 'shipping_amount':
          case 'display_name':
          case 'total_amount':
          case 'total_tax_amount':
          case 'description':
          case 'shipping_type':
            transformedObject[key] = transformKeys(obj[key])
            break
          default:
            transformedObject[camelCaseToSnakeCase(key)] = transformKeys(obj[key])
        }
      }
      return transformedObject
    }

    return obj
  }

  return transformKeys(copiedResponse) as UpdatedSuccessInformationSnakeCase
}
