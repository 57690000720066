import { TrackerClients } from './trackerClients'
import trackerFactory from './trackerFactory'

export const trackError = (
  errorKey: string,
  message: string,
  trackerClient: TrackerClients = TrackerClients.websdk
) => {
  const tracker = trackerFactory(trackerClient)

  try {
    tracker.event('error', { message, name_1: errorKey })
  } catch {}
}
