import type { ExtraTrackingDataSDK, SDKConfig } from '@klarna-web-sdk/utils/src/types'

import { removeUndefinedAttributes } from '../removeUndefinedAttributes'
import { getFrontendEventRouterUrl } from './getFrontendEventRouterUrl'
import { TrackerClients } from './trackerClients'
import trackerFactory from './trackerFactory'

export interface TrackingConfig {
  version: SDKConfig['version']
  environment?: SDKConfig['environment']
  sessionId: SDKConfig['sessionId']
}

export const configureTracker = ({
  config,
  trackerClient,
  extraTrackingData,
  locale,
}: {
  config: TrackingConfig
  trackerClient: TrackerClients
  extraTrackingData: ExtraTrackingDataSDK
  locale?: string
}) => {
  const { version, environment, sessionId } = config

  const tracker = trackerFactory(trackerClient)

  const trackingData: ExtraTrackingDataSDK = removeUndefinedAttributes({
    clientId: extraTrackingData?.clientId,
    accountId: extraTrackingData?.accountId,
    additionalIdentifier: extraTrackingData?.additionalIdentifier,
    sdk: 'websdk',
    aId: extraTrackingData?.aId,
  })

  tracker.configure({
    options: {
      client: trackerClient,
      clientVersion: version,
      sessionId,
      baseUrl: getFrontendEventRouterUrl({ environment, locale }),
    },
    data: trackingData,
  })

  return tracker
}
