import { EStorageTypes } from './constants'

export function sessionStorage(storageKey: string, win = window) {
  if (!win?.sessionStorage) {
    throw new Error(`client does not support ${EStorageTypes.sessionStorage}`)
  }
  const getStorageObj = () => {
    return JSON.parse(win.sessionStorage.getItem(storageKey) || '{}')
  }

  const StorageOps = {
    get: (itemKey: string) => {
      const storageObj = getStorageObj()

      const item = storageObj?.[itemKey]
      if (!item) return null

      return item
    },
    set: (itemKey: string, data: unknown) => {
      const storageObj = getStorageObj()

      if (data === null) delete storageObj[itemKey]
      else storageObj[itemKey] = data

      return win.sessionStorage.setItem(storageKey, JSON.stringify(storageObj))
    },
    remove: (itemKey: string) => {
      return StorageOps.set(itemKey, null)
    },
    flush: () => {
      return win.sessionStorage.removeItem(storageKey)
    },
  }

  return StorageOps
}
