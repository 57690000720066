import { useShoppingSession } from '@klarna-web-sdk/shopping/src/shoppingSDK'
import { logError } from '@klarna-web-sdk/utils'
import { ZodError } from 'zod'

import { InteroperabilityContextSchema, InteroperabilityOptionsSchema } from './schema'
import type { InteroperabilityContext, InteroperabilityOptions } from './types'
import { resolveInteroperability } from './utils/interoperabilityRequests'

export class InteroperabilitySDK {
  private async resolveInteroperability(
    context: InteroperabilityContext,
    options: InteroperabilityOptions
  ) {
    const contextValidation = InteroperabilityContextSchema.safeParse(context)
    const optionsValidation = InteroperabilityOptionsSchema.safeParse(options)
    const errors: ZodError[] = []
    if (!contextValidation.success) {
      errors.push(contextValidation.error)
    }
    if (!optionsValidation.success) {
      errors.push(optionsValidation.error)
    }

    if (errors.length) {
      logError('Invalid Interoperability context or options ' + JSON.stringify(errors))
      return
    }

    const shoppingSessionId = await useShoppingSession().getId({ forceCreateSession: true })
    if (!shoppingSessionId) {
      logError('Shopping session id not found')
      return
    }

    try {
      return await resolveInteroperability(shoppingSessionId, context, options)
    } catch (error) {
      logError('Error while fetching interoperability data')
    }
  }

  private async resolve(context: InteroperabilityContext, options: InteroperabilityOptions) {
    await this.resolveInteroperability(context, options)
  }

  /**
   * @hidden
   */
  getPublicAPI() {
    return {
      resolve: this.resolve.bind(this),
    }
  }
}

export type InteroperabilitySDKPublicAPI = ReturnType<
  typeof InteroperabilitySDK.prototype.getPublicAPI
>
