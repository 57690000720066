import { getUserLocale } from '../getUserLocale'
import { isStagingEnvironment } from '../isStagingEnvironment'
import { getRegion } from '../region'

export const getFrontendEventRouterUrl = ({
  environment,
  locale,
  currency,
}: {
  environment: string
  currency?: string
  locale?: string
}) => {
  const userLocale = getUserLocale({ locale })
  const region = getRegion({ currency, locale: userLocale })

  if (isStagingEnvironment()) {
    // c2c uses US instead of NA for staging
    if (region === 'na') {
      return `https://frontend-event-router-us.staging.c2c.klarna.net`
    }
    return `https://frontend-event-router-${region}.staging.c2c.klarna.net`
  } else if (environment === 'playground') {
    return `https://${region}.playground.klarnaevt.com`
  }

  return `https://${region}.klarnaevt.com`
}
