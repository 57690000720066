import { logWarn } from '@klarna-web-sdk/utils'

import { request } from '../apis/request'
import { ErrorTypes, EXPONENTIAL_BACKOFF_INTERVALS } from '../constants'
import { PaymentSDK } from '../paymentSDK'
import { emitUpdate } from './emitUpdate'
import { fetchPaymentRequest } from './fetchPaymentRequest'
import { cancelPollingInterval } from './store'

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))
const MAX_POLLING_ATTEMPTS = 4

export async function onCloseInteractionMode(
  removeListener?: () => unknown,
  attempt: number = 0
): Promise<void> {
  if (attempt > MAX_POLLING_ATTEMPTS - 1) {
    return
  } else {
    await wait(EXPONENTIAL_BACKOFF_INTERVALS[attempt])
  }

  const response = await fetchPaymentRequest()

  // check for aborted flow
  if (response.previousState === 'IN_PROGRESS' && response.state === 'SUBMITTED') {
    if (removeListener) removeListener()
    emitUpdate()
    return
  }

  if (response.state === 'PENDING_CONFIRMATION' || response.state === 'AUTHORIZED') {
    if (removeListener) removeListener()

    if (
      // integrator handling the interaction
      window.klarnaIntegratorApi?.handleInteraction ||
      !response.paymentRequest.config.redirectUrl
    ) {
      emitUpdate()
      return
    }

    if (PaymentSDK.updateCallback) {
      try {
        const callbackResponse = await PaymentSDK.updateCallback?.(request())
        if (typeof callbackResponse === 'boolean' && callbackResponse === false) return
      } catch (error) {
        // continue with redirect if callback fails - inform integrator about the error
        logWarn(`${ErrorTypes.TECHNICAL_ERROR} - 'Update callback failed'`)
      }
    } else {
      emitUpdate()
    }

    const constructedRedirectUrl = response.paymentRequest.config.redirectUrl
      .replace('{klarna.payment_request.id}', response.paymentRequestId)
      .replace(
        '{klarna.payment_request.payment_confirmation_token}',
        response.stateContext.paymentConfirmationToken
      )
    return window.location.assign(constructedRedirectUrl)
  } else {
    emitUpdate()
  }

  onCloseInteractionMode(removeListener, attempt + 1)
}

export function initPerformActionsOnFocus() {
  const controller = new AbortController()
  const removeEventListener = () => controller.abort()
  window.addEventListener(
    'focus',
    () => {
      // Cancel any other ongoing polling for payment request state
      cancelPollingInterval({ type: 'pollingForInProgressState' })
      onCloseInteractionMode(removeEventListener)
    },
    {
      signal: controller.signal,
    }
  )
}
