import frontendEvents, { LOG_LEVEL } from '@klarna/frontend-events'

interface TrackerConfig {
  client: string
  clientVersion: string
  sessionId: string
  logLevel?: LOG_LEVEL
  baseUrl?: string
}

export default function tracker(config: TrackerConfig) {
  if (process.env.NODE_ENV !== 'development') {
    return frontendEvents(config)
  } else {
    return fakeInstance(config)
  }
}

function fakeInstance(config: TrackerConfig) {
  console.info('[Tracking (disabled)] Setting up fake instance with config', config)

  return {
    event: (name: string, options: object, postData = {}, level = LOG_LEVEL.INFO): void => {
      if (config.logLevel && config.logLevel > level) {
        // Skip logging
        return
      }
      console.info('[Tracking (disabled)]', name, options, postData, level, {
        config,
      })
    },
    setLogLevel(logLevel: LOG_LEVEL): void {
      console.info('[Tracking (disabled)] Setting up fake instance logLevel to', logLevel)
      config.logLevel = logLevel
    },
    getConfig: (): TrackerConfig => config,
  }
}
