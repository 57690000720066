import { closeInteractionMode, triggerIframe } from '@klarna/flow-interaction-mode'
import { getFullscreenWindow } from '@klarna-web-sdk/utils/src/getFullscreen'
import { ETranslationKey, getTranslation } from '@klarna-web-sdk/utils/src/i18n'
import {
  tryIntegratorApiFullscreenHide,
  tryIntegratorApiFullscreenShow,
} from '@klarna-web-sdk/utils/src/klarnaIntegratorApi'
import { validateKlarnaOrigin } from '@klarna-web-sdk/utils/src/validateKlarnaOrigin'

import { messagingEvents } from '../../events'

export enum AllowedSources {
  LEARN_MORE = 'LEARN_MORE',
  PREQUALIFICATION = 'PREQUALIFICATION',
}
export enum AllowedActions {
  CLOSE = 'CLOSE',
}

export const onMessage =
  (iframe: HTMLElement, emit: (event: messagingEvents) => void) => (event: MessageEvent) => {
    try {
      if (!validateKlarnaOrigin(event.origin)) {
        throw new Error('Invalid klarna origin')
      }

      const parsedData = JSON.parse(event.data)

      const data: {
        source: AllowedSources
        type?: AllowedActions
        dialogHeight?: string
      } = {
        source: parsedData.source,
        type: parsedData.type,
        dialogHeight: parsedData.dialogHeight,
      }

      if (!(data.source in AllowedSources)) {
        throw new Error('Invalid event source')
      }

      const resizeIframeToFitContent = (iframe: HTMLElement, contentHeight: number): void => {
        const fullscreenWindow = getFullscreenWindow()
        const pageHeight = fullscreenWindow.innerHeight
        iframe.style.height = `${Math.min(contentHeight, pageHeight)}px`
      }

      if (data.source === AllowedSources.LEARN_MORE) {
        const iframeHeight = parseInt(data.dialogHeight)

        if (!Number.isNaN(iframeHeight)) {
          resizeIframeToFitContent(iframe, iframeHeight)
        }

        iframe.focus()
      }

      if (data.source in AllowedSources && data.type === AllowedActions.CLOSE) {
        closeInteractionMode()
        emit(messagingEvents.MODAL_CLOSED)
        emit(messagingEvents.MODAL_CLOSED_LEGACY)
      }
    } catch (error) {}
  }

export function initializeInterstitialHandler(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: { emit: (event: string) => void },
  iframe: HTMLIFrameElement
) {
  const { emit } = params

  const fullscreenWindow = getFullscreenWindow()
  fullscreenWindow.addEventListener('message', onMessage(iframe, emit), false)
}

export function openInterstitial(
  e: PointerEvent,
  params: {
    iframeId: string
    ctaLink: string
    emit?: (event: messagingEvents, detail?: string | object) => void
  }
) {
  const { ctaLink, emit } = params
  e.preventDefault()
  emit(messagingEvents.MODAL_OPENED)
  emit(messagingEvents.MODAL_OPENED_LEGACY)

  const title = `Information about Klarna's payment methods`

  const iframeLink = ctaLink.replace(
    'showButtons=false&showBackground=true',
    'showButtons=true&showBackground=false&websdk=true'
  )

  tryIntegratorApiFullscreenShow()

  return triggerIframe(iframeLink, {
    overlayContent: {
      text: getTranslation(ETranslationKey.OverlayContentText),
      buttonLabel: getTranslation(ETranslationKey.OverlayContentButtonLabel),
    },
    previousActiveElement: e.target as HTMLButtonElement,
    fullscreenWindow: getFullscreenWindow(),
    onClose: tryIntegratorApiFullscreenHide,
    title,
    id: params.iframeId,
    styles: {
      borderRadius: 0,
    },
  })
}
