import { html } from 'lit'
import { unsafeHTML } from 'lit/directives/unsafe-html.js'

import { KlarnaBadge } from '../../assets/logos'
import addKlarnaLogo, { isKlarnaTextAvailableInAd } from '../helpers/addKlarnaLogo/addKlarnaLogo'
import { LogoType } from '../types'
import styles from './style.scss'

interface HeaderProps {
  theme?: string
  mainText?: string
  ctaLabel?: string
  legalText?: string
  logoType?: Omit<LogoType, 'null'>
  onLearnMoreClick: () => null
}

export default function header({
  theme = 'default',
  mainText = '',
  ctaLabel = '',
  legalText,
  logoType,
  onLearnMoreClick,
}: HeaderProps) {
  function addKlarnaIfNotAvailable(adText: string) {
    if (!isKlarnaTextAvailableInAd(adText)) adText += ' Klarna'
    return adText
  }

  function renderBadge() {
    if (logoType === 'badge') {
      return html`<div class="badge" part="osm-badge-container">
        ${unsafeHTML(KlarnaBadge({ width: 60, height: 30 }))}
      </div>`
    }
    return null
  }

  function renderMainText() {
    if (logoType === 'badge') {
      return html` <p part="osm-message">
        ${mainText}
        <button class="link" part="osm-cta" @click=${onLearnMoreClick}>${ctaLabel}</button>
      </p>`
    }

    return html`<p part="osm-message">
      ${addKlarnaLogo(addKlarnaIfNotAvailable(mainText))}
      <button class="link" part="osm-cta" @click=${onLearnMoreClick}>${ctaLabel}</button>
    </p>`
  }

  return html`<style>
      ${styles}
    </style>
    <div data-testid="${mainText ? 'header' : ''}" class="container ${theme}" part="osm-container">
      ${renderBadge()}
      <div part="osm-text-container">
        ${renderMainText()}
        ${legalText ? html`<p part="osm-legal" class="legal">${legalText}</p>` : ''}
      </div>
    </div>`
}
