import type { LitTranslation } from './types'

export const replacePlaceholders = (
  translationString: string,
  params: LitTranslation[]
): LitTranslation[] => {
  let placeholderIndex = 0
  // replace all placeholder values with pattern {{value}} || {{value value}} || {{value.value}}
  const placeholderPattern = /{{?([a-zA-Z]+ )?[a-z]+.[a-z]*}}/
  const value = translationString.split(placeholderPattern).map((item) => {
    if (item === undefined) {
      // Replace pattern with value or display "" if no value is provided
      const newValue = params?.[placeholderIndex]
      placeholderIndex++

      return newValue
    }
    return item
  })

  return value
}
