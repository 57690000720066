export default function createInterstitialToken(locale: string, bespokeId: string) {
  const [language, country] = locale.split('-')

  return window.btoa(
    JSON.stringify({
      language,
      country,
      bespokeId,
    })
  )
}
