/* eslint-disable security-node/non-literal-reg-expr */
// Regex gotchas and solutions
// https://nathanfriend.io/2012/12/13/beware-the-regex-pointer.html
// https://dev.to/dvddpl/why-is-my-regex-working-intermittently-4f4g
import { unsafeHTML } from 'lit/directives/unsafe-html.js'

const KLARNA_REGEX_EXACT = /\b(klarna)\b\.?\s?/gi // "klarna" "klarna." "Klarna." "Klarna" "Klarna "
const KLARNA_REGEX_WITH_EXTRA_CHARS = /\bklarna\w+[.]?/gi // klarnalla Klarnaalla Klarnaalla. klarnaalla.
const klarnaLogoNode = '\n<span class="logo" part="osm-logo">$1.</span> ' // "$1" denotes the first captured group

export function isKlarnaTextAvailableInAd(adText: string): boolean {
  return (
    new RegExp(KLARNA_REGEX_EXACT).test(adText) ||
    new RegExp(KLARNA_REGEX_WITH_EXTRA_CHARS).test(adText)
  )
}

export function replaceLogoTextWithHTMLNode(adText = '') {
  return adText.replace(KLARNA_REGEX_EXACT, klarnaLogoNode).trim()
}

export default function addKlarnaLogo(adText: string) {
  // Logic to wrap Klarna with HTML <span> node to render as Klarna logo.
  return unsafeHTML(replaceLogoTextWithHTMLNode(adText))
}
