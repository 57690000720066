export const loadingScreen = `
<!DOCTYPE html>
<html>
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" />
    <meta name="theme-color" content="rgb(254, 254, 254)" />
    <meta name="theme-color" content="rgb(0, 0, 0)" media="(prefers-color-scheme: dark)" />
    <title>Klarna</title>
    <link
      rel="shortcut icon"
      href="https://x.klarnacdn.net/ui/favicon/v1/favicon.ico"
      type="image/x-icon"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="https://x.klarnacdn.net/ui/favicon/v1/favicon-32x32.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="https://x.klarnacdn.net/ui/favicon/v1/favicon-16x16.png"
    />
  </head>

  <style>
    .logo-container {
      width: 100%;
      height: 100%;
      background-color: var(--background);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 1;
      display: flex;
    }

    #klarna-logo {
      display: flex;
      margin-bottom: 24px;
    }
  </style>

  <body>
    <div class="logo-container">
      <div id="klarna-logo">
        <svg
          width="131"
          height="54"
          viewBox="0 0 131 54"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.5 15.8677C0.5 7.36407 7.33232 0.46875 15.7596 0.46875H115.24C123.668 0.46875 130.5 7.36407 130.5 15.8677V38.1322C130.5 46.6358 123.668 53.53 115.24 53.53H15.7596C7.33232 53.53 0.5 46.6358 0.5 38.1322V15.8677Z"
            fill="#FFB3C7"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M100.387 22.7873C102.07 22.7873 103.632 23.3069 104.924 24.1902V23.2102H109.502V38.5148H104.924V37.5371C103.632 38.4193 102.07 38.9377 100.387 38.9377C95.9319 38.9377 92.3204 35.3236 92.3204 30.8637C92.3204 26.4037 95.9319 22.7873 100.387 22.7873ZM52.0983 22.7873C53.7814 22.7873 55.3419 23.3069 56.6355 24.1902V23.2102H61.2124V38.5148H56.6355V37.5371C55.3419 38.4193 53.7814 38.9377 52.0983 38.9377C47.643 38.9377 44.0303 35.3236 44.0303 30.8637C44.0303 26.4037 47.643 22.7873 52.0983 22.7873ZM114.362 33.0863C115.949 33.0863 117.234 34.3743 117.234 35.9625C117.234 37.5508 115.949 38.8377 114.362 38.8377C112.776 38.8377 111.489 37.5508 111.489 35.9625C111.489 34.3743 112.776 33.0863 114.362 33.0863ZM34.0622 16.3867C34.0622 21.1786 32.1963 25.6363 28.8721 28.9492L35.8839 38.5216H29.6194L21.9977 28.1193L23.9647 26.6447C27.2265 24.1982 29.0981 20.459 29.0981 16.3867H34.0622ZM21.3912 16.3867V38.5205H16.418V16.3867H21.3912ZM42.1541 16.3901V38.5148H37.3637V16.3901H42.1541ZM84.1865 22.7987C87.8741 22.7987 90.7031 25.2214 90.7031 28.7832V38.5148H86.1308V30.4407C86.1308 28.2091 84.9747 27.0051 82.9633 27.0051C81.086 27.0051 79.5267 28.1454 79.5267 30.4726V38.5148H74.9135V23.2114H79.471V24.9349C80.6283 23.3671 82.358 22.7987 84.1865 22.7987ZM68.2935 23.2102V25.2043C69.2111 24.0083 70.9203 23.2114 72.7795 23.2114V27.6645C72.7613 27.6645 72.7431 27.6622 72.725 27.6622C70.9135 27.6622 68.3037 28.9594 68.3037 31.3696V38.5148H63.6076V23.2102H68.2935ZM100.762 26.9255C98.4714 26.9255 96.6145 28.6888 96.6145 30.8637C96.6145 33.0374 98.4714 34.8007 100.762 34.8007C103.053 34.8007 104.91 33.0374 104.91 30.8637C104.91 28.6888 103.053 26.9255 100.762 26.9255ZM52.472 26.9255C50.1824 26.9255 48.3255 28.6888 48.3255 30.8637C48.3255 33.0374 50.1824 34.8007 52.472 34.8007C54.7627 34.8007 56.6196 33.0374 56.6196 30.8637C56.6196 28.6888 54.7627 26.9255 52.472 26.9255Z"
            fill="black"
          />
      </svg>
    </div>
  </body>
</html>
`
