import type {
  PaymentRequest as RequestInteface,
  PaymentRequestData as PaymentRequestDataType,
  PaymentRequestOptions as PaymentRequestOptionsType,
} from '../types'
import {
  store,
  StoreData,
  storeUpdatePaymentRequest,
  storeUpdatePaymentRequestOptions,
} from '../utils/store'
import { cancel } from './cancel'
import { fetch } from './fetch'
import { initiate } from './initiate'
import { prepare } from './prepare'
import { submit } from './submit'
import { update } from './update'

export const request = (
  paymentRequest?: Partial<PaymentRequestDataType> | string,
  options?: Partial<PaymentRequestOptionsType>
): RequestInteface => {
  if (paymentRequest) {
    if (typeof paymentRequest === 'string') {
      store.resetPaymentRequest()
      store.set('paymentRequestId', paymentRequest)

      // TODO: removing temporarily - re-enable once abort flows are implemented
      // paymentStorage.set(EPaymentStorageKeys.PaymentRequestId, paymentRequest)
    } else {
      storeUpdatePaymentRequest(paymentRequest)
    }
  }
  if (options) storeUpdatePaymentRequestOptions(options)

  return {
    paymentRequestId: StoreData.paymentRequestId,
    paymentRequest: StoreData.paymentRequest,
    result: StoreData.paymentRequestResult,
    state: StoreData.paymentRequestState,
    stateContext: StoreData.paymentRequestStateContext,
    previousState: StoreData.paymentRequestPreviousState,

    cancel,
    fetch,
    initiate,
    prepare,
    submit,
    update,
  }
}
