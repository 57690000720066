import type { FallbackLocale, Region } from '../types.d.ts'

export type LocaleMapping = {
  [key in Lowercase<Region>]: FallbackLocale
}

export const RegionalLocaleMapping: LocaleMapping = {
  eu: 'en-GB',
  na: 'en-US',
  oc: 'en-AU',
}
