import { klarnaElements, klarnaPlacements } from '@klarna-web-sdk/utils/src/constants'
import { html } from 'lit'

import { Klarna, Maestro, Mastercard, Visa, VisaElectron } from '../../assets/logos'
import styles from './style.scss'

const element = `${klarnaElements.PLACEMENT}-${klarnaPlacements.FOOTER_PROMOTION_AUTO_SIZE}`

export default function footerPromotion() {
  return html`
    <style>
      ${styles}
    </style>
    <div id="${element}">
      <div class="container" part="osm-container">
        <ul class="footer-badges">
          <li class="badge klarna" part="osm-logo">${Klarna}</li>
          <li class="badge mastercard" part="osm-logo">${Mastercard}</li>
          <li class="badge maestro" part="osm-logo">${Maestro}</li>
          <li class="badge visa" part="osm-logo">${Visa}</li>
          <li class="badge visaelectron" part="osm-logo">${VisaElectron}</li>
        </ul>
      </div>
    </div>
  `
}
