import { ButtonLabel } from '@klarna-web-sdk/utils/src/constants'

export enum EffectiveUxModes {
  IFRAME = 'iframe',
  WINDOW = 'window',
  REDIRECT = 'redirect',
  SDK = 'sdk',
}

export enum ErrorTypes {
  TECHNICAL_ERROR = 'TECHNICAL_ERROR',
  ACCESS_ERROR = 'ACCESS_ERROR',
  RESOURCE_ERROR = 'RESOURCE_ERROR',
  INPUT_ERROR = 'INPUT_ERROR',
}

export enum ErrorCodes {
  UNAUTHORIZED = 'UNAUTHORIZED',
  RATE_LIMITED = 'RATE_LIMITED',
  NOT_FOUND = 'NOT_FOUND',
  RESOURCE_NOT_FOUND = 'RESOURCE_NOT_FOUND',
  RESOURCE_CONFLICT = 'RESOURCE_CONFLICT',
  OPERATION_FORBIDDEN = 'OPERATION_FORBIDDEN',
  RATE_LIMITED_CLIENT = 'RATE_LIMITED_CLIENT',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  INVALID_CONTENT_TYPE = 'INVALID_CONTENT_TYPE',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  TEMPORARY_UNAVAILABLE = 'TEMPORARY_UNAVAILABLE',
}

export enum EErrorCodes {
  KLARNA_NOT_AVAILABLE = 'KLARNA_NOT_AVAILABLE',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
}

export enum ShippingType {
  TO_DOOR = 'TO_DOOR',
  TO_CURB = 'TO_CURB',
  TO_MAILBOX = 'TO_MAILBOX',
  PICKUP_BOX = 'PICKUP_BOX',
  PICKUP_POINT = 'PICKUP_POINT',
  PICKUP_STORE = 'PICKUP_STORE',
  PICKUP_WAREHOUSE = 'PICKUP_WAREHOUSE',
  DIGITAL_EMAIL = 'DIGITAL_EMAIL',
  DIGITAL_DOWNLOAD = 'DIGITAL_DOWNLOAD',
  DIGITAL_OTHER = 'DIGITAL_OTHER',
  PHYSICAL_OTHER = 'PHYSICAL_OTHER',
}

export enum ShippingTypeAttributes {
  SIGNATURE_REQUIRED = 'SIGNATURE_REQUIRED',
  IDENTIFICATION_REQUIRED = 'IDENTIFICATION_REQUIRED',
  CONTACTLESS_DELIVERY = 'CONTACTLESS_DELIVERY',
  LEAVE_AT_DOOR = 'LEAVE_AT_DOOR',
  LEAVE_AT_CURB = 'LEAVE_AT_CURB',
  LEAVE_WITH_NEIGHBOUR = 'LEAVE_WITH_NEIGHBOUR',
  EXPRESS = 'EXPRESS',
  TRACKED = 'TRACKED',
  UNTRACKED = 'UNTRACKED',
}

export enum ShippingRejectionReason {
  POSTAL_CODE_NOT_SUPPORTED = 'POSTAL_CODE_NOT_SUPPORTED',
  CITY_NOT_SUPPORTED = 'CITY_NOT_SUPPORTED',
  REGION_NOT_SUPPORTED = 'REGION_NOT_SUPPORTED',
  COUNTRY_NOT_SUPPORTED = 'COUNTRY_NOT_SUPPORTED',
  ADDRESS_NOT_SUPPORTED = 'ADDRESS_NOT_SUPPORTED',
}

export enum ShippingOptionRejectionReason {
  INVALID_OPTION = 'INVALID_OPTION',
}

/**
 * @hidden
 */
export enum TrackingEvents {
  ABORT_PAYMENT_REQUEST_CALLED = 'abort_payment_request_called',
  ABORT_PAYMENT_REQUEST_COMPLETED = 'abort_payment_request_completed',
  INITIATE_CALLED = 'initiate_called',
  INITIATE_INTEGRATOR_HANDLED_INTERACTION_TRIGGERED = 'initiate_integrator_handled_interaction_triggered',
  INITIATE_INTEGRATOR_CLOSED_INTERACTION = 'initiate_integrator_closed_interaction',
  INITIATE_INTERACTION_MODE_TRIGGERED = 'inititate_interaction_mode_triggered',
  INITIATE_COMPLETED = 'initiate_completed',
  FETCH_CALLED = 'fetch_called',
  FETCH_COMPLETED = 'fetch_completed',
  CAN_MAKE_PAYMENT_CALLED = 'can_make_payment_called',
  CAN_MAKE_PAYMENT_COMPLETED = 'can_make_payment_completed',
  CANCEL_CALLED = 'cancel_called',
  CANCEL_COMPLETED = 'cancel_completed',
  PREPARE_CALLED = 'prepare_called',
  PREPARE_COMPLETED = 'prepare_completed',
  REQUEST_CALLED = 'request_called',
  REQUEST_COMPLETED = 'request_completed',
  SUBMIT_CALLED = 'submit_called',
  SUBMIT_COMPLETED = 'submit_completed',
  UPDATE_CALLED = 'update_called',
  UPDATE_COMPLETED = 'update_completed',
  MAKE_PAYMENT_REQUEST_CALLED = 'make_payment_request_called',
  MAKE_PAYMENT_REQUEST_COMPLETED = 'make_payment_request_completed',
  UPDATE_PAYMENT_REQUEST_CALLED = 'update_payment_request_called',
  UPDATE_PAYMENT_REQUEST_COMPLETED = 'update_payment_request_completed',
  FLOW_END_GENERIC_ERROR_RENDERED = 'flow_end_generic_error_rendered',
  FLOW_END_FALLBACK_CLOSED_UI_RENDERED = 'flow_end_fallback_closed_ui_rendered',
  FLOW_END_APP_RENDERED = 'flow_end_app_rendered',
}

/**
 * {@link PaymentRequestState}
 *
 * Represents the lifecycle of a payment request
 *
 * State transition, note that the `CREATED,` state is client side only and not visible through webhook notifications.
 *
 * ```
 * CREATED              -> [SUBMITTED]
 * SUBMITTED            -> [IN_PROGRESS, PREPARED, SUBMITTED]
 * PREPARED             -> [IN_PROGRESS, PENDING_CONFIRMATION, SUBMITTED]
 * IN_PROGRESS          -> [SUBMITTED, PENDING_CONFIRMATION, AUTHORIZED]
 * PENDING_CONFIRMATION -> [IN_PROGRESS, AUTHORIZED]
 * AUTHORIZED
 * EXPIRED
 * CANCELED
 * ```
 */
export enum PaymentRequestState {
  CREATED = 'CREATED',
  SUBMITTED = 'SUBMITTED',
  IN_PROGRESS = 'IN_PROGRESS',
  PREPARED = 'PREPARED',
  PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
  AUTHORIZED = 'AUTHORIZED',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
}

/**
 * Can be used to set the `label` of a Payment Button by using the label attribute in the button configuration.
 */
export enum PaymentButtonLabel {
  CONTINUE = ButtonLabel.CONTINUE,
  PAY = ButtonLabel.PAY,
}

/**
 * State "undefined" is the default state of a button, making it interactive State "disabled" disables the button, e.g. makes it non-interactive State "loading" state displays a loading indicator in the button
 */
export enum PaymentButtonState {
  DISABLED = 'disabled',
  LOADING = 'loading',
}

/**
 * @hidden
 */
export enum PaymentEvents {
  UPDATE = 'update',
  SHIPPINGADDRESSCHANGE = 'shippingaddresschange',
  SHIPPINGOPTIONSELECT = 'shippingoptionselect',
}

/**
 * @hidden
 */
export const EXPONENTIAL_BACKOFF_INTERVALS = [200, 400, 800, 1600, 3200, 6400]

export enum Currency {
  AUD = 'AUD',
  EUR = 'EUR',
  CAD = 'CAD',
  CZK = 'CZK',
  DKK = 'DKK',
  HUF = 'HUF',
  MXN = 'MXN',
  NZD = 'NZD',
  NOK = 'NOK',
  PLN = 'PLN',
  RON = 'RON',
  SEK = 'SEK',
  CHF = 'CHF',
  GBP = 'GBP',
  USD = 'USD',
  JPY = 'JPY',
}

/**
 * County information.
 */
export enum Country {
  AT = 'AT',
  AU = 'AU',
  BE = 'BE',
  CA = 'CA',
  CH = 'CH',
  CZ = 'CZ',
  DE = 'DE',
  DK = 'DK',
  ES = 'ES',
  FI = 'FI',
  FR = 'FR',
  GB = 'GB',
  GR = 'GR',
  HU = 'HU',
  IE = 'IE',
  IT = 'IT',
  MX = 'MX',
  NL = 'NL',
  NO = 'NO',
  NZ = 'NZ',
  PL = 'PL',
  PT = 'PT',
  RO = 'RO',
  SE = 'SE',
  US = 'US',
  JP = 'JP',
}
