import { InteractionModes } from '@klarna/flow-interaction-mode'
import { PaymentError } from '@klarna-web-sdk/payment/src/utils/paymentError'
import { zodAlwaysRefine } from '@klarna-web-sdk/utils/src/zod/alwaysRefine'
import { ZodIssueCode } from 'zod'

import { EffectiveUxModes, ErrorCodes, ErrorTypes, TrackingEvents } from '../constants'
import { PaymentRequestData, PaymentRequestOptions } from '../schema'
import {
  PaymentRequestData as PaymentRequestDataType,
  PaymentRequestOptions as PaymentRequestOptionsType,
} from '../types'
import { emitUpdate } from '../utils/emitUpdate'
import { makePaymentRequest } from '../utils/makePaymentRequest'
import { tracker } from '../utils/paymentTracker'
import { store, storeUpdatePaymentRequest, storeUpdatePaymentRequestOptions } from '../utils/store'

export async function submit(
  paymentRequest?: Partial<PaymentRequestDataType>,
  options?: PaymentRequestOptionsType
) {
  const paymentRequestId = store.get('paymentRequestId')
  tracker().event(TrackingEvents.SUBMIT_CALLED, { ...options, paymentRequestId })

  if (paymentRequestId) {
    throw new PaymentError(
      ErrorTypes.RESOURCE_ERROR,
      ErrorCodes.RESOURCE_CONFLICT,
      'There is an ongoing payment request, please use the `Klarna.Payment.request().update()` method instead.'
    )
  }

  /* Use data from store if not provided from parameters */
  const _paymentRequest = {
    ...(store.get('paymentRequest') as PaymentRequestDataType),
    ...(paymentRequest && { ...paymentRequest }),
  }
  const _options = {
    ...store.get('paymentRequestOptions'),
    ...(options && { ...options }),
  }

  const errors = []
  const paymentRequestValidation = await zodAlwaysRefine(PaymentRequestData)
    .superRefine((data, ctx) => {
      if (
        _options?.interactionMode !== InteractionModes.ON_PAGE &&
        data.config?.redirectUrl === undefined
      ) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message:
            'config.redirectUrl in payment request data is required when interactionMode is not ON_PAGE',
          path: ['config', 'redirectUrl'],
        })
      }
    })
    .safeParseAsync(_paymentRequest)

  if (!paymentRequestValidation.success) {
    errors.push(paymentRequestValidation.error)
  }

  const optionsValidation = PaymentRequestOptions.safeParse(_options)
  if (!optionsValidation.success) {
    errors.push(optionsValidation.error)
  }

  if (errors.length > 0) {
    throw new PaymentError(
      ErrorTypes.INPUT_ERROR,
      ErrorCodes.VALIDATION_ERROR,
      'Invalid PaymentRequestData or PaymentRequestOptions',
      errors
    )
  }

  storeUpdatePaymentRequest(_paymentRequest)
  storeUpdatePaymentRequestOptions(_options)

  try {
    const response = await makePaymentRequest(
      _paymentRequest as PaymentRequestDataType,
      EffectiveUxModes.REDIRECT
    )

    emitUpdate()
    tracker().event(TrackingEvents.SUBMIT_COMPLETED, {
      paymentRequestId: response.paymentRequestId,
    })
  } catch (error) {
    throw new PaymentError(
      ErrorTypes.TECHNICAL_ERROR,
      ErrorCodes.INTERNAL_ERROR,
      'Submission failed',
      error
    )
  }
}
