/* eslint-disable @typescript-eslint/no-explicit-any */

import { detectBrowserName } from '../detectBrowser'
import { EVENTS_SAMPLING_RATE, getSampleGroup } from '../getSampleGroup'
import { isProductionBuild } from '../isProductionBuild'
import { TrackerClients } from '../tracker/trackerClients'
import trackerFactory from '../tracker/trackerFactory'

const CONSOLE_MESSAGE_PREFIX = 'Klarna Web SDK:'

const logStyle = 'color: black; background-color: #FFB3C7; padding: 2px;'

const browserName = detectBrowserName()

export function logError(message: string | Error) {
  console.error(`%c${CONSOLE_MESSAGE_PREFIX} ${message}`, logStyle)

  if (process.env.NODE_ENV !== 'development') {
    const tracker = trackerFactory(TrackerClients.websdk)
    tracker.event('metric_merchant_error', { message, browserName })
  }
}

export function logWarn(message: string) {
  console.warn(`%c${CONSOLE_MESSAGE_PREFIX} ${message}`, logStyle)

  if (
    process.env.NODE_ENV !== 'development' &&
    getSampleGroup(EVENTS_SAMPLING_RATE.MERCHANT_WARN)
  ) {
    const tracker = trackerFactory(TrackerClients.websdk)
    tracker.event('metric_merchant_warn', { message })
  }
}

export function logInfo(message: string) {
  console.info(`%c${CONSOLE_MESSAGE_PREFIX} ${message}`, logStyle)

  if (process.env.NODE_ENV !== 'development') {
    const tracker = trackerFactory(TrackerClients.websdk)
    tracker.event('merchant_info', { message })
  }
}

export const devLogger = {
  info: (message?: string) => !isProductionBuild() && logInfo(message),
  warn: (message?: string) => !isProductionBuild() && logWarn(message),
  error: (message?: string) => !isProductionBuild() && logError(message),
}
