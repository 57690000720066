import { EventBus, klarnaElements, logError } from '@klarna-web-sdk/utils'

import { ERROR_TITLES, errorHandler } from '../errors'
import { IdentityButtonConfiguration } from '../klarnaIdentityButton/klarnaIdentityButton'
import { AuthFlow } from './AuthFlow'
import { AuthorizationServerRegistry } from './AuthorizationServerRegistry'
import { IdentitySDKConfig } from './IdentitySDK'
import { IdentityTracker, TrackingEvents } from './IdentityTracker'

// todo: improve type definitions by coupling eventname with its data
type ButtonEvents = 'ready' | 'click' | 'tokenizationTrigger'
type WithTokenizationCallbackFunction = (tokenizationId: string) => void
type ButtonData = undefined | Error | WithTokenizationCallbackFunction

// todo:discuss why EventBus is an abstract class?
class IdentityButtonEvents extends EventBus<ButtonEvents, ButtonData> {
  public constructor() {
    super()
  }
}

type IdentityButtonState = 'created' | 'mounted' | 'attached'

type IdentityButtonConfigurationWithId = IdentityButtonConfiguration & {
  id: string
}

// TODO: send payments by default, delete create_payment_session
const DEFAULT_SCOPES = ['openid', 'offline_access', 'customer:login']
const addDefaultScopesToConfig = (config: IdentityButtonConfigurationWithId) => {
  const requestedScope = config.scope || ''
  const scope = [...new Set([...DEFAULT_SCOPES, ...requestedScope.trim().split(' ')])].join(' ')

  return {
    ...config,
    scope,
  }
}

const stringifyElement = (elementOrId?: null | string | HTMLElement) => {
  if (elementOrId && typeof elementOrId === 'object') return elementOrId.outerHTML

  return elementOrId
}

// see: https://www.stefanjudis.com/snippets/how-to-remove-all-event-listeners-from-a-dom-element/
// see: https://developer.mozilla.org/en-US/docs/Web/API/Element/replaceWith#browser_compatibility
const removeAllEventListeners = (element: HTMLElement) => {
  const clonedElement = element.cloneNode(true)
  element.replaceWith(clonedElement)
  return clonedElement as HTMLElement
}

export class IdentityButton {
  public config: IdentityButtonConfigurationWithId
  public buttonEvents: IdentityButtonEvents
  private sdkConfig: IdentitySDKConfig
  private buttonElement?: HTMLElement
  private state: IdentityButtonState
  private authFlow: AuthFlow

  constructor(config: IdentityButtonConfigurationWithId, sdkConfig: IdentitySDKConfig) {
    // todo: verify config
    this.config = addDefaultScopesToConfig(config)
    this.sdkConfig = sdkConfig
    this.buttonEvents = new IdentityButtonEvents()
    this.state = 'created'
  }

  mount(containerIdOrElement: string | HTMLElement) {
    const container =
      typeof containerIdOrElement === 'string'
        ? document.querySelector<HTMLElement>(containerIdOrElement)
        : containerIdOrElement

    if (!container || typeof container !== 'object' || !document.body.contains(container)) {
      const message =
        `mount failed: container not found: ` + stringifyElement(container || containerIdOrElement)
      logError(message)
      throw new Error(message)
    }

    const buttonSelector =
      this.sdkConfig?.clientInstanceName && this.sdkConfig.clientInstanceName !== 'default'
        ? `${klarnaElements.IDENTITY_BUTTON}-${this.sdkConfig.clientInstanceName}`
        : klarnaElements.IDENTITY_BUTTON

    if (container.querySelector(buttonSelector)) {
      const message = `mount failed: container already contains <${buttonSelector} /> element!`
      logError(message)
      throw new Error(message)
    }

    if (this.state === 'attached') {
      const errorMessage = `mount failed: this instance was previously attached to a custom element: ${stringifyElement(this.buttonElement)}`
      logError(errorMessage)
      throw new Error(errorMessage)
    }

    const existingButton = document.querySelector(`#${this.config.id}`)
    if (this.state === 'mounted' && existingButton) {
      const errorMessage = `mount failed: this button was already mounted!`
      logError(errorMessage)
      throw new Error(errorMessage)
    }

    const buttonElement = document.createElement(buttonSelector)
    // todo: discuss if this span is necessary?
    const emptySpan = document.createElement('span')
    buttonElement.appendChild(emptySpan)

    const { id, ...args } = this.config
    Object.assign(buttonElement.dataset, args)
    buttonElement.setAttribute('id', id)
    buttonElement.setAttribute('data-testid', id)

    container.appendChild(buttonElement)
    this.state = 'mounted'

    this.buttonElement = buttonElement
  }

  unmount() {
    if (!this.buttonElement || !document.body.contains(this.buttonElement)) {
      logError('Unmount failed: Button element not found!')
      return
    }

    if (this.state !== 'mounted') {
      logError('Unmount failed: Button is not mounted!')
      return
    }
    this.state = 'created'
    this.buttonElement.remove()
  }

  attach(buttonIdOrElement: string | HTMLElement) {
    const customButton =
      typeof buttonIdOrElement === 'string'
        ? document.querySelector<HTMLElement>(buttonIdOrElement)
        : buttonIdOrElement

    if (
      !customButton ||
      typeof customButton !== 'object' ||
      !document.body.contains(customButton)
    ) {
      const message =
        `attach failed: button element not found: ` +
        stringifyElement(customButton || buttonIdOrElement)
      logError(message)
      throw new Error(message)
    }

    if (this.state === 'mounted') {
      const errorMessage = `attach failed: this instance was previously mounted: ${stringifyElement(this.buttonElement)}`
      logError(errorMessage)
      throw new Error(errorMessage)
    }

    if (this.state === 'attached' && this.buttonElement === customButton) {
      const errorMessage = `attach failed: this instance was already attached to the same element: ${stringifyElement(this.buttonElement)}`
      logError(errorMessage)
      throw new Error(errorMessage)
    }

    /**
     * Cloning the button and replacing it with itself will remove all event listeners.
     * We want to overwrite existing event handlers to prevent potential
     * race condition issues and prevent attaching multiple click handlers.
     *
     * Merchants can use emitted "clicked" event to register their own click handlers,
     * or they can add a click event handler to a parent element.
     */
    this.buttonElement = removeAllEventListeners(customButton)
    this.buttonElement.onclick = () => this.clickHandler()

    this.state = 'attached'
    this.emitReadyEvent()
  }

  detach() {
    if (!this.buttonElement || !document.body.contains(this.buttonElement)) {
      logError('detach failed: Button element not found!')
      return
    }

    if (this.state !== 'attached') {
      logError('detach failed: Button is not attached!')
      return
    }
    this.state = 'created'
    this.buttonElement.onclick = null
  }

  on(eventName: ButtonEvents, callback: (data: ButtonData) => void) {
    this.buttonEvents.on(eventName, callback)
  }

  async click() {
    await this.clickHandler()
  }

  async initAuthorizationServer() {
    return AuthorizationServerRegistry.getInstance()
      .then((authServer) => {
        if (!authServer) {
          throw new Error(ERROR_TITLES.CouldNotCreateAuthServer)
        }
      })
      .catch((err) => {
        const errorTitle = ERROR_TITLES.CouldNotCreateAuthServer
        errorHandler(err, {
          errorTitle,
        })
        return new Error(errorTitle)
      })
  }

  // todo: discuss if we need this
  getPublicAPI() {
    // todo
    return {
      mount: this.mount.bind(this) as typeof this.mount,
      unmount: this.unmount.bind(this) as typeof this.unmount,
      attach: this.attach.bind(this) as typeof this.attach,
      detach: this.detach.bind(this) as typeof this.detach,
      on: this.on.bind(this) as typeof this.on,
      id: this.config.id as string,
    }
  }

  emitReadyEvent = async () => {
    this.initAuthorizationServer().then((err) => {
      if (err) {
        return
      }
      this.buttonEvents.emit('ready')

      IdentityTracker.sendEvent({
        name: TrackingEvents.ButtonReady,
        options: {
          state: this.state,
        },
      })
    })
  }

  private isTokenizationFlow = () =>
    this.config.scope.split(' ').some((scope) => ['payment:customer_present'].includes(scope))

  private tokenizationCallback = (tokenizationId: string) => {
    this.authFlow.triggerAuthFlow(tokenizationId)
  }

  private clickHandler = async () => {
    IdentityTracker.sendEvent({
      name: TrackingEvents.ButtonClicked,
    })

    this.buttonEvents.emit('click')
    this.authFlow = new AuthFlow(this.config, this.sdkConfig)

    const tokenizationFlow = this.isTokenizationFlow()

    if (tokenizationFlow) {
      this.buttonEvents.emit('tokenizationTrigger', this.tokenizationCallback)
    }

    this.authFlow
      .start({
        tokenizationFlow,
      })
      .then(() => {
        // console.log('Login successful')
      })
      .catch(() => {
        //  console.log('Login failed', error)
      })
  }
}

export type IdentityButtonPublicAPI = ReturnType<typeof IdentityButton.prototype.getPublicAPI>
