import { detectDeviceBest, InteractionModes, triggerRedirect } from '@klarna/flow-interaction-mode'
import { PaymentError } from '@klarna-web-sdk/payment/src/utils/paymentError'
import { API_BASE_URLS } from '@klarna-web-sdk/utils'
import { getIntegratorApi } from '@klarna-web-sdk/utils/src/klarnaIntegratorApi'
import { zodAlwaysRefine } from '@klarna-web-sdk/utils/src/zod/alwaysRefine'
import { ZodIssueCode } from 'zod'

import { EffectiveUxModes, ErrorCodes, ErrorTypes, TrackingEvents } from '../constants'
import { PaymentRequestData, PaymentRequestDataAllOptional, PaymentRequestOptions } from '../schema'
import {
  PaymentRequestData as PaymentRequestDataType,
  PaymentRequestOptions as PaymentRequestOptionsType,
} from '../types'
import { emitUpdate } from '../utils/emitUpdate'
import { makePaymentRequest } from '../utils/makePaymentRequest'
import { initPerformActionsOnFocus, onCloseInteractionMode } from '../utils/onCloseInteractionMode'
import { openOnPageInteraction } from '../utils/openOnPageInteraction'
import { tracker } from '../utils/paymentTracker'
import { initPollingForInProgressState } from '../utils/pollForInProgressState'
import { store, storeUpdatePaymentRequest, storeUpdatePaymentRequestOptions } from '../utils/store'
import { updatePaymentRequest } from '../utils/updatePaymentRequest'

export async function initiate(
  paymentRequest?: Partial<PaymentRequestDataType>,
  options?: PaymentRequestOptionsType
) {
  const paymentRequestId = store.get('paymentRequestId')

  tracker().event(TrackingEvents.INITIATE_CALLED, {
    ...options,
    paymentRequestId,
  })

  /* Use data from store if not provided from parameters */
  const _paymentRequest = {
    ...(store.get('paymentRequest') as PaymentRequestDataType),
    ...(paymentRequest && { ...paymentRequest }),
  }
  const _options = {
    ...store.get('paymentRequestOptions'),
    ...(options && { ...options }),
  }

  const paymentRequestState = store.get('paymentRequestState')
  const sdkConfig = store.get('config')
  const flowEndUrl = `${API_BASE_URLS[sdkConfig?.environment || 'playground']}/web-sdk/v1/flow-end/index.html`
  const integratorApi = getIntegratorApi(window)

  const errors = []

  const paymentRequestValidation = await zodAlwaysRefine(
    paymentRequestId ? PaymentRequestDataAllOptional : PaymentRequestData
  )
    .superRefine((data, ctx) => {
      if (
        _options?.interactionMode !== InteractionModes.ON_PAGE &&
        data.config?.redirectUrl === undefined
      ) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message:
            'config.redirectUrl in payment request data is required when interactionMode is not ON_PAGE',
          path: ['config', 'redirectUrl'],
        })
      }
    })
    .safeParseAsync(_paymentRequest)

  if (!paymentRequestValidation.success) {
    errors.push(paymentRequestValidation.error)
  }

  const optionsValidation = PaymentRequestOptions.safeParse(_options)
  if (!optionsValidation.success) {
    errors.push(optionsValidation.error)
  }

  if (errors.length > 0) {
    throw new PaymentError(
      ErrorTypes.INPUT_ERROR,
      ErrorCodes.VALIDATION_ERROR,
      'Invalid PaymentRequestData or PaymentRequestOptions',
      errors
    )
  }

  storeUpdatePaymentRequest(_paymentRequest)
  storeUpdatePaymentRequestOptions(_options)

  const makeOrUpdatePaymentRequest = (
    effectiveUxMode: EffectiveUxModes,
    opfClientVersion?: string
  ) => {
    if (
      paymentRequestState === 'PENDING_CONFIRMATION' ||
      paymentRequestState === 'AUTHORIZED' ||
      !paymentRequestId
    ) {
      return makePaymentRequest(_paymentRequest, effectiveUxMode, opfClientVersion)
    } else {
      return updatePaymentRequest(paymentRequestId, _paymentRequest, effectiveUxMode)
    }
  }

  // interaction handler is provided by integrator
  if (integratorApi?.handleInteraction) {
    const effectiveUxMode =
      _options?.interactionMode === InteractionModes.ON_PAGE
        ? EffectiveUxModes.WINDOW
        : EffectiveUxModes.REDIRECT

    const opfClientVersion = _options?.opfClientVersion

    tracker().event(TrackingEvents.INITIATE_INTEGRATOR_HANDLED_INTERACTION_TRIGGERED, {
      effectiveUxMode,
      paymentRequestId,
    })

    try {
      const response = await makeOrUpdatePaymentRequest(effectiveUxMode, opfClientVersion)

      emitUpdate()
      initPollingForInProgressState()
      integratorApi.handleInteraction(response.stateContext?.distribution.url)

      if (integratorApi.onPaymentFlowClosed) {
        integratorApi.onPaymentFlowClosed(() => {
          tracker().event(TrackingEvents.INITIATE_INTEGRATOR_CLOSED_INTERACTION, {
            paymentRequestId: response.paymentRequestId,
          })
          onCloseInteractionMode()
        })
      }

      tracker().event(TrackingEvents.INITIATE_COMPLETED, {
        paymentRequestId: response.paymentRequestId,
      })
    } catch (error) {
      window.klarnaIntegratorApi.handleInteraction(`${flowEndUrl}?error_code=internalError`)
      throw new PaymentError(
        ErrorTypes.TECHNICAL_ERROR,
        ErrorCodes.INTERNAL_ERROR,
        'Initiate failed',
        error
      )
    }
  }

  const finalInteractionMode =
    !_options?.interactionMode || _options?.interactionMode === InteractionModes.DEVICE_BEST
      ? detectDeviceBest()
      : _options.interactionMode

  tracker().event(TrackingEvents.INITIATE_INTERACTION_MODE_TRIGGERED, {
    interactionMode: finalInteractionMode,
    paymentRequestId,
  })

  if (finalInteractionMode === InteractionModes.ON_PAGE) {
    try {
      const [interactionPromise, apiPromise] = await Promise.allSettled([
        openOnPageInteraction({ onCloseIframe: onCloseInteractionMode }),
        makeOrUpdatePaymentRequest(EffectiveUxModes.WINDOW, _options?.opfClientVersion),
      ])

      if (interactionPromise.status === 'rejected') {
        throw new PaymentError(
          ErrorTypes.TECHNICAL_ERROR,
          ErrorCodes.INTERNAL_ERROR,
          'Failed to open an interaction',
          interactionPromise.reason
        )
      }

      const { updateUrl, effectiveMode } = interactionPromise.value

      if (apiPromise.status === 'rejected') {
        updateUrl(`${flowEndUrl}?error_code=${ErrorTypes.TECHNICAL_ERROR}`)
        throw new PaymentError(
          ErrorTypes.TECHNICAL_ERROR,
          ErrorCodes.INTERNAL_ERROR,
          'API request failed',
          apiPromise.reason
        )
      }

      if (effectiveMode === EffectiveUxModes.WINDOW) {
        initPerformActionsOnFocus()
      } else {
        // unable to open a popup, needs to be reflected in backend as well
        await updatePaymentRequest(apiPromise.value.paymentRequestId, null, effectiveMode)
      }

      updateUrl(apiPromise.value.stateContext?.distribution.url)
      initPollingForInProgressState()
      emitUpdate()
      tracker().event(TrackingEvents.INITIATE_COMPLETED, {
        paymentRequestId: apiPromise.value.paymentRequestId,
      })
    } catch (error) {
      throw new PaymentError(
        ErrorTypes.TECHNICAL_ERROR,
        ErrorCodes.INTERNAL_ERROR,
        'Initiate failed',
        error
      )
    }
  }

  if (finalInteractionMode === InteractionModes.REDIRECT) {
    try {
      const response = await makeOrUpdatePaymentRequest(
        EffectiveUxModes.REDIRECT,
        _options?.opfClientVersion
      )

      emitUpdate()
      tracker().event(TrackingEvents.INITIATE_COMPLETED, {
        paymentRequestId: response.paymentRequestId,
      })
      triggerRedirect(response.stateContext?.distribution.url)
    } catch (error) {
      throw new PaymentError(
        ErrorTypes.TECHNICAL_ERROR,
        ErrorCodes.INTERNAL_ERROR,
        'Initiate failed',
        error
      )
    }
  }
}
