import { EventBus } from '@klarna-web-sdk/utils'

import { PaymentEvents as EPaymentEvents } from '../constants'
import { PaymentEventData } from '../types'

export class PaymentEvents extends EventBus<EPaymentEvents, PaymentEventData> {
  // eslint-disable-next-line no-use-before-define
  private static instance: PaymentEvents

  private constructor() {
    super()
  }

  static getInstance(): PaymentEvents {
    if (!PaymentEvents.instance) {
      PaymentEvents.instance = new PaymentEvents()
    }

    return PaymentEvents.instance
  }
}
