import {
  detectDeviceBest,
  InteractionModes,
  OnPagePopupModeOptions,
  triggerOnPage,
  triggerRedirect,
} from '@klarna/flow-interaction-mode'
import { ETranslationKey, getTranslation } from '@klarna-web-sdk/utils'

import { ERROR_TITLES, errorHandler } from '../errors'
import { IntegrationError } from '../errors/integration-error'
import { IdentityEvents } from '../identityEvents'
import { IdentityButtonConfiguration } from '../klarnaIdentityButton/klarnaIdentityButton'
import { shouldRunPKCEFlow } from '../utils/should-run-pkce'
import { AuthorizationServerRegistry } from './AuthorizationServerRegistry'
import { IdentitySDKConfig } from './IdentitySDK'
import { IdentityTracker, TrackingEvents } from './IdentityTracker'
import { PopupManager } from './PopupManager'

const POPUP_DIMENSIONS = {
  WIDTH: 400,
  HEIGHT: 929, // This value is the calc of the width * 2.16
}

type AuthFlowConfig = Pick<
  IdentityButtonConfiguration,
  'interactionMode' | 'redirectUri' | 'scope' | 'hideOverlay'
>

export class AuthFlow {
  public config: AuthFlowConfig
  private sdkConfig: IdentitySDKConfig
  private popupManager: PopupManager
  private pkceFlow = false
  private identityEvents: IdentityEvents

  constructor(config: AuthFlowConfig, sdkConfig: IdentitySDKConfig) {
    // todo: verify config
    this.config = config
    this.sdkConfig = sdkConfig
    this.pkceFlow = shouldRunPKCEFlow(
      this.sdkConfig.identityRuntimeConfig?.nonPKCEClientIds,
      this.sdkConfig.clientId
    )
    this.identityEvents = IdentityEvents.getInstance()
  }

  async start({ tokenizationFlow }: { tokenizationFlow: boolean }) {
    const interactionMode = this.findBestInteractionMode()

    if (interactionMode === 'REDIRECT') {
      return this.startRedirectFlow()
    }

    return this.startOnPageFlow({ tokenizationFlow })
  }

  async triggerAuthFlow(tokenizationId: string) {
    const authServer = await AuthorizationServerRegistry.getInstance()
    const authUrl = await authServer.constructOIDCAuthorizationUrl({
      clientId: this.sdkConfig.clientId,
      redirectUri: authServer.getOnPageFlowRedirectUri(),
      scope: this.config.scope,
      sessionId: this.sdkConfig.sessionId,
      pkceFlow: this.pkceFlow,
      locale: this.sdkConfig.locale,
      tokenizationId,
    })

    this.popupManager.goto(authUrl)
  }

  private findBestInteractionMode(): 'REDIRECT' | 'ON_PAGE' {
    if (this.config.interactionMode && this.config.interactionMode !== 'DEVICE_BEST') {
      return this.config.interactionMode
    }
    const bestMode = detectDeviceBest()

    if (bestMode === InteractionModes.REDIRECT) {
      return `${InteractionModes.REDIRECT}`
    }

    return `${InteractionModes.ON_PAGE}`
  }

  private async startRedirectFlow() {
    IdentityTracker.sendEvent({
      name: TrackingEvents.RedirectFlowStarted,
    })
    const { redirectUri } = this.config
    if (!redirectUri) {
      throw new IntegrationError('redirectUri is not provided!')
    }

    const authServer = await AuthorizationServerRegistry.getInstance()

    const authUrl = await authServer.constructOIDCAuthorizationUrl({
      clientId: this.sdkConfig.clientId,
      redirectUri,
      scope: this.config.scope,
      sessionId: this.sdkConfig.sessionId,
      pkceFlow: this.pkceFlow,
      locale: this.sdkConfig.locale,
    })

    triggerRedirect(authUrl.toString())
  }

  private async startOnPageFlow({ tokenizationFlow }: { tokenizationFlow: boolean }) {
    IdentityTracker.sendEvent({
      name: TrackingEvents.OnPageFlowStarted,
    })

    const options: OnPagePopupModeOptions = {
      overlayContent: {
        text: getTranslation(ETranslationKey.OverlayContentText),
        buttonLabel: getTranslation(ETranslationKey.OverlayContentButtonLabel),
      },
      styles: {
        width: POPUP_DIMENSIONS.WIDTH,
        height: POPUP_DIMENSIONS.HEIGHT,
      },
      hideOverlay: this.config.hideOverlay,
      onPopupClose: () => {
        this.identityEvents.emit('popupclose')
      },
    }
    try {
      const { getOpenedWindow } = triggerOnPage('', options)

      const authServer = await AuthorizationServerRegistry.getInstance()
      const popup = getOpenedWindow()
      this.popupManager = new PopupManager({
        popup,
        authServer,
        sdkConfig: this.sdkConfig,
        pkceFlow: this.pkceFlow,
      })

      if (!tokenizationFlow) {
        const authUrl = await authServer.constructOIDCAuthorizationUrl({
          clientId: this.sdkConfig.clientId,
          redirectUri: authServer.getOnPageFlowRedirectUri(),
          scope: this.config.scope,
          sessionId: this.sdkConfig.sessionId,
          pkceFlow: this.pkceFlow,
          locale: this.sdkConfig.locale,
        })

        this.popupManager.goto(authUrl)
      }
    } catch (error) {
      try {
        await this.startRedirectFlow()
      } catch (error) {
        errorHandler(error, {
          errorTitle: ERROR_TITLES.StartRedirectFailed,
        })
      }
    }
  }
}
