import { PaymentError } from '@klarna-web-sdk/payment/src/utils/paymentError'

import { ErrorCodes, ErrorTypes, TrackingEvents } from '../constants'
import { cancelPaymentRequest } from '../utils/cancelPaymentRequest'
import { emitUpdate } from '../utils/emitUpdate'
import { tracker } from '../utils/paymentTracker'
import { store } from '../utils/store'

export async function cancel() {
  const paymentRequestId = store.get('paymentRequestId')
  const paymentResult = store.get('paymentRequestResult')
  const paymentConfirmationToken = paymentResult?.paymentConfirmationToken

  tracker().event(TrackingEvents.CANCEL_CALLED, { paymentRequestId })

  if (!paymentConfirmationToken) {
    throw new PaymentError(
      ErrorTypes.RESOURCE_ERROR,
      ErrorCodes.RESOURCE_NOT_FOUND,
      'Payment confirmation token not available'
    )
  }

  try {
    const response = await cancelPaymentRequest(paymentConfirmationToken)

    emitUpdate()
    tracker().event(TrackingEvents.CANCEL_COMPLETED, {
      paymentRequestId: response.paymentRequestId,
    })
  } catch (error) {
    throw new PaymentError(
      ErrorTypes.TECHNICAL_ERROR,
      ErrorCodes.INTERNAL_ERROR,
      'Cancel failed',
      error
    )
  }
}
