import { html } from 'lit'

export default function zeroFinancingBadge() {
  return html` <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36">
    <g clip-path="url(#a)">
      <path
        fill="#00B76E"
        d="M30.841 34.406H18.224a3.776 3.776 0 0 1-3.776-3.775V12.027a3.775 3.775 0 0 1 1.233-2.79l6.308-5.75a3.773 3.773 0 0 1 5.086 0l6.308 5.75a3.774 3.774 0 0 1 1.232 2.79V30.63a3.776 3.776 0 0 1-3.774 3.775Z"
      />
      <path
        fill="url(#b)"
        d="m11.412 30.115-8.92-8.92a3.775 3.775 0 0 1 0-5.34L15.646 2.701a3.78 3.78 0 0 1 2.844-1.107l11.963.554.554 11.964a3.774 3.774 0 0 1-1.1 2.844L16.753 30.11a3.773 3.773 0 0 1-5.34.005Z"
      />
      <path fill="#000" d="M24.304 11.476a3.173 3.173 0 1 0 0-6.347 3.173 3.173 0 0 0 0 6.347Z" />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1="17.057"
        x2="14.699"
        y1="6.961"
        y2="32.896"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#38FF9C" />
        <stop offset="1" stop-color="#0DE871" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>`
}
