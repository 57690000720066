import { InteractionModes } from '@klarna/flow-interaction-mode'
import { Language, logWarn } from '@klarna-web-sdk/utils'
import { Environment, IdentityConfig, Region } from '@klarna-web-sdk/utils/src/types'
import { v4 as uuidV4 } from 'uuid'

import { ERROR_TITLES, errorHandler } from '../errors'
import { IdentityEvents } from '../identityEvents'
import { identitySessionStorage, IdentityStorageKeys } from '../identitySessionStorage'
import {
  defineIdentityButton,
  IdentityButtonConfiguration,
  IdentityButtonConfigurationSchema,
} from '../klarnaIdentityButton/klarnaIdentityButton'
import { getEnvironmentConfig } from '../utils/environment'
import { getSigninResponse } from '../utils/getSigninResponse'
import { parseLanguageFromLocale } from '../utils/parseLanguageFromLocale'
import { checkRedirectFlowTokens, doesUrlContainOAuthResponse } from '../utils/redirectFlow'
import { shouldRunPKCEFlow } from '../utils/should-run-pkce'
import { AuthorizationServerRegistry } from './AuthorizationServerRegistry'
import { IdentityButton, IdentityButtonPublicAPI } from './IdentityButton'
import { IdentityTracker, TrackingEvents } from './IdentityTracker'

export interface IdentitySDKConfig {
  clientId: string
  environment: Environment
  sessionId: string
  identityRuntimeConfig?: IdentityConfig
  locale?: string
  clientInstanceName?: string
}

const DEFAULT_ENVIRONMENT: Environment = 'playground'

type ButtonsMap = Map<string, IdentityButton>

const readRedirectUrisFromStore = () => {
  try {
    return (identitySessionStorage.get(IdentityStorageKeys.RedirectUris) || []) as string[]
  } catch (e) {}
  return []
}

const saveRedirectUrisToStore = (buttons: ButtonsMap) => {
  const redirectUris = [
    ...new Set(Array.from(buttons.values()).map((button) => button.config.redirectUri)),
  ].filter((uri) => Boolean(uri))
  identitySessionStorage.set(IdentityStorageKeys.RedirectUris, redirectUris)
}

export class IdentitySDK {
  private buttons: ButtonsMap
  private config: IdentitySDKConfig
  private identityEvents: IdentityEvents

  private bypassRedirectUriValidationClients = [
    // Veepee Production
    'klarna_live_client_L0glKm45TjlXNnNRJHY3WHJJbkYxUjVsUFoyMyQwLVYsZmNhMjhkNDMtNTIzYS00YmQzLWFjM2MtMTVmNjBlZDA0MGZlLDEsMzNRQVBiZWp6Y2E5cVNEOGExR0kzbVhFUDlwS3JUcHdJclhPSTBBbm45bz0',
    // Veepee Playground
    'klarna_test_client_TVgzVzIhUmFkODZ6NU5Va09xUWNTVS90V1l4aW01TlksODMzZTcyMWUtMzFlMy00YjFiLTllOTYtZjgwODQwOTFmOGIwLDEsTStlVVVkNzVMeVBHNDlnQ3BoVXVuVGNaUjJqMXJNU1B1blpIT3ZTYlVPND0',
  ]

  constructor(config: IdentitySDKConfig) {
    this.config = {
      environment: DEFAULT_ENVIRONMENT,
      ...config,
    }

    identitySessionStorage.set(IdentityStorageKeys.SessionId, this.config.sessionId)

    AuthorizationServerRegistry.setEnvironment(this.config.environment)
    AuthorizationServerRegistry.setRegion(this.getRegion())

    this.identityEvents = IdentityEvents.getInstance()

    this.buttons = new Map<string, IdentityButton>()

    const { tracker: trackerConfig } = getEnvironmentConfig(
      this.getRegion(),
      this.config.environment
    )
    IdentityTracker.configureInstance(this.config, trackerConfig)

    defineIdentityButton(this)
  }

  public getConfig = () => this.config

  button(configOrId?: string | IdentityButtonConfiguration): IdentityButtonPublicAPI | never {
    if (!configOrId) {
      if (this.buttons.size === 0) {
        throw new Error('There are no buttons!')
      }

      // get first button
      const [firstButton] = [...this.buttons.values()]
      return firstButton.getPublicAPI()
    }

    if (typeof configOrId === 'string') {
      const foundButton = this.buttons.get(configOrId)
      if (!foundButton) {
        throw new Error(`Button '${configOrId}' not found!`)
      }
      return foundButton.getPublicAPI()
    }

    if (this.buttons.size > 0 && !configOrId?.id) {
      logWarn(
        `Identity.button() method was called more than once! Please make sure you are not creating unintentional button instances.

        Tip: If you need to create multiple identity buttons, please consider providing explicit 'id' values for each button to avoid potential side effects!`
      )
    }

    this.validateButtonConfig(configOrId)

    const identityButton = this.createButton(configOrId)
    return identityButton.getPublicAPI()
  }

  public on: typeof this.identityEvents.on = (eventName, callback) => {
    this.identityEvents.on(eventName, callback)

    if (eventName === 'signin' && doesUrlContainOAuthResponse()) {
      this.checkRedirectFlowResponse()
        .then(() => {
          // console.log('Login successful')
        })
        .catch((err) => {
          errorHandler(err, { errorTitle: ERROR_TITLES.RedirectFlowResponseCheckFailed })
        })
    }
  }

  getIdentityPublicAPI = () => {
    return {
      button: this.button.bind(this),
      on: this.on.bind(this),
    }
  }

  private skipRedirectUriValidation = (clientId: string) => {
    return this.bypassRedirectUriValidationClients.includes(clientId)
  }

  private validateButtonConfig(configOrId: IdentityButtonConfiguration) {
    const { redirectUri } = configOrId

    const clientId = this.config.clientId

    if (this.skipRedirectUriValidation(clientId)) {
      if (!redirectUri) {
        IdentityTracker.sendEvent({
          name: TrackingEvents.RedirectUriNotProvided,
        })
      }
      return
    }

    if (!redirectUri) {
      throw new Error('The redirectUri is missing.')
    }

    if (!this.isValidUri(redirectUri)) {
      throw new Error(`The ${redirectUri} redirectUri is an invalid URI.`)
    }

    const { error } = IdentityButtonConfigurationSchema.safeParse(configOrId)
    if (error) {
      logWarn(`Invalid button configuration: ${error.message}`)
    }
  }

  registerButton(htmlElement: HTMLElement) {
    const id = htmlElement.getAttribute('id')

    const existingIdentityButton = this.buttons.get(id)
    if (id && existingIdentityButton) {
      existingIdentityButton.emitReadyEvent()
      return existingIdentityButton
    }

    const config = {
      id,
      ...(htmlElement.dataset as unknown as IdentityButtonConfiguration),
      hideOverlay: htmlElement.dataset['hideOverlay']?.toLocaleLowerCase() === 'true',
    }
    // todo: verify config & remove invalid config: https://klarna.atlassian.net/browse/SIWK-917

    const identityButton = this.createButton(config, htmlElement)
    identityButton.emitReadyEvent()
    return identityButton
  }

  unregisterButton(htmlElement: HTMLElement) {
    const config = htmlElement.dataset as unknown as IdentityButtonConfiguration
    // todo: verify config & remove invalid config: https://klarna.atlassian.net/browse/SIWK-917
    this.buttons.delete(config.id)
    saveRedirectUrisToStore(this.buttons)

    // todo: discuss cleanup sessionStorage as well?: https://klarna.atlassian.net/browse/SIWK-928
  }

  private isValidUri = (uri: string): boolean => {
    try {
      const _uriInstance = new URL(uri)
      return true
    } catch (e) {
      return false
    }
  }

  private getRegion = (): Region => {
    if (!this.config.identityRuntimeConfig?.naClientIds) {
      const errorTitle = ERROR_TITLES.NAClientIdsNotFound
      errorHandler(new Error(errorTitle), { errorTitle })
    }

    const naClientIds = this.config.identityRuntimeConfig?.naClientIds ?? []

    return naClientIds.includes(this.config.clientId) ? 'NA' : 'EU'
  }

  public getLanguage = (): Language => parseLanguageFromLocale(this.config.locale)

  private checkRedirectFlowResponse = async () => {
    // load redirect_uri values used in the authorization request
    const redirectUris = readRedirectUrisFromStore()

    for (const redirectUri of redirectUris) {
      const tokens = await checkRedirectFlowTokens({
        redirectUri,
        clientId: this.config.clientId,
        pkceFlow: shouldRunPKCEFlow(
          this.config.identityRuntimeConfig?.nonPKCEClientIds,
          this.config.clientId
        ),
      })

      if (tokens) {
        this.identityEvents.emit('signin', getSigninResponse(tokens))

        IdentityTracker.sendEvent({
          name: TrackingEvents.LoginSuccess,
          options: {
            flow: InteractionModes.REDIRECT,
          },
        })

        return
      }
    }
  }

  private createButton = (
    { id, ...rest }: IdentityButtonConfiguration,
    htmlElement?: HTMLElement
  ) => {
    if (id) {
      const existingButton = this.buttons.get(id)
      if (existingButton) {
        throw new Error(`There is already a button created with id '${id}'`)
      }
    }

    if (!id) {
      id = `kib-${uuidV4()}`
      if (htmlElement) {
        htmlElement.setAttribute('id', id)
        htmlElement.setAttribute('data-testid', id)
      }
    }

    const identityButton = new IdentityButton({ id, ...rest }, this.config)
    this.buttons.set(id, identityButton)

    identityButton.initAuthorizationServer().then((err) => {
      if (err) {
        /**
         * Calling this.identityEvents.emit('error', err)
         * resulting eslint "security-node/detect-unhandled-event-errors" rule
         * to crash and throw an error. Seems like this is a bug in eslint rule.
         *
         * */
        this.identityEvents.emit('error', new Error(err.message))
      }
    })

    saveRedirectUrisToStore(this.buttons)

    return identityButton
  }
}

export type IdentitySDKPublicAPI = ReturnType<typeof IdentitySDK.prototype.getIdentityPublicAPI>
