export default {
  content: {
    nodes: [
      {
        type: 'TEXT',
        name: 'TEXT_MAIN',
        value: 'Enjoy Buyer Protection with Klarna',
      },
      {
        type: 'ACTION',
        name: 'ACTION_LEARN_MORE',
        label: 'See payment options',
        url: '',
      },
      {
        type: 'ACTION',
        name: 'ACTION_OPEN_BUYERS_PROTECTION_LINK',
        label: 'Buyer Protection',
        url: 'https://www.klarna.com/uk/buyer-protection-description/',
      },
    ],
  },
  impression_url: '',
}
