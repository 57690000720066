import { EStorageTypes } from './constants'

export function localStorage(storageKey: string, win = window) {
  if (!win?.localStorage) {
    throw new Error(`client does not support ${EStorageTypes.localStorage}`)
  }

  const getStorageObj = () => {
    return JSON.parse(win.localStorage.getItem(storageKey) || '{}')
  }

  const StorageOps = {
    get: (itemKey: string) => {
      const storageObj = getStorageObj()

      const item = storageObj?.[itemKey]
      if (!item) return null

      const ttl = storageObj?.ttl

      if (ttl && ttl < Date.now()) {
        StorageOps.flush()
        return null
      }

      return item
    },
    set: (itemKey: string, data: unknown) => {
      const storageObj = getStorageObj()

      if (data === null) delete storageObj[itemKey]
      else storageObj[itemKey] = data

      return win.localStorage.setItem(storageKey, JSON.stringify(storageObj))
    },
    remove: (itemKey: string) => {
      return StorageOps.set(itemKey, null)
    },
    flush: () => {
      return win.localStorage.removeItem(storageKey)
    },
  }

  return StorageOps
}
