import { getLanguage } from './getLanguage'
import { replacePlaceholders } from './replacePlaceholders'
import { Translations } from './translations'
import { ETranslationKey, Language } from './types'

export const getTranslation = (
  key: ETranslationKey,
  { locale, params }: { locale?: Language; params?: string[] } = {}
): string => {
  const language = locale || getLanguage()
  let translationString = Translations[key as ETranslationKey][language as Language]

  if (params && translationString) {
    translationString = replacePlaceholders(translationString, params).join('')
  }

  return translationString
}
