import { logWarn } from '../logger'
import type { Region } from '../types.d.ts'
import { TimeZoneMapping, timeZoneMapping } from './timeZoneMapping'

const FALLBACK_REGION: Lowercase<Region> = 'eu'

export const getFallbackRegion = (): Lowercase<Region> => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  if (timeZone) {
    const regionFromTimeZone = timeZone.split('/')[0].toLowerCase() as keyof TimeZoneMapping
    if (regionFromTimeZone in timeZoneMapping) {
      return timeZoneMapping[regionFromTimeZone]
    }
  }

  logWarn(`Provided region is not supported - defaulting region to ${FALLBACK_REGION}`)
  return FALLBACK_REGION
}
