import retry from 'async-retry'
import * as oauth from 'oauth4webapi'

import type { EnvironmentConfig } from '../config'
import { ERROR_TITLES, errorHandler } from '../errors'
import { TokenExchangeError } from '../errors/token-exchange-error'
import { IdentityEvents } from '../identityEvents'
import { identitySessionStorage, IdentityStorageKeys } from '../identitySessionStorage'
import { RETRY_OPTIONS } from '../utils/retry'
import { IdentityTracker, TrackingEvents } from './IdentityTracker'

export interface OAuthTokens {
  access_token: string
  id_token: string
  token_type: string
  refresh_token?: string
  expires_in?: number
  scope?: string
}

export interface OAuthAuthorizationCodeResponse {
  code: string
}

type ConstructOIDCAuthorizationUrlParams = {
  clientId: string
  scope: string
  redirectUri: string
  pkceFlow: boolean
  locale?: string
  tokenizationId?: string
  // todo: discuss sessionId is optional in SDKConfig
  // but was mandatory in our function
  // what happens if it's not provided
  sessionId?: string
}

type VerifyLoginParams = {
  clientId: string
  urlWithLoginParams: URL
  isOnPageFlow: boolean
  redirectUri?: string
  pkceFlow: boolean
}

type OAuthRequestParams = {
  client_id: string
  redirect_uri: string
  response_type: string
  scope: string
  funnel_id: string
  state: string
  platform_type: 'WEB'
  nonce?: string
  code_challenge?: string
  code_challenge_method?: string
  tokenization_id?: string
}

export class AuthorizationServer {
  private authorizationServerMetadata: oauth.AuthorizationServer
  private environmentConfig: EnvironmentConfig

  constructor(
    authorizationServerMetadata: oauth.AuthorizationServer,
    environmentConfig: EnvironmentConfig
  ) {
    this.authorizationServerMetadata = authorizationServerMetadata
    this.environmentConfig = environmentConfig
  }

  getIdpOrigin = () => {
    return new URL(this.environmentConfig.oidc.idpUrl).origin
  }

  getOnPageFlowRedirectUri = () => {
    const idpOrigin = this.getIdpOrigin()
    const klarnaRedirectPage = `${idpOrigin}/popup/callback`
    const merchantEncodedOrigin = window.btoa(window.location.origin)
    const merchantEncodedRedirectURI = `${klarnaRedirectPage}/${merchantEncodedOrigin}`

    return merchantEncodedRedirectURI
  }

  constructOIDCAuthorizationUrl = async ({
    clientId,
    redirectUri,
    scope,
    sessionId,
    locale,
    pkceFlow,
    tokenizationId,
  }: ConstructOIDCAuthorizationUrlParams): Promise<URL> => {
    if (!this.authorizationServerMetadata.authorization_endpoint) {
      throw new Error('Authorization Endpoint not present')
    }
    const state = oauth.generateRandomState()
    identitySessionStorage.set(IdentityStorageKeys.State, state)

    const authParams: OAuthRequestParams = {
      client_id: clientId,
      redirect_uri: redirectUri,
      response_type: 'code',
      scope,
      funnel_id: sessionId,
      state,
      platform_type: 'WEB',
    }

    if (pkceFlow) {
      const nonce = oauth.generateRandomNonce()
      const codeVerifier = oauth.generateRandomCodeVerifier()
      const codeChallenge = await oauth.calculatePKCECodeChallenge(codeVerifier)

      identitySessionStorage.set(IdentityStorageKeys.Nonce, nonce)
      identitySessionStorage.set(IdentityStorageKeys.CodeVerifier, codeVerifier)

      authParams.nonce = nonce
      authParams.code_challenge = codeChallenge
      authParams.code_challenge_method = 'S256'
    }

    if (tokenizationId) {
      authParams.tokenization_id = tokenizationId
    }

    const searchParams = new URLSearchParams(authParams)

    // todo: discuss how to handle this
    const prompt = new URLSearchParams(window.location.search).get('klarna-auth-prompt')
    if (prompt) searchParams.set('prompt', prompt)

    if (locale) searchParams.set('ui_locales', locale)

    const authorizationUrl = new URL(this.authorizationServerMetadata.authorization_endpoint)
    authorizationUrl.search = searchParams.toString()

    return authorizationUrl
  }

  verifyLogin = async ({
    clientId,
    urlWithLoginParams,
    isOnPageFlow,
    redirectUri = '',
    pkceFlow,
  }: VerifyLoginParams): Promise<OAuthTokens | OAuthAuthorizationCodeResponse> => {
    const state = identitySessionStorage.get(IdentityStorageKeys.State)

    if (!state || typeof state !== 'string') {
      throw new Error('State is missing')
    }

    const identityEvents = IdentityEvents.getInstance()

    const client: oauth.Client = {
      client_id: clientId,
      // TODO: check if this value is important. We don't know which secret it could be
      token_endpoint_auth_method: pkceFlow ? 'none' : 'client_secret_basic',
    }

    const params = oauth.validateAuthResponse(
      this.authorizationServerMetadata,
      client,
      urlWithLoginParams,
      state
    )

    if (oauth.isOAuth2Error(params)) {
      const errorBodies = [params.error]
      if (params.error_description) errorBodies.push(params.error_description)
      identityEvents.emit('error', new Error(errorBodies.join(' - ')))

      const errorTitle = ERROR_TITLES.OAuthError
      const error = new Error(errorTitle)
      errorHandler(error, { errorTitle, result: params })
      IdentityTracker.sendEvent({
        name: TrackingEvents.LoginFailure,
        options: {
          reason: params.error_description || errorTitle,
        },
      })
      throw error
    }

    if (!pkceFlow) {
      const code = params.get('code')
      return { code }
    }

    const result = await this.performTokenExchange(client, params, isOnPageFlow, redirectUri)

    const { access_token, id_token, token_type, refresh_token, scope, expires_in } = result

    return {
      access_token,
      id_token,
      token_type,
      refresh_token,
      scope,
      expires_in,
    }
  }

  private async performTokenExchange(
    client: oauth.Client,
    params: URLSearchParams,
    isOnPageFlow: boolean,
    redirectUri: string
  ): Promise<oauth.OpenIDTokenEndpointResponse> {
    const codeVerifier = identitySessionStorage.get(IdentityStorageKeys.CodeVerifier)
    if (!codeVerifier) throw new Error('codeVerifier missing')

    const nonce = identitySessionStorage.get(IdentityStorageKeys.Nonce)
    if (!nonce) throw new Error('nonce missing')

    let tokenExchangeResponse
    try {
      tokenExchangeResponse = await retry(
        async () => {
          return oauth.authorizationCodeGrantRequest(
            this.authorizationServerMetadata,
            client,
            params,
            isOnPageFlow ? this.getOnPageFlowRedirectUri() : redirectUri,
            codeVerifier
          )
        },
        {
          ...RETRY_OPTIONS,
          onRetry: (error, attempt): void => {
            errorHandler(error, {
              errorTitle: ERROR_TITLES.AuthorizationCodeGrantRequestCallFailed,
              attempt,
            })
          },
        }
      )
    } catch (error) {
      errorHandler(error, {
        errorTitle: 'Token exchange failed',
      })
      IdentityTracker.sendEvent({
        name: TrackingEvents.LoginFailure,
      })
      throw new TokenExchangeError(`Token Exchange failed. Reason: ${error}`)
    }

    if (!tokenExchangeResponse.ok) {
      const errorBody = await tokenExchangeResponse.json()
      const errorTitle = ERROR_TITLES.TokenExchangeFailed
      const error = new TokenExchangeError(errorTitle)
      errorHandler(error, {
        errorTitle,
        result: errorBody,
      })
      IdentityTracker.sendEvent({
        name: TrackingEvents.LoginFailure,
      })
      throw error
    }

    const result = await oauth.processAuthorizationCodeOpenIDResponse(
      this.authorizationServerMetadata,
      client,
      tokenExchangeResponse,
      nonce
    )

    if (oauth.isOAuth2Error(result)) {
      const error = new Error(ERROR_TITLES.InvalidAuthorizationCodeOpenIDResponse)
      errorHandler(error, {
        errorTitle: ERROR_TITLES.InvalidAuthorizationCodeOpenIDResponse,
        result,
      })
      IdentityTracker.sendEvent({
        name: TrackingEvents.LoginFailure,
      })
      throw error
    }
    return result
  }
}
