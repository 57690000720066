import { ETranslationKey, Language } from './types'

export const Translations = {
  [ETranslationKey.ContinueWith]: {
    [Language.DA]: 'Fortsæt med {{klarna}}',
    [Language.DE]: 'Weiter mit {{klarna}}',
    [Language.EN]: 'Continue with {{klarna}}',
    [Language.ES]: 'Continuar con {{klarna}}',
    [Language.FI]: 'Jatka Klarnalla',
    [Language.FR]: 'Continuer avec {{klarna}}',
    [Language.IT]: 'Continua con {{klarna}}',
    [Language.NL]: 'Verdergaan met {{klarna}}',
    [Language.NB]: 'Fortsett med {{klarna}}',
    [Language.PL]: 'Kontynuuj z {{klarna}}',
    [Language.PT]: 'Continuar com {{klarna}}',
    [Language.SV]: 'Fortsätt med {{klarna}}',
  },
  [ETranslationKey.PayWith]: {
    [Language.DA]: 'Betal med {{klarna}}',
    [Language.DE]: 'Bezahlen mit {{klarna}}',
    [Language.EN]: 'Pay with {{klarna}}',
    [Language.ES]: 'Pagar con {{klarna}}',
    [Language.FI]: 'Maksa käyttäen {{klarna}}',
    [Language.FR]: 'Payer avec {{klarna}}',
    [Language.IT]: 'Paga con {{klarna}}',
    [Language.NL]: 'Betalen met {{klarna}}',
    [Language.NB]: 'Betal med {{klarna}}',
    [Language.PL]: 'Zapłać za pomocą {{klarna}}',
    [Language.PT]: 'Pagar com {{klarna}}',
    [Language.SV]: 'Betala med {{klarna}}',
  },
  [ETranslationKey.OverlayContentText]: {
    [Language.DA]:
      'Ser du ikke Klarna-vinduet? Vi hjælper dig med at åbne det for at fuldføre din tilmelding.',
    [Language.DE]:
      'Sie sehen das Klarna-Fenster nicht? Wir helfen Ihnen dabei, es zu öffnen, um Ihre Anmeldung abzuschließen.',
    [Language.EN]: "Don't see the Klarna window? We'll help you open it to complete your sign in.",
    [Language.ES]:
      '¿No ves la ventana de Klarna? Te ayudaremos a abrirla para completar tu inicio de sesión.',
    [Language.FI]: 'Et näe Klarnan ikkunaa? Autamme sinua avaamaan sen ja kirjautumaan sisään.',
    [Language.FR]:
      "Vous ne voyez pas la fenêtre Klarna ? Nous vous aiderons à l'ouvrir pour finaliser votre connexion.",
    [Language.IT]:
      "Non vedi la finestra di Klarna? Ti aiuteremo ad aprirla per completare l'accesso.",
    [Language.NL]:
      'Zie je het Klarna-venster niet? We helpen je het te openen om je aanmelding te voltooien.',
    [Language.NB]:
      'Ser du ikke Klarna-vinduet? Vi hjelper deg med å åpne det for å fullføre innloggingen din.',
    [Language.PL]: 'Nie widzisz okna Klarna? Pomożemy je otworzyć, aby zakończyć proces logowania.',
    [Language.PT]:
      'Não está vendo a janela do Klarna? Vamos ajudar a abri-la para concluir seu login.',
    [Language.SV]:
      'Öppnas inte Klarnas fönster automatiskt? Klicka här så hjälper vi dig att logga in.',
  },
  [ETranslationKey.OverlayContentButtonLabel]: {
    [Language.DA]: 'Fortsæt',
    [Language.DE]: 'Weiter',
    [Language.EN]: 'Continue',
    [Language.ES]: 'Continuar',
    [Language.FI]: 'Jatka',
    [Language.FR]: 'Continuer',
    [Language.IT]: 'Continua',
    [Language.NL]: 'Doorgaan',
    [Language.NB]: 'Fortsett',
    [Language.PL]: 'Kontynuuj',
    [Language.PT]: 'Continuar',
    [Language.SV]: 'Fortsätt',
  },
}
