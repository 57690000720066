/**
 * The SDK bundles all Klarna products, Pay with Klarna, Sign-in with Klarna, and Conversion Boost products.
 *
 * # Including Klarna.js
 * Include the Klarna.js script on every page of your site that renders a Klarna component or Klarna flow. The script should always be loaded directly from https://js.klarna.com/web-sdk/v1/klarna.js, rather than included in a bundle or hosted yourself.
 * Klarna.js supports asynchronous and deferred loading by adding async or defer to the script tag. When deferring the loading of Klarna.js it's important to not access the SDK before it has finished loading. The web sdk triggers a global callback when it has finished loading window.KlarnaSDKCallback that can be used to safely access the web sdk APIs.
 *
 * # Initiating the SDK with credentials
 * Klarnas Web SDK supports two different ways of providing credentials. Important to note is that both ways of providing credentials can not be combined.
 * 1. On the script tag
 * ```html
 * <script defer
 *  src="https://js.klarna.com/web-sdk/v1/klarna.js"
 *  data-account-id="<The account id>"
 *  data-client-id="<Your client id>"
 *  ></script>
 *
 * <script>
 *  window.KlarnaSDKCallback = async (Klarna) => {
 *    // Klarna SDK ready to be utilized
 *    console.log(Klarna.Payment.request())
 *  }
 * </script>
 * ```
 *  2. In the init method
 * ```html
 * <script defer
 *   src="https://js.klarna.com/web-sdk/v1/klarna.js"
 * ></script>
 *
 * <script>
 *   window.KlarnaSDKCallback = async (Klarna) => {
 *     // Klarna SDK ready to be initialized with klarna.init(...)
 *     const klarna = await Klarna.init({
 *       clientId: '<Your client id',
 *       accountId: '<Your account id',
 *     })
 *     // Klarna SDK ready to be utilized
 *     console.log(klarna.Payment.request())
 *  }
 * </script>
 * ```
 *
 * # Initiating the SDK on a page where another instance of the SDK is already initiated
 * If you want to initiate the SDK on a page where another instance of the SDK is already initiated or will be initiated, you can pass a client instance name to the script tag to avoid conflicts with the global `KlarnaSDKCallback` function. The client instance name will be appended to the global callback function name. Make sure to always use the same client instance name on every page.
 *
 * ```html
 * <script defer
 *   src="https://js.klarna.com/web-sdk/v1/klarna.js"
 *   data-client-instance-name="custominstance"
 * ></script>
 *
 * <script>
 *   window.KlarnaSDKCallback_custominstance = async (Klarna) => {
 *     // Klarna SDK ready to be initialized with klarna.init(...)
 *     const klarna = await Klarna.init({
 *       clientId: '<Your client id',
 *       accountId: '<Your account id',
 *     })
 *     // Klarna SDK ready to be utilized
 *     console.log(klarna.Payment.request())
 *  }
 * </script>
 * ```
 *
 * Important note on Script Tag Initialization:
 *
 * When initializing the SDK with the script tag, the presence of data-attributes (such as data-account-id and data-client-id) is not validated by the SDK. This means that if these attributes are incorrect, the SDK will not display any warnings. This behavior is working as expected with the SDK's handling of errors during programmatic initialization (using the init method). It is important to ensure that these attributes are correctly set as they are crucial for the SDK's operation.
 *
 * # Supported browsers:
 * Klarna.js supports the following set of browsers:
 * * the last 2 versions of all browsers (not including those considered 'dead'),
 * * any browser used by more than 0.5% of people,
 * * Firefox Extended Support Release (ESR),
 * * iOS versions greater than 10.
 *
 * You can see the full supported list here. Ensure that your usage of the SDK aligns with these browsers to guarantee full functionality.
 * @module Klarna SDK
 */

import { backendBridge } from '@klarna-web-sdk/backend-bridge'
import IdentitySDK from '@klarna-web-sdk/identity'
import { KlarnaInteroperability } from '@klarna-web-sdk/interoperability'
import KlarnaPlacement from '@klarna-web-sdk/messaging'
import setupMessagingAPI from '@klarna-web-sdk/messaging/src/api'
import { KlarnaPayment } from '@klarna-web-sdk/payment'
import { KlarnaShopping } from '@klarna-web-sdk/shopping'
import {
  configureTracker,
  decodeClientToken,
  EVENTS_SAMPLING_RATE,
  getSampleGroup,
  removeUndefinedAttributes,
  sessionStorageHelper,
  setupSentry,
} from '@klarna-web-sdk/utils'
import { getRolloutVariant } from '@klarna-web-sdk/utils/src/getVersion'
import shouldLoadTestDriveBadge from '@klarna-web-sdk/utils/src/shouldLoadTestDriveBadge/shouldLoadTestDriveBadge'
import { TrackerClients } from '@klarna-web-sdk/utils/src/tracker/trackerClients'
import trackerFactory from '@klarna-web-sdk/utils/src/tracker/trackerFactory'
import { Methods, SDKConfig, Session } from '@klarna-web-sdk/utils/src/types'
import { v4 as uuidv4 } from 'uuid'

import { PublicAPI, PublicAPIOSM } from './types'

/**
 * @hidden
 */
class SDK {
  private config: SDKConfig

  private tracker: ReturnType<typeof trackerFactory>
  private session: Session
  private identitySDK: IdentitySDK

  Payment: KlarnaPayment
  Shopping: KlarnaShopping
  Interoperability: KlarnaInteroperability
  Messaging: ReturnType<typeof setupMessagingAPI> // Add MessagingType

  constructor(config: SDKConfig) {
    if (!config?.clientInstanceName) {
      config.clientInstanceName = uuidv4()
    }

    this.config = config

    const storage = sessionStorageHelper(`${config.clientInstanceName}-config`)
    if (storage) {
      if (storage.get('config')) {
        this.config = storage.get('config')
      } else {
        storage.set('config', this.config)
      }
    }

    /**
     * For KCO which uses PACS backend to create sessions we will initiate the SDK with a clientToken.
     * From the clientToken we can extract a payment session client id and environment which will take precedence over directly provided attributes.
     */
    if (this.config.clientToken) {
      this.configureSDKWithClientToken()
    }

    this.tracker = configureTracker({
      config: {
        version: this.config.version,
        environment: this.config.environment,
        sessionId: this.config.sessionId,
      },
      trackerClient: TrackerClients.websdk,
      extraTrackingData: {
        additionalIdentifier: this.config.additionalIdentifier,
        clientId: this.config.clientId,
        accountId: this.config.accountId,
        instanceId: this.config.clientInstanceName,
      },
      locale: this.config.locale,
    })
    backendBridge.configure(this.config, this.tracker)
    this.initSentryUtils()
    this.trackRollout()
    this.initializeSDKFeatures()
    this.loadTestDriveBadge()
  }

  private trackRollout() {
    if (getSampleGroup(EVENTS_SAMPLING_RATE.INIT)) {
      const rolloutVariant = getRolloutVariant()

      this.tracker.event('metric_sdk_init', {
        rolloutVariant,
      })
    }
  }

  private loadTestDriveBadge = () => {
    const { environment, clientId } = this.config

    if (shouldLoadTestDriveBadge(environment || '', clientId)) {
      import(/* webpackChunkName: "klarna-test-drive-badge" */ '../elements/test-drive-badge')
    }
  }

  private configureSDKWithClientToken = () => {
    const { sessionId, clientId, environment } = decodeClientToken(this.config.clientToken)

    this.config.environment = environment
    this.config.clientId = clientId

    this.setSession({
      session_id: sessionId,
      client_token: this.config.clientToken,
    })
  }

  private initSentryUtils = () => {
    const {
      accountId,
      clientId,
      clientToken,
      environment,
      version,
      additionalIdentifier,
      clientInstanceName,
    } = this.config

    const tags = removeUndefinedAttributes({
      accountId,
      clientId,
      clientToken,
      clientInstanceName,
      additionalIdentifier,
    })

    setupSentry({ environment, version, tags })
  }

  getMethodsObject(): Methods {
    return {
      getSession: this.getSession,
      setSession: this.setSession,
    }
  }

  private initializeSDKFeatures = () => {
    if (__PACKAGE__ !== 'osm' && this.config?.environment !== 'production') {
      try {
        this.Shopping = KlarnaShopping.getInstance(this.config.shoppingSessionId)
      } catch {
        this.tracker.event('error', { message: 'Failed to initialize Shopping SDK' })
      }
    }

    if (__PACKAGE__ !== 'osm') {
      try {
        this.Interoperability = new KlarnaInteroperability()
      } catch {
        this.tracker.event('error', { message: 'Failed to initialize Interoperability SDK' })
      }
    }

    try {
      this.Messaging = setupMessagingAPI.call(this, this.config, this.getMethodsObject())
      KlarnaPlacement(this, this.config)
    } catch {
      this.tracker.event('error', { message: 'Failed to initialize Messaging SDK' })
    }

    if (__PACKAGE__ !== 'osm') {
      try {
        this.Payment = new KlarnaPayment(this.config)
      } catch {
        this.tracker.event('error', { message: 'Failed to initialize Payment SDK' })
      }
    }

    if (__PACKAGE__ !== 'osm') {
      try {
        this.identitySDK = new IdentitySDK({
          sessionId: this.config.sessionId,
          environment: this.config.environment,
          clientId: this.config.clientId,
          identityRuntimeConfig: this.config.runtimeConfig?.identity,
          locale: this.config.locale,
          clientInstanceName: this.config.clientInstanceName,
        })
      } catch {
        this.tracker.event('error', { message: 'Failed to initialize Identity SDK' })
      }
    }
  }

  private getSession = () => this.session as Session

  private setSession = (session: Session) => {
    this.session = session
  }

  // For legacy OSM we only need the messaging package
  // @todo: refactor this solution with ticket: https://klarna.atlassian.net/browse/KPC-965
  public getPublicAPI(): PublicAPI | PublicAPIOSM {
    if (__PACKAGE__ !== 'osm') {
      const Payment = this.Payment?.getPublicAPI()
      const Identity = this.identitySDK?.getIdentityPublicAPI()
      const Messaging = this.Messaging
      const Shopping = this.Shopping?.getPublicAPI()
      const Interoperability = this.Interoperability?.getPublicAPI()
      class PublicAPI {
        Payment = Payment
        Identity = Identity
        Messaging = Messaging
        Shopping = Shopping
        Interoperability = Interoperability
      }

      return new PublicAPI()
    } else {
      const Messaging = this.Messaging
      class PublicAPI {
        Messaging = Messaging
      }

      return new PublicAPI()
    }
  }
}

export default SDK

export type { PublicAPI as KlarnaJS } from './types'

export type { SDKConfig as KlarnaConfiguration } from '@klarna-web-sdk/utils/src/types'
