import { html } from 'lit'
import { unsafeHTML } from 'lit/directives/unsafe-html.js'

import { KlarnaBadge } from '../../assets/logos'
import styles from './style.scss'

interface TopStripPromotionBadgeProps {
  customStyles?: string
  theme?: string
  mainText?: string
  ctaLabel?: string
  legalText?: string
  onLearnMoreClick: () => null
}

export default function topStripPromotionBadge({
  customStyles,
  theme,
  mainText,
  ctaLabel,
  legalText,
  onLearnMoreClick,
}: TopStripPromotionBadgeProps) {
  return html`
    <style>
      ${styles}
    </style>
    ${html`${unsafeHTML(customStyles)}`}
    <div
      data-testid="${mainText ? 'top-strip-promotion-badge' : ''}"
      class="container ${theme}"
      part="osm-container"
    >
      <div class="badge-container">
        <div class="badge" part="osm-badge-container">${unsafeHTML(KlarnaBadge())}</div>
      </div>
      <div class="text-wrapper">
        <p part="osm-message" class="text">
          ${mainText}
          <button @click=${onLearnMoreClick} class="link" part="osm-cta">${ctaLabel}</button>
        </p>
        ${legalText ? html`<p part="osm-legal" class="legal">${legalText}</p>` : ''}
      </div>
    </div>
  `
}
