export class ValidationError extends Error {
  public status: number

  public serializable: boolean

  public type: string

  public constructor(message = 'Invalid request params') {
    super(message)
    this.name = 'VALIDATION_ERROR'
    this.type = 'INPUT_ERROR'
    this.status = 400
    this.serializable = true
  }
}
