import { EventBus } from '@klarna-web-sdk/utils'

export enum messagingEvents {
  MODAL_OPENED_LEGACY = 'informationalModalOpened', // For backward compatibility
  MODAL_CLOSED_LEGACY = 'informationalModalClosed', // For backward compatibility
  PLACEMENT_RENDERED_LEGACY = 'placementRendered', // For backward compatibility
  PLACEMENT_RENDERED = 'placement-rendered',
  MODAL_OPENED = 'informational-modal-opened',
  MODAL_CLOSED = 'informational-modal-closed',
}

export class MessagingEvents extends EventBus<messagingEvents, undefined> {
  // eslint-disable-next-line no-use-before-define
  private static instance: MessagingEvents

  private constructor() {
    super()
  }

  static getInstance(): MessagingEvents {
    if (!MessagingEvents.instance) {
      MessagingEvents.instance = new MessagingEvents()
    }

    return MessagingEvents.instance
  }
}

export default messagingEvents
