import { CreateElement, logWarn } from '@klarna-web-sdk/utils'
import { klarnaElements } from '@klarna-web-sdk/utils/src/constants'

import { PaymentButtonState } from '../constants'
import { KlarnaPaymentButton, PaymentButtonConfiguration } from '../types'
import { request } from './request'

export class PaymentButton extends CreateElement {
  constructor(config?: PaymentButtonConfiguration) {
    // TODO: add validation for config
    super(klarnaElements.PAYMENT_BUTTON, config)
  }

  toggleState(state: PaymentButtonState, value?: boolean) {
    const lowerCaseState = state.toLowerCase() as PaymentButtonState

    if (Object.values(PaymentButtonState).includes(lowerCaseState)) {
      const reverseValue =
        value !== undefined
          ? value.toString()
          : this.htmlElement.getAttribute(`data-${lowerCaseState}`) === 'true'
            ? 'false'
            : 'true'

      this.htmlElement.setAttribute(`data-${lowerCaseState}`, reverseValue)
    } else {
      logWarn(
        `Invalid state: ${state}. Allowed values are: ${Object.values(PaymentButtonState).join(', ')}`
      )
    }
    return this
  }

  on(
    event: 'click',
    callback: (payment_request: ReturnType<typeof request>, button?: string) => Promise<void>
  ) {
    this.htmlElement.onclick = () => callback(request(), this.id)

    return this
  }

  getPublicAPI(): KlarnaPaymentButton {
    return {
      ...super.getPublicAPI(),
      toggleState: this.toggleState.bind(this),
      on: this.on.bind(this),
    }
  }
}
