import { html, svg } from 'lit'

export const Visa = html`<svg
  part="badge"
  role="img"
  focusable="false"
  viewBox="0 0 35 22"
  aria-label="Visa"
>
  ${svg`<g fill="none">
    <path fill="#1A1F70" d="M33.24443 22H1.7277C.76903 21.9849 0 21.20974 0 20.2588V1.7412C0 .79027.76903.0151 1.7277 0h31.51673C34.214 0 35 .77956 35 1.7412v18.5176C35 21.22043 34.214 22 33.24443 22"/><path fill="#FFF" d="M17.33333 7.645 15.8889 14.3h-1.72222l1.41666-6.655h1.75Zm7.33334 4.29.91666-2.5025.52778 2.5025h-1.44444ZM26.6111 14.3h1.58333l-1.38888-6.65503h-1.5c-.314-.00275-.59888.18165-.72223.46753L21.97223 14.3h1.83333l.3611-.99h2.22223l.22222.99Zm-4.55555-2.1725c0-1.76-2.44445-1.87-2.44445-2.64 0-.2475.25-.495.75-.5775.59104-.05274 1.18558.0517 1.72222.3025L22.3889 7.81c-.52195-.20292-1.07815-.30558-1.63889-.30257-1.72222 0-2.91667.90757-2.94444 2.20007-.02778 1.2925.8611 1.485 1.52777 1.815.66667.33.91667.55.8889.825-.02779.275-.52779.66-1.02779.66-.61702.00205-1.22593-.13923-1.77777-.4125l-.33334 1.4575a5.77516 5.77516 0 0 0 1.94445.33003c1.83333 0 3.02778-.88003 3.02778-2.25503M14.8611 7.645 12.02778 14.3h-1.83334L8.80556 8.965c-.03566-.24817-.19111-.46362-.41667-.5775a6.6586 6.6586 0 0 0-1.72222-.55l.05555-.1925h2.94445c.39585-.00586.73734.27392.80555.66l.75 3.85 1.80556-4.51h1.83333Z"/>
  </g>`}
</svg> `
