import { buildPlacementSelectorName, CreateElement } from '@klarna-web-sdk/utils'
import { klarnaElements } from '@klarna-web-sdk/utils/src/constants'

import { MessagingEvents } from './events'
import initializeWindowInterface from './helpers/initializeWindowInterface/initializeWindowInterface'
import { MessagingArgs } from './types'

function setupMessagingAPI() {
  const messagingEventBus = MessagingEvents.getInstance()
  const on = messagingEventBus.on.bind(messagingEventBus)
  const off = messagingEventBus.off.bind(messagingEventBus)
  const emit = messagingEventBus.emit.bind(messagingEventBus)

  initializeWindowInterface(on, off)

  const placementSelector = buildPlacementSelectorName(this.config.clientInstanceName)

  const Messaging = {
    placement: (args: MessagingArgs) => {
      return new CreateElement(placementSelector as klarnaElements, args).getPublicAPI()
    },
    on,
    off,
    emit,
  }

  return Messaging
}

export default setupMessagingAPI
