import { PaymentError } from '@klarna-web-sdk/payment/src/utils/paymentError'

import { ErrorCodes, ErrorTypes, TrackingEvents } from '../constants'
import { fetchPaymentRequest } from '../utils/fetchPaymentRequest'
import { tracker } from '../utils/paymentTracker'
import { store } from '../utils/store'
import { request } from './request'

export async function fetch() {
  const paymentRequestId = store.get('paymentRequestId')

  tracker().event(TrackingEvents.FETCH_CALLED, { paymentRequestId })

  if (!paymentRequestId) {
    throw new PaymentError(
      ErrorTypes.RESOURCE_ERROR,
      ErrorCodes.RESOURCE_NOT_FOUND,
      'No payment request available'
    )
  }

  try {
    const response = await fetchPaymentRequest()
    tracker().event(TrackingEvents.FETCH_COMPLETED, { paymentRequestId: response.paymentRequestId })

    return request()
  } catch (error) {
    throw new PaymentError(
      ErrorTypes.TECHNICAL_ERROR,
      ErrorCodes.INTERNAL_ERROR,
      'Fetch failed',
      error
    )
  }
}
