import { getUserLocale } from '../getUserLocale'
import { Language } from './types'

const isSupportedLanguage = (lng: string): boolean =>
  Object.values(Language).includes(lng as Language)

const extractLanguageFromLocale = (locale: string): Language =>
  locale.substring(0, 2)?.toLowerCase() as Language

export const getLanguage = (): Language => {
  const locale = getUserLocale()
  if (locale) {
    const language = extractLanguageFromLocale(locale)
    if (isSupportedLanguage(language)) {
      return language
    }
  }
  return Language.EN
}
