import { LOG_LEVEL } from '@klarna/frontend-events'

import createTracker from './createTracker'
import { TrackerClients } from './trackerClients'

const instances: { [key in TrackerClients]?: ReturnType<typeof createTracker> } = {}

export interface TrackerOptions {
  client: string
  clientVersion: string
  sessionId: string
  baseUrl?: string
}

export interface TrackerEventConfig {
  options: TrackerOptions
  data?: unknown
  instanceId?: string
}

type EventFunction = (
  name: string,
  postData?: Record<string, unknown>,
  options?: Record<string, unknown>,
  level?: LOG_LEVEL
) => void

export default function trackerFactory(id: TrackerClients) {
  let logLevel = LOG_LEVEL.INFO
  let extraData = {}

  const event: EventFunction = (name, postData = {}, options = {}, level = LOG_LEVEL.INFO) => {
    if (level < logLevel) {
      return
    }

    if (instances[id]) {
      instances[id].event(
        name,
        {
          level,
          ...options,
          ...extraData,
          ...postData,
        },
        {},
        level
      )
    }
  }

  const eventsFactory =
    (event: EventFunction, logLevel: LOG_LEVEL) =>
    (name: string, postData: Record<string, unknown> = {}, options: Record<string, unknown> = {}) =>
      event(name, postData, options, logLevel)

  return {
    configure: ({ options, data = {}, instanceId = undefined }: TrackerEventConfig) => {
      extraData = data as Record<string, unknown>
      if (!instances[id]) {
        instances[id] = createTracker(
          configWithInstanceId({
            ...options,
            instanceId,
          })
        )
      }
    },
    event,
    trace: eventsFactory(event, LOG_LEVEL.TRACE),
    debug: eventsFactory(event, LOG_LEVEL.DEBUG),
    info: eventsFactory(event, LOG_LEVEL.INFO),
    warn: eventsFactory(event, LOG_LEVEL.WARN),
    error: eventsFactory(event, LOG_LEVEL.ERROR),
    fatal: eventsFactory(event, LOG_LEVEL.FATAL),
    setLogLevel(logLevelKey = 'ALL') {
      try {
        const logLevelKeyUpperCase = logLevelKey.toUpperCase()
        logLevel =
          logLevelKeyUpperCase in LOG_LEVEL
            ? LOG_LEVEL[logLevelKeyUpperCase as keyof typeof LOG_LEVEL]
            : LOG_LEVEL.ALL

        if (instances[id]) {
          instances[id].setLogLevel(logLevel)
        }
      } catch (error) {
        // at least we try
      }
    },
    removeInstance() {
      if (instances[id]) {
        delete instances[id]
      }
    },
  }
}

const configWithInstanceId = ({
  client = 'sdk',
  clientVersion = '',
  sessionId = '',
  instanceId,
  baseUrl = '',
}: {
  client?: string
  clientVersion?: string
  sessionId?: string
  instanceId?: string
  baseUrl?: string
}) => ({
  client,
  clientVersion,
  environment: process.env.NODE_ENV,
  sessionId,
  instanceId,
  baseUrl,
})
