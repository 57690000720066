import { SentryRequestBody } from '@micro-sentry/core'

import { getSampleGroup } from '../getSampleGroup'

const sampleRate = 5
const sampledErrors = [
  "Unexpected token '",
  'Storage type: ',
  'Failed to fetch dynamically imported module',
  'Missing runtime config',
  "Failed to execute 'define",
  'Importing a module script failed.',
  'NetworkError when attempting to fetch resource',
  'Loading chunk',
]

export const sampleErrors = (event: SentryRequestBody) => {
  let shouldNotDropError = true
  event.exception?.values?.forEach(({ value }) => {
    sampledErrors.forEach((sampledError) => {
      if (value.includes(sampledError)) {
        shouldNotDropError = getSampleGroup(sampleRate)
      }
    })
  })

  if (shouldNotDropError) {
    return event
  }

  return null
}
