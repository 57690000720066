import { getLanguage } from './getLanguage'
import { replacePlaceholders } from './replacePlaceholders'
import { Translations } from './translations'
import type { ETranslationKey, Language, LitTranslation } from './types'

// Returns an array with translated strings and/or lit elements
export const getLitTranslation = (
  key: ETranslationKey,
  { locale, params }: { locale?: Language; params?: LitTranslation[] } = {}
): LitTranslation[] => {
  const language = locale || getLanguage()
  const translationString = Translations[key as ETranslationKey][language as Language]

  if (params && translationString) {
    return replacePlaceholders(translationString, params)
  }

  return [translationString]
}

export { Language, ETranslationKey }
