import { object, string } from 'zod'

export const SdkConfigSchema = object({
  accountId: string().optional(),
  baseUrl: string(),
  clientId: string(),
  environment: string(),
  sessionId: string(),
  version: string(),
  clientInstanceName: string().optional(),
})
