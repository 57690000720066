import { isValidLocale } from '../isValidLocale'
import { logWarn } from '../logger'
import type { Region } from '../types.d.ts'
import { getFallbackRegion } from './getFallbackRegion'
import { RegionMapping, regionMapping } from './regionMapping'

export const getRegionFromLocale = (locale?: string | null): Lowercase<Region> => {
  // To support accidental typos in data-locale attribute
  if (locale) {
    locale = locale.replace('_', '-')
  }

  if (locale && isValidLocale(locale)) {
    const countryCode = locale.split('-')[1].toLowerCase() as keyof RegionMapping
    if (countryCode in regionMapping) {
      return regionMapping[countryCode]
    }
  }
  const fallbackRegion = getFallbackRegion()
  logWarn(`Provided locale is not supported - defaulting region to ${fallbackRegion}`)

  return fallbackRegion
}
