/**
 * Reflects information regarding whether the customer has gone through a sign-in process,
 * finished the 1st step of KEC or other scenarios where they have demonstrated the intent of selecting Klarna in the purchase flow.
 * In this case, enabling Klarna as the primary payment method will make their journey smoother.
 */
export enum InteroperabilityStatus {
  /**
   * Confirm Klarna payment immediately, otherwise, display and select Klarna as a primary payment method.
   */
  PAYMENT_PENDING_CONFIRMATION = 'PAYMENT_PENDING_CONFIRMATION',
  /**
   * Display and select Klarna as a primary payment method
   */
  PAYMENT_PREPARED = 'PAYMENT_PREPARED',
  /**
   * Display Klarna as a payment method
   */
  PAYMENT_SUPPORTED = 'PAYMENT_SUPPORTED',
  /**
   * Don't show a Klarna button or offer Klarna
   */
  PAYMENT_UNSUPPORTED = 'PAYMENT_UNSUPPORTED',
}
