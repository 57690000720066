import { reportSentryError } from '../sentry'
import { STORAGE_PREFIX } from './constants'
import { localStorage } from './localStorage'
import { sessionStorage } from './sessionStorage'

export const localStorageHelper = (storageKey: string): ReturnType<typeof localStorage> => {
  const storageKeyWithPrefix = `${STORAGE_PREFIX}${storageKey}`

  try {
    return localStorage(storageKeyWithPrefix)
  } catch {
    reportSentryError(`Storage type: localStorage not supported`)
  }
}

export const sessionStorageHelper = (storageKey: string): ReturnType<typeof sessionStorage> => {
  const storageKeyWithPrefix = `${STORAGE_PREFIX}${storageKey}`

  try {
    return sessionStorage(storageKeyWithPrefix)
  } catch {
    reportSentryError(`Storage type: sessionStorage not supported`)
  }
}
