import { SUPPORTED_LOCALES } from '@klarna-web-sdk/messaging/src/config'

export const isValidLanguage = (lang: string | null): boolean => {
  if (!lang) {
    return false
  }

  const [language = '', country = ''] = lang.split('-')

  if (!language) {
    return false
  }
  const lowerLanguage = language.toLowerCase()

  if (country) {
    const upperCountry = country.toUpperCase()
    const supportedLanguages = SUPPORTED_LOCALES[upperCountry]

    if (!supportedLanguages) {
      return false
    }

    return supportedLanguages.includes(lowerLanguage)
  }

  return Object.values(SUPPORTED_LOCALES).flat().includes(lowerLanguage)
}
