import { Country, Currency as ECurrency } from './constants'
import type { CountryCurrency as TCountryCurrency } from './types'

export const SUPPORTED_CURRENCIES: ECurrency[] = [
  ECurrency.AUD,
  ECurrency.EUR,
  ECurrency.CAD,
  ECurrency.CZK,
  ECurrency.DKK,
  ECurrency.HUF,
  ECurrency.MXN,
  ECurrency.NZD,
  ECurrency.NOK,
  ECurrency.PLN,
  ECurrency.RON,
  ECurrency.SEK,
  ECurrency.CHF,
  ECurrency.GBP,
  ECurrency.USD,
  ECurrency.JPY,
]
export const SUPPORTED_CURRENCY_COUNTRY_COMBOS: TCountryCurrency = {
  AUD: [Country.AU],
  EUR: [
    Country.AT,
    Country.BE,
    Country.DE,
    Country.ES,
    Country.FI,
    Country.FR,
    Country.GR,
    Country.IE,
    Country.IT,
    Country.NL,
    Country.PT,
  ],
  CAD: [Country.CA],
  CZK: [Country.CZ],
  DKK: [Country.DK],
  HUF: [Country.HU],
  MXN: [Country.MX],
  NZD: [Country.NZ],
  NOK: [Country.NO],
  PLN: [Country.PL],
  RON: [Country.RO],
  SEK: [Country.SE],
  CHF: [Country.CH],
  GBP: [Country.GB],
  USD: [Country.US],
  JPY: [Country.JP],
}
