import { nativeEnum, number, object, string } from 'zod'

import { InteroperabilityStatus } from './constants'

export const InteroperabilityOptionsSchema = object({
  country: string().optional(),
  currency: string(),
  paymentAmount: number().optional(),
})

// JWT token
export const InteroperabilityToken = string()

export const InteroperabilityResponse = object({
  status: nativeEnum(InteroperabilityStatus),
  shoppingSessionId: string(),
  paymentConfirmationToken: string().optional(),
})

/**
 * Interoperability context is critical to provide smooth customer journey.
 */
export const InteroperabilityContextSchema = object({
  /**
   * Providing the interoperabilityToken inside InteroperabilityContext should always be the goal during the integration
   * since it will prevent Interoperability.resolve() method from performing an extra API call to determine the interoperability status.
   */
  interoperabilityToken: InteroperabilityToken.optional(),
  /**
   * Providing merchant reference would be a fallback solution in case interoperabilityToken is missing.
   * Alongside with platform reference, it will be used to determine the interoperability status.
   */
  merchantReference: string().optional(),
  /**
   * Providing platform reference would be a fallback solution in case interoperabilityToken is missing.
   * Alongside with merchant reference, it will be used to determine the interoperability status.
   */
  platformReference: string().optional(),
})

export const InteroperabilityApiBridgeSchema = {
  interoperabilityApiResolve: {
    data: InteroperabilityContextSchema.merge(InteroperabilityOptionsSchema).extend({
      shoppingSessionId: string(),
    }),
    response: string(),
  },
}
