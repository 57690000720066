import { configureTracker } from '@klarna-web-sdk/utils/src/tracker'
import type { TrackingConfig } from '@klarna-web-sdk/utils/src/tracker/index'
import { TrackerClients } from '@klarna-web-sdk/utils/src/tracker/trackerClients'

export class PaymentTracker {
  private static instance: ReturnType<typeof configurePaymentTracker> | null = null

  static getInstance(config?: TrackingConfig): ReturnType<typeof configurePaymentTracker> | null {
    if (!PaymentTracker.instance && config) {
      PaymentTracker.instance = configurePaymentTracker(config)
    }
    return PaymentTracker.instance
  }
}

export function configurePaymentTracker(config: TrackingConfig) {
  return configureTracker({
    config: {
      version: config.version,
      environment: config.environment,
      sessionId: config.sessionId,
    },
    trackerClient: TrackerClients.websdk,
    extraTrackingData: {},
  })
}

export const tracker = () => PaymentTracker.getInstance()
