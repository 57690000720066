import type KlarnaSDK from '@klarna-web-sdk/lib/src/sdk'
import { messagingEvents } from '@klarna-web-sdk/messaging/src/events'
import {
  buildPlacementSelectorName,
  configureTracker,
  getSampleGroup,
  getSDKConfig,
  isLegacyOSMClientId,
} from '@klarna-web-sdk/utils'
import { klarnaElements, klarnaPlacements } from '@klarna-web-sdk/utils/src/constants'
import { ErrorMixin } from '@klarna-web-sdk/utils/src/errors'
import getKlarnaScriptAttributes, {
  ScriptAttributes,
} from '@klarna-web-sdk/utils/src/getKlarnaScriptAttributes/getKlarnaScriptAttributes'
import { getUserLocale } from '@klarna-web-sdk/utils/src/getUserLocale'
import { logWarn } from '@klarna-web-sdk/utils/src/logger'
import { getRegionFromLocale } from '@klarna-web-sdk/utils/src/region'
import { reportSentryError, setupSentry } from '@klarna-web-sdk/utils/src/sentry'
import shouldLoadTestDriveBadge from '@klarna-web-sdk/utils/src/shouldLoadTestDriveBadge/shouldLoadTestDriveBadge'
import { TrackerClients } from '@klarna-web-sdk/utils/src/tracker/trackerClients'
import trackerFactory from '@klarna-web-sdk/utils/src/tracker/trackerFactory'
import type { SDKConfig } from '@klarna-web-sdk/utils/src/types'
import { html, LitElement } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'

import checkout from './checkout'
import { ASSETS_URL, EVENT_SAMPLING_PERCENTAGE, PLACEMENTS } from './config'
import creditPromotion from './credit-promotion-auto-size'
import creditPromotionBadge from './credit-promotion-badge'
import footerPromotion from './footer-promotion-auto-size'
import header from './header'
import enhanceLinkWithClientInfo from './helpers/enhanceLinkWithClientInfo/enhanceLinkWithClientInfo'
import fetchContent from './helpers/fetchContent/fetchContent'
import getCountry from './helpers/getCountry/getCountry'
import getLanguage from './helpers/getLanguage/getLanguage'
import { getLogoType } from './helpers/getLogoType/getLogoType'
import { getMessagePreference } from './helpers/getMessagePreference/getMessagePreference'
import getPaymentAmount from './helpers/getPaymentAmount/getPaymentAmount'
import getPlacementAttributes, {
  type NodeType,
} from './helpers/getPlacementAttributes/getPlacementAttributes'
import { PlacementAttributes } from './helpers/getPlacementAttributes/types'
import { getSubscriptionInterval } from './helpers/getSubscriptionInterval/getSubscriptionInterval'
import hasValidPlacementConfiguration from './helpers/hasValidPlacementConfiguration/hasValidPlacementConfiguration'
import {
  initializeInterstitialHandler,
  openInterstitial,
} from './helpers/interstitialHelpers/interstitialHelpers'
import homePagePromotionBox from './home-page-promotion-box'
import homePagePromotionTall from './home-page-promotion-tall'
import homePagePromotionWide from './home-page-promotion-wide'
import infoPage from './info-page'
import renderLegacy from './legacy-rendering'
import product from './product'
import sideBar from './sidebar-promotion-auto-size'
import styles from './style.scss'
import topStripPromotionAutoSize from './top-strip-promotion-auto-size'
import topStripPromotionBadge from './top-strip-promotion-badge'
import { LogoType, MessagingArgs } from './types'

const DEFAULT_THEME = '' // if no value is passed, adServer returns custom variant if available, else default variant styles.
const DEFAULT_PURCHASE_AMOUNT: number = undefined
const DEFAULT_LOCALE: string = undefined

type RenderType = 'shadow-dom' | 'inline' | 'messaging'
interface ImpressionEvent {
  k?: string // Ad ID
  j?: string // Campaign ID
  pt?: string // Promotion Type
  pm?: number // Payment Method ID
  d: string // Placement Key
  g: string // Client ID
  h: string // Language
  i: string // Country Code
  n: string // Merchant Host Url
  ae: string // Merchant Page Path
  ti?: string // Tracking ID
  iv: 'v2' | 'web-sdk'
  mpf?: 'Or' | 'or' // Message prefix
  rt?: RenderType
  ac?: 'web' | 'mobile' | 'app' // Event Channel
  ab?: 'api' | 'osm-frontends' // Event Source
}

export default function definePlacement(Klarna: KlarnaSDK, config: SDKConfig) {
  const elementSelector = buildPlacementSelectorName(config.clientInstanceName)

  if (customElements.get(elementSelector)) {
    return
  }
  @customElement(elementSelector)
  @ErrorMixin
  class KlarnaPlacement extends LitElement {
    @property({ attribute: 'data-key', type: klarnaPlacements })
    accessor key = '' as klarnaPlacements

    @property({ attribute: 'data-custom-payment-method-ids' })
    accessor customPaymentMethodIds = ''

    @property({ attribute: 'data-message-prefix' })
    accessor messagePrefix = ''

    @property({ attribute: 'data-theme' })
    accessor theme = DEFAULT_THEME

    @property({ attribute: 'data-locale' })
    accessor locale = config.locale || DEFAULT_LOCALE

    @property({ attribute: 'data-instance' })
    accessor instance = ''

    @property({ attribute: 'data-payment-amount' })
    accessor paymentAmount = DEFAULT_PURCHASE_AMOUNT

    // Alias for payment amount
    @property({ attribute: 'data-purchase-amount' })
    accessor purchaseAmount = DEFAULT_PURCHASE_AMOUNT

    // For legacy reasons we need to support both data-purchase-amount and data-purchase_amount
    @property({ attribute: 'data-purchase_amount' })
    accessor purchase_amount = DEFAULT_PURCHASE_AMOUNT

    // Inline should be a string, since boolean properties are set by the presence of the attribute, not the attribute value
    @property({ attribute: 'data-inline' })
    accessor inline = ''

    @property({ attribute: 'data-message-preference' })
    accessor messagePreference: string = undefined

    @property({ attribute: 'data-logo-type' })
    accessor logoType: LogoType = ''

    @property({ attribute: 'data-subscription-interval' })
    accessor subscriptionInterval: string = undefined

    @property({ type: Object })
    protected accessor placementAttributes: PlacementAttributes = {}

    @state()
    protected accessor code = ''

    @state()
    protected accessor tracker: ReturnType<typeof trackerFactory> = undefined

    @state()
    protected accessor nodes: HTMLElement[] = undefined

    @state()
    protected accessor impressionUrl = ''

    @state()
    protected accessor isLoading = false

    private instanceId = -1

    @state()
    private accessor config = config

    private readonly updateDelay = 10
    private updatePending = false

    protected isInline() {
      return this.hasAttribute('data-inline') && this.inline === 'true'
    }

    constructor() {
      super()
      this.instanceId = this.getSamplingInstanceId()

      try {
        this.setupSentryWithTags()
        this.tracker = configureTracker({
          config: {
            version: config.version,
            environment: config.environment,
            sessionId: config.sessionId,
          },
          trackerClient: TrackerClients.osm,
          extraTrackingData: {
            aId: config.additionalIdentifier,
          },
          locale: this.locale,
        })
      } catch (error) {
        reportSentryError(error)
      }
    }

    setupSentryWithTags() {
      const tags = {
        accountId: config.accountId,
        clientId: config.clientId,
        product: klarnaElements.PLACEMENT,
        sessionId: config.sessionId,
      }
      setupSentry({
        environment: config.environment,
        version: config.version,
        tags,
      })
    }

    createRenderRoot(): HTMLElement | DocumentFragment {
      return this.isInline() ? this : super.createRenderRoot()
    }

    async updateContent() {
      try {
        const {
          locale,
          key,
          legacyRendering,
          theme = DEFAULT_THEME,
          messagePrefix,
          customPaymentMethodIds,
        } = this.dataset as MessagingArgs

        const paymentAmount = getPaymentAmount(this.dataset)
        const isValid = hasValidPlacementConfiguration(this.dataset)
        const userLocale = getUserLocale({
          locale: locale ?? this.config.locale,
          allowFallback: false,
        })
        const region = getRegionFromLocale(userLocale)
        const messagePreference = getMessagePreference(this.dataset)
        const logoType = getLogoType(this.dataset)
        const subscriptionInterval = getSubscriptionInterval(this.dataset)
        const assetsUrl = ASSETS_URL[this.config.environment][region]

        if (!isValid) return

        if (PLACEMENTS[key] === 'static' && !legacyRendering) {
          this.sendImpressionEvent()
          return
        }

        type ContentType = 'html' | 'json'
        const contentType: ContentType = legacyRendering ? 'html' : (PLACEMENTS[key] as ContentType)

        this.isLoading = true

        const clientId = this.getClientId()

        const response = await fetchContent(
          {
            locale: userLocale,
            key,
            paymentAmount,
            theme,
            inline: this.isInline(),
            messagePrefix,
            customPaymentMethodIds,
            clientId,
            accountId: this.getAccountId(),
            environment: config.environment,
            messagePreference,
            logoType,
            subscriptionInterval,
          },
          contentType
        )

        if (!response) {
          this.nodes = []
          this.code = ''
          this.placementAttributes = {}
        }

        if (response) {
          this.nodes = response.content?.nodes
          this.code = response.code || ''
          const customStyles = (response.custom_styles || '').replace('{{i:klarna_fonts_link}}', '')

          if (this.nodes) {
            this.placementAttributes = getPlacementAttributes(this.nodes as NodeType[], {
              assetsUrl,
              customStyles,
              locale: userLocale,
              theme,
              key,
              environment: this.config.environment,
              clientId,
              origin: window.origin || window.location.origin,
              logoType,
            })
          }

          this.impressionUrl = response.impression_url
          this.sendImpressionEvent()
        }
      } catch (error) {
        reportSentryError(error)
      } finally {
        this.isLoading = false
      }
    }

    /** Account ID needs to be passed as header when the request
     * is made by a PSP in behalf of a partner
     */
    private getAccountId() {
      const attributes = getKlarnaScriptAttributes() as unknown as ScriptAttributes
      const accountId = attributes ? attributes['data-account-id']?.value : undefined
      return accountId
    }

    private getClientId() {
      const attributes: ScriptAttributes | null =
        getKlarnaScriptAttributes() as unknown as ScriptAttributes

      const clientIdFromAttributes = attributes ? attributes['data-client-id']?.value : undefined
      return clientIdFromAttributes || this.config.clientId
    }

    private getSamplingInstanceId() {
      return Math.floor(Math.random() * 10000) + 1
    }

    // External fonts have to be loaded outside of the shadow-dom, otherwise
    // they are not applied
    private addKlarnaFont() {
      const shadowHost = this.shadowRoot?.host

      if (shadowHost && shadowHost.parentNode) {
        const link = document.createElement('link')
        link.rel = 'stylesheet'
        link.href = 'https://x.klarnacdn.net/onsite-messaging/fonts/v1.2/fonts.css'
        // Sometimes the shadowHost doesn't have nextSibling, in that case we pass
        // null so the link it is appended at the end. See https://klarna-1.sentry.io/issues/4573010677/events/30480e7170e34c9a8033e915005d401d/
        shadowHost.parentNode.insertBefore(link, shadowHost.nextSibling || null)
      }
    }

    private getRenderType(): RenderType {
      if (this.isInline()) {
        return 'inline'
      }
      if (this.code) {
        return 'shadow-dom'
      }
      return 'messaging'
    }

    /* Called when the component renders for the first time, automatically by Lit */
    connectedCallback() {
      super.connectedCallback()
      const emptySpan = document.createElement('span')
      this.appendChild(emptySpan)
      this.config = getSDKConfig(this.instance) || config

      performance?.mark(`componentInitStart-${this.instanceId}`)
      this.updateContent()

      this.addKlarnaFont()

      // to prevent double click events arising when merchants apply a click event listener to the placement element
      this.addEventListener('click', (event: Event) => {
        event.stopPropagation()
      })
    }

    disconnectedCallback() {
      super.disconnectedCallback()
    }

    firstUpdated() {
      this.informForDeprecation()
    }

    /* Callback triggers when any attribute is changed, automatically by Lit */
    attributeChangedCallback(name: string, old: string | null, value: string | null) {
      super.attributeChangedCallback(name, old, value)
      this.applyContentChange(name, old, value)
    }

    async applyContentChange(name: string, old: string | null, value: string | null) {
      const contentUpdateProperties = [
        'data-theme',
        'data-payment-amount',
        'data-purchase-amount',
        'data-purchase_amount',
        'data-locale',
        'data-message-prefix',
        'data-message-preference',
        'data-subscription-interval',
      ]
      if (old === value || !contentUpdateProperties.includes(name)) {
        // Do nothing if changed attribute is not related to the content update context
        return
      }

      // Debounce update content calls to only update once per multiple attribute changes
      await this.debounceContentUpdates(this.updateContent.bind(this))
    }

    async debounceContentUpdates(fn: () => Promise<unknown>) {
      if (!this.updatePending) {
        this.updatePending = true
        setTimeout(() => fn().then(() => (this.updatePending = false)), this.updateDelay)
      }
    }

    informForDeprecation() {
      const oldOSMlib = document.querySelector(
        'script[src*="klarnaservices"][src*="/lib.js"],script[src*="pre-purchase"][src*="/lib.js"]'
      )

      if (oldOSMlib) {
        logWarn(
          `The Klarna library you are utilizing will be deprecated end of 2024. We strongly recommend updating your integration for continued support and enhanced features. For migration details, please visit: https://docs.klarna.com/on-site-messaging/migrate-to-new-klarna-websdk/"`
        )
      }
    }

    onLearnMoreClick(event: PointerEvent) {
      let ctaLink = this.placementAttributes?.ctaLink || ''
      ctaLink = enhanceLinkWithClientInfo(ctaLink, this.getClientId())

      const { iframe } = openInterstitial(event, {
        emit: Klarna.Messaging.emit,
        ctaLink,
        iframeId: 'learn-more-iframe',
      })

      initializeInterstitialHandler({ emit: Klarna.Messaging.emit }, iframe)
    }

    onOpenInterstitialFromLegacyRendering(event: PointerEvent, ctaLink: string) {
      const link = enhanceLinkWithClientInfo(ctaLink, this.getClientId())

      const { iframe } = openInterstitial(event, {
        emit: Klarna.Messaging.emit,
        ctaLink: link,
        iframeId: 'learn-more-iframe',
      })

      initializeInterstitialHandler({ emit: Klarna.Messaging.emit }, iframe)
    }

    getPlacementByKey() {
      const { key } = this.dataset

      // Attribute to be able to compare the placement with the new and old rendering way
      if (this.code) {
        this.sendLegacyRenderingEvent()
        return html`${renderLegacy({
          code: this.code,
          onLearnMoreClick: this.onOpenInterstitialFromLegacyRendering.bind(this),
        })}`
      }

      switch (key) {
        case klarnaPlacements.CHECKOUT:
          return html` ${checkout({
            locale: this.locale,
            onLearnMoreClick: this.onLearnMoreClick.bind(this),
            ...this.placementAttributes,
          })}`
        case klarnaPlacements.CUSTOM_TYPE_0:
        case klarnaPlacements.CREDIT_PROMOTION_AUTO_SIZE:
        case klarnaPlacements.CREDIT_PROMOTION_STANDARD:
        case klarnaPlacements.CREDIT_PROMOTION_SMALL:
          return html`${creditPromotion({
            ...this.placementAttributes,
            onLearnMoreClick: this.onLearnMoreClick.bind(this),
          })} `
        case klarnaPlacements.PRODUCT:
        case klarnaPlacements.CART:
          return html` ${product({
            ...this.placementAttributes,
            onLearnMoreClick: this.onLearnMoreClick.bind(this),
          })}`
        case klarnaPlacements.HEADER:
          return html` ${header({
            ...this.placementAttributes,
            onLearnMoreClick: this.onLearnMoreClick.bind(this),
          })}`
        case klarnaPlacements.CREDIT_PROMOTION_BADGE:
          return html` ${creditPromotionBadge({
            ...this.placementAttributes,
            onLearnMoreClick: this.onLearnMoreClick.bind(this),
          })}`
        case klarnaPlacements.FOOTER_PROMOTION_AUTO_SIZE:
          return html`${footerPromotion()}`
        case klarnaPlacements.SIDEBAR_PROMOTION_AUTO_SIZE:
          return html`${sideBar()}`
        case klarnaPlacements.TOP_STRIP_PROMOTION_BADGE:
          return html`
            ${topStripPromotionBadge({
              ...this.placementAttributes,
              onLearnMoreClick: this.onLearnMoreClick.bind(this),
            })}
          `
        case klarnaPlacements.TOP_STRIP_PROMOTION_STANDARD:
        case klarnaPlacements.TOP_STRIP_PROMOTION_AUTO_SIZE:
          return html`<klarna-placement-top-strip-promotion-auto-size
              dataAttributes=${JSON.stringify(this.placementAttributes)}
              .onLearnMoreClick=${this.onLearnMoreClick.bind(this)}
            />

            ${topStripPromotionAutoSize({
              ...this.placementAttributes,
              onLearnMoreClick: this.onLearnMoreClick.bind(this),
            })} `
        case klarnaPlacements.HOMEPAGE_PROMOTION_WIDE:
          return html`
            ${homePagePromotionWide({
              ...this.placementAttributes,
              onLearnMoreClick: this.onLearnMoreClick.bind(this),
            })}
          `
        case klarnaPlacements.HOMEPAGE_PROMOTION_TALL:
          return html`
            ${homePagePromotionTall({
              ...this.placementAttributes,
              onLearnMoreClick: this.onLearnMoreClick.bind(this),
            })}
          `
        case klarnaPlacements.HOMEPAGE_PROMOTION_BOX:
          return html`
            ${homePagePromotionBox({
              ...this.placementAttributes,
              onLearnMoreClick: this.onLearnMoreClick.bind(this),
            })}
          `

        case klarnaPlacements.INFO_PAGE:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          /* @ts-ignore-next-line */
          return html` ${infoPage({ ...this.placementAttributes })}`

        /* @TODO the following placements should be implemented in the API and the legacy html rendering removed */
        case klarnaPlacements.CUSTOM_TYPE_3_INLINE:
        case klarnaPlacements.CUSTOM_TYPE_3_335_AUTO:
        case klarnaPlacements.CREDIT_PROMOTION_INLINE:
        case klarnaPlacements.CUSTOM_TYPE_1:
          return html`${renderLegacy({
            code: this.code,
            onLearnMoreClick: this.onOpenInterstitialFromLegacyRendering.bind(this),
          })}`
        default:
          return html`<div></div>`
      }
    }

    attachTestDriveBadge() {
      const shouldLoadBadge = shouldLoadTestDriveBadge(
        this.config.environment,
        this.config.clientId
      )
      if (shouldLoadBadge) {
        return html`<test-drive-badge></test-drive-badge>`
      }
      return null
    }

    sendImpressionEvent() {
      let promotionType, campaignId, paymentMethod

      try {
        const url = new URL(this.impressionUrl)
        const params = url.searchParams
        promotionType = params.get('pt')
        campaignId = params.get('j')
        paymentMethod = params.get('pm')
      } catch (error) {
        // ignore
      }

      const language = getLanguage(this.locale)
      const countryCode = getCountry(this.locale)

      if (
        getSampleGroup(EVENT_SAMPLING_PERCENTAGE.IMPRESSIONS) ||
        this.key === 'checkout' // Do not do sampling of events on the checkout widget
      ) {
        this.tracker.event('b', {}, {
          iv: 'web-sdk',
          d: this.key,
          h: `${language}`.toUpperCase(),
          i: `${countryCode}`.toUpperCase(),
          n: window.location.host,
          ae: window.location.pathname,
          g: isLegacyOSMClientId(this.getClientId()) ? this.getClientId() : undefined,
          mpf: this.messagePrefix,
          rt: this.getRenderType(),
          ab: 'osm-frontends',
          pt: promotionType,
          j: campaignId,
          pm: Number(paymentMethod),
        } as ImpressionEvent as unknown as Record<string, unknown>)
      }

      if (getSampleGroup(EVENT_SAMPLING_PERCENTAGE.AGR_IMPRESSIONS)) {
        this.tracker.event('aggr_b', {}, {
          iv: 'web-sdk',
          d: this.key,
          h: `${language}`.toUpperCase(),
          i: `${countryCode}`.toUpperCase(),
          ab: 'osm-frontends',
        } as ImpressionEvent as unknown as Record<string, unknown>)
      }
    }

    sendRenderPerformanceEvent() {
      if (getSampleGroup(EVENT_SAMPLING_PERCENTAGE.PERFORMANCE) && performance?.mark) {
        try {
          performance.mark(`componentInitEnd-${this.instanceId}`)
          performance.measure(
            `componentInitializationToRender-${this.instanceId}`,
            `componentInitStart-${this.instanceId}`,
            `componentInitEnd-${this.instanceId}`
          )
          const measure = performance.getEntriesByName(
            `componentInitializationToRender-${this.instanceId}`
          )[0]

          this.tracker.event(
            'osm_rendering_time',
            {},
            {
              ms: measure.duration,
              placement_key: this.key,
            }
          )
        } catch (error) {}
      }
    }

    sendLegacyRenderingEvent() {
      if (getSampleGroup(EVENT_SAMPLING_PERCENTAGE.LEGACY_RENDERING)) {
        try {
          this.tracker.event(
            'osm_legacy_rendering',
            {},
            {
              client_id: this.getClientId(),
              placement_key: this.key,
            }
          )
        } catch (error) {}
      }
    }

    render() {
      const { key } = this.dataset
      const hasContent = Boolean(this.nodes?.length || this.code)
      const blurClass = this.isLoading ? 'loading' : 'loaded'
      type PlacementKeys = keyof typeof PLACEMENTS

      if (hasContent) {
        this.sendRenderPerformanceEvent()
      }

      // Emit 'placement-rendered' event with 'content' and 'element' in payload when the loading is false.
      if (!this.isLoading) {
        Klarna.Messaging.emit(messagingEvents.PLACEMENT_RENDERED, {
          content: hasContent,
          element: this.renderRoot,
        })
        Klarna.Messaging.emit(messagingEvents.PLACEMENT_RENDERED_LEGACY, {
          content: hasContent,
          element: this.renderRoot,
        })
      }

      // When we don't have content returned we should not render anything
      if (!this.isLoading && !hasContent && PLACEMENTS[key as PlacementKeys] !== 'static') {
        return html`<!-- No content found for the given configuration -->`
      }

      return html`
        <style>
          ${styles}
        </style>
        <div
          class="${blurClass} ${this.theme} ${this.key}"
          style="height: auto; width: 100%; display: inline-block;"
        >
          ${html`${this.getPlacementByKey()}${this.attachTestDriveBadge()}`}
        </div>
      `
    }
  }

  return KlarnaPlacement
}
