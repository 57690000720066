import { SentryRequestBody } from '@micro-sentry/core'

// Add errors to drop in the below array
const droppedErrors: string | undefined[] = []

export const dropErrors = (event: SentryRequestBody) => {
  let shouldDropError = false
  event.exception?.values?.forEach(({ value }) => {
    droppedErrors.forEach((sampledError) => {
      if (value.includes(sampledError)) {
        shouldDropError = true
      }
    })
  })

  if (shouldDropError) {
    return null
  }

  return event
}
