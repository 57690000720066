import { any, array, boolean, object, string } from 'zod'

export const PublicPayload = object({
  supplementaryPurchaseData: any().optional(),
})
export const PublicResponse = object({
  consumerInfo: object({ personId: string(), firstName: string(), lastName: string() }).optional(),
  shoppingSessionId: string(),
  createdAt: string(),
  expiresAt: string(),
  authenticated: boolean().optional(),
})

export const ApiPayload = object({
  supplementary_purchase_data: object({
    merchant_references: array(string()).optional(),
    content_type: string().optional(),
    content: object({}).optional(),
  }).optional(),
})

export const ApiResponse = object({
  user_info: object({ person_id: string(), first_name: string(), last_name: string() }).optional(),
  shopping_session_id: string(),
  created_at: string(),
  expires_at: string(),
  authenticated: boolean().optional(),
})

export const PublicShoppingSessionDataSchema = object({
  shopping_session_id: string(),
  created_at: string(),
  expires_at: string(),
})

export const ShoppingApiBridgeSchema = {
  shoppingApiCreateSession: {
    data: object({
      body: ApiPayload,
      region: string(),
    }),
    response: ApiResponse,
  },
  shoppingApiGetSession: {
    data: object({
      shoppingSessionId: string(),
      region: string(),
    }),
    response: ApiResponse,
  },
  shoppingApiUpdateSession: {
    data: object({
      shoppingSessionId: string(),
      body: ApiPayload,
      region: string(),
    }),
    response: ApiResponse,
  },
  shoppingApiGetInteroperabilityToken: {
    data: object({
      shoppingSessionId: string(),
    }),
    response: string(),
  },
}
