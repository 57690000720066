import { klarnaShapes } from '../constants'
import { isProductionBuild } from '../isProductionBuild'
import { logWarn } from '../logger'
import { validateKlarnaOrigin } from '../validateKlarnaOrigin'

export default function getButtonShape(shapeRequested: klarnaShapes): klarnaShapes {
  if (Object.values(klarnaShapes).includes(shapeRequested)) return shapeRequested

  if (!isProductionBuild() || validateKlarnaOrigin(window.location.origin)) {
    logWarn(`Invalid shape requested: "${shapeRequested}", using default shape instead.`)
  }

  return klarnaShapes.DEFAULT
}
