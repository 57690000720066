import { logWarn } from '@klarna-web-sdk/utils/src/logger'

/* Check that amount is valid */
function validateAmount(key: string, purchaseAmountValue?: string) {
  if (`${key}`.includes('credit')) {
    if (purchaseAmountValue === undefined) {
      logWarn('missing data-purchase-amount in klarna-placement')
      return false
    }

    if (Number.isNaN(parseFloat(purchaseAmountValue))) {
      logWarn('invalid purchase-amount in klarna-placement, it should be a number')
      return false
    }

    if (parseFloat(purchaseAmountValue) < 0) {
      logWarn('invalid purchase-amount in klarna-placement, it should be >= 0')
      return false
    }

    if (`${purchaseAmountValue}`.includes('.') || `${purchaseAmountValue}`.includes(',')) {
      // eslint-disable-next-line max-len
      logWarn(
        'The data-purchase-amount should be in minor units (e.g. $120.00 should be passed as 12000). Please check https://docs.klarna.com/on-site-messaging/in-depth-knowledge/placements/#attributes'
      )

      // we should allow amounts with .0, e.g. 3000.0 is valid, 3000.1 is not valid
      if (!isInteger(parseFloat(purchaseAmountValue))) return false
    }
  }

  return true
}

function isInteger(value: number): boolean {
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value
}

export default validateAmount
