import { html } from 'lit'
import { unsafeHTML } from 'lit/directives/unsafe-html.js'

import getLanguage from '../helpers/getLanguage/getLanguage'
import styles from './style.scss'

export default function homePagePromotionBox({
  textTitle = '',
  textSubtitle = '',
  locale = '',
  theme = '',
  onLearnMoreClick,
}: {
  textTitle?: string
  textSubtitle?: string
  locale?: string
  theme?: string
  onLearnMoreClick: (event: MouseEvent) => void
}) {
  const language = getLanguage(locale)

  return html`
    <style>
      ${styles}
    </style>
    <div
      data-testid="${textTitle ? 'home-page-promotion-box' : ''}"
      class="container ${theme}"
      @click=${onLearnMoreClick}
    >
      <div class="content-container">
        <div class="content">
          <div class="heading-${language.toLowerCase()} heading item">
            <div>${html`${unsafeHTML(textTitle)}`}</div>
          </div>
          <div class="sub-text-${language.toLowerCase()} sub-text item">
            <div>${html`${unsafeHTML(textSubtitle)}`}</div>
          </div>
        </div>
        <div class="logo-container item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="badge-pink ${theme}"
            viewBox="0 0 118.64 59.32"
          >
            <rect width="118.64" height="59.32" fill="#ffb3c7" rx="8.9" ry="8.9" />
            <path
              d="M94.12 25.75v.86a7.1 7.1 0 1 0 0 11.74v.86h4V25.75Zm-3.67 10.19a3.47 3.47 0 1 1 3.65-3.46 3.56 3.56 0 0 1-3.65 3.46Zm-58.73-16.2h-4.37a11.2 11.2 0 0 1-4.52 9l-1.73 1.32 6.71 9.15h5.52l-6.18-8.42a15.46 15.46 0 0 0 4.57-11.05Zm-15.63 0h4.48v19.47h-4.48zm18.54.01h4.22v19.46h-4.22zm41.23 5.63a4.88 4.88 0 0 0-4.15 1.88v-1.51h-4v13.46h4.06v-7.08a2.85 2.85 0 0 1 3-3.05c1.77 0 2.79 1.06 2.79 3v7.11h4v-8.54c.04-3.14-2.46-5.27-5.7-5.27Zm-24.26.37v.86a7.1 7.1 0 1 0 0 11.74v.86h4V25.75Zm-3.67 10.19a3.47 3.47 0 1 1 3.66-3.46 3.56 3.56 0 0 1-3.66 3.46Zm13.93-8.44v-1.75h-4.12v13.46h4.13v-6.29c0-2.12 2.3-3.26 3.9-3.26v-3.91a5 5 0 0 0-3.91 1.75Zm40.57 6.93A2.53 2.53 0 1 0 105 37a2.53 2.53 0 0 0-2.57-2.57Z"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="badge-black ${theme}"
            viewBox="0 0 118.64 59.32"
          >
            <rect width="118.64" height="59.32" rx="8.9" ry="8.9" />
            <path
              fill="#fff"
              d="M94.12 25.75v.86a7.1 7.1 0 1 0 0 11.74v.86h4V25.75Zm-3.67 10.19a3.47 3.47 0 1 1 3.65-3.46 3.56 3.56 0 0 1-3.65 3.46Zm-58.73-16.2h-4.37a11.2 11.2 0 0 1-4.52 9l-1.73 1.32 6.71 9.15h5.52l-6.18-8.42a15.46 15.46 0 0 0 4.57-11.05Zm-15.63 0h4.48v19.47h-4.48zm18.54.01h4.22v19.46h-4.22zm41.23 5.63a4.88 4.88 0 0 0-4.15 1.88v-1.51h-4v13.46h4.06v-7.08a2.85 2.85 0 0 1 3-3.05c1.77 0 2.79 1.06 2.79 3v7.11h4v-8.54c.04-3.14-2.46-5.27-5.7-5.27Zm-24.26.37v.86a7.1 7.1 0 1 0 0 11.74v.86h4V25.75Zm-3.67 10.19a3.47 3.47 0 1 1 3.66-3.46 3.56 3.56 0 0 1-3.66 3.46Zm13.93-8.44v-1.75h-4.12v13.46h4.13v-6.29c0-2.12 2.3-3.26 3.9-3.26v-3.91a5 5 0 0 0-3.91 1.75Zm40.57 6.93A2.53 2.53 0 1 0 105 37a2.53 2.53 0 0 0-2.57-2.57Z"
            />
          </svg>
        </div>
      </div>
    </div>
  `
}
