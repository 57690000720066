import { devLogger, getSentryClient, isProductionBuild } from '@klarna-web-sdk/utils'

import { IdentityTracker, TrackingEvents } from '../components/IdentityTracker'
import { identitySessionStorage, IdentityStorageKeys } from '../identitySessionStorage'
import { IntegrationError } from './integration-error'

export const ERROR_TITLES = {
  OAuthError: 'Authorization response is OAuthError',
  AuthorizationCodeGrantRequestCallFailed: 'oauth.authorizationCodeGrantRequest call failed!',
  TokenExchangeFailed: 'Token exchange failed',
  InvalidAuthorizationCodeOpenIDResponse: 'Invalid AuthorizationCodeOpenID Response',
  DiscoveryRequestFailed: 'Discovery request failed!',
  RedirectFlowResponseCheckFailed: 'redirectFlowResponse check failed!',
  NAClientIdsNotFound: 'Identity naClientIds list not found',
  CouldNotCreateAuthServer: 'Could not create Authorization Server',
  VerifyLoginFailedForPopupFlow: 'verifyLogin failed for popup flow!',
  VerifyLoginFailedForRedirectFlow: 'verifyLogin failed for redirect flow.',
  StartRedirectFailed: 'Failed to start the redirect flow',
} as const

const toError = async (error: unknown): Promise<Error> => {
  if (error instanceof Error) return error

  if (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof error.message === 'string'
  ) {
    return new Error(error.message)
  }

  if (
    error &&
    typeof error === 'object' &&
    'json' in error &&
    'bodyUsed' in error &&
    typeof error.json === 'function'
  ) {
    try {
      if (error.bodyUsed && 'body' in error) {
        return new Error(Buffer.from(error.body as ArrayBuffer).toString('utf-8'))
      }

      return new Error(JSON.stringify(await error.json()))
    } catch (err) {
      return err
    }
  }

  try {
    return new Error(JSON.stringify(error))
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(error))
  }
}

interface ErrorExtras extends Record<string, unknown> {
  errorTitle: string
}

export const errorHandler = (err: unknown, { errorTitle, ...extras }: ErrorExtras): void => {
  toError(err).then((error) => {
    if (error instanceof IntegrationError) {
      devLogger.error(error.message)
      return
    }

    if (isProductionBuild()) {
      const client = getSentryClient()
      const sessionId = identitySessionStorage.get(IdentityStorageKeys.SessionId)
      if (extras) client.setExtras(extras)
      client.setExtra('errorTitle', errorTitle)
      client.setExtra('sessionId', sessionId)
      client.report(error)

      IdentityTracker.sendEvent({
        name: TrackingEvents.UnexpectedError,
        options: {
          errorTitle,
          funnelId: sessionId,
        },
      })
    }

    devLogger.error(error.message)
  })
}
