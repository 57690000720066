import { request } from '../apis/request'
import { PaymentRequestState } from '../constants'
import { store } from './store'

export let lastEmittedState: PaymentRequestState | undefined

export const emitUpdate = () => {
  const emit = store.get('emit')
  const state = store.get('paymentRequestState')

  if (lastEmittedState && state === lastEmittedState) return

  lastEmittedState = state
  emit('update', request())
}
