import { PaymentRequestState } from '../constants'
import { emitUpdate } from './emitUpdate'
import { fetchPaymentRequest } from './fetchPaymentRequest'
import { addPollingInterval, cancelPollingInterval, store } from './store'

const pollableStates: PaymentRequestState[] = [PaymentRequestState.SUBMITTED]

const pollCancelStates: PaymentRequestState[] = [
  PaymentRequestState.IN_PROGRESS,
  PaymentRequestState.PENDING_CONFIRMATION,
  PaymentRequestState.AUTHORIZED,
]

async function pollForInProgressState(intervalId: NodeJS.Timeout): Promise<void> {
  const currentState = store.get('paymentRequestState')
  if (!pollableStates.includes(currentState)) return

  const response = await fetchPaymentRequest()
  if (response.state === currentState) return

  if (pollCancelStates.includes(response.state)) {
    clearInterval(intervalId)

    if (response.state === 'IN_PROGRESS') emitUpdate()
  }
}

export function initPollingForInProgressState() {
  // Remove any existing polling intervals of the same type
  cancelPollingInterval({ type: 'pollingForInProgressState' })
  // poll every 2 seconds
  const intervalId: NodeJS.Timeout = setInterval(() => pollForInProgressState(intervalId), 2000)
  // Add the polling interval to the global store so it can be cleared later
  addPollingInterval({ type: 'pollingForInProgressState', intervalId })

  // stop polling after 10 seconds
  setTimeout(() => cancelPollingInterval({ intervalId }), 10000)
}

export const __test__ = {
  pollForInProgressState,
}
